define("crm/components/internment/chart-action", ["exports", "moment", "crm/components/internment/action-components"], function (_exports, _moment, _actionComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _actionComponents.default.extend({
    es: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    currentWorker: Ember.computed.readOnly("sessionAccount.currentWorker"),
    classNames: ["table-24-td", "clickable animated", "fadeIn"],
    doubleClick: function doubleClick() {
      if (this.get("disableBtn")) {
        return;
      }

      var self = this;
      var date = (0, _moment.default)(self.get("thisDate"));

      if (this.get('model.internment.disabled')) {
        return;
      } //check if there is any event for single click, disable it


      var eventIO = this.get('eventIO');

      if (eventIO) {
        Ember.run.cancel(eventIO);
        this.set('eventIO', undefined);
      }

      if (!this.get('action')) {
        this.doAction();
      } else if (this.get('action.status') === 5) {
        this.updateAction();
      } else {
        this.get("store").findRecord("action", this.get('action.id')).then(function (action) {
          if (action.get("status") === 3) {
            action.set("status", 1);
            action.set("started", date);
            action.set("doer", self.get("currentWorker"));
          }

          action.save().catch(function (result) {
            self.get("es").errorReturned(result);
          });
        });
      }
    },
    click: function click() {
      if (this.get("disableBtn")) {
        return;
      }

      if (this.get("action.status") === 4) {
        return;
      }

      var self = this;
      var eventIO = this.get("eventIO");

      if (!eventIO) {
        //if this is the first click, will schedule it for later
        self.set("eventIO", Ember.run.later(self, function () {
          if (self.get("model.constructor.modelName") == "todo-exam" && self.get("model.type.id") && self.get("statusColor") != "add-action") {
            if (!Ember.isEmpty(self.get("action")) && !Ember.isEmpty(self.get("action.examination.id"))) {
              self.get("store").findRecord("exam", self.get("action.examination.id")).then(function (savedExam) {
                self.sendAction("callModal", "exam", "edit", savedExam);
              });
            } else {
              self.sendAction("callModal", "exam", "create", {
                patient: self.get("model.internment.patient"),
                type: self.get("model.type"),
                organization: self.get("model.internment.organization"),
                action: {
                  exam: self.get("model"),
                  status: 1,
                  doer: self.get("currentWorker"),
                  started: (0, _moment.default)(self.get("thisDate"))
                }
              });
            }
          } else {
            self.sendAction("openAction", self.get("model"), self.get("thisDate"), self.get("action"), self.get("statusColor"), self.get("prevAction"));
          } ///clear additional events


          var eventIO = self.get("eventIO");

          if (eventIO) {
            Ember.run.cancel(eventIO);
            self.set("eventIO", undefined);
          }
        }, 600));
      }
    },
    isPrevTodo: Ember.computed('model.prevStarted', 'thisDate', 'model.started', function () {
      return this.get('thisDate').isSameOrBefore(this.get('model.started')) && this.get('thisDate').isSameOrAfter(this.get('model.prevStarted')) && this.get('model.prevTodo.id');
    }),
    statusColor: Ember.computed('model.allActions.@each.started', 'model.allActions.@each.status', 'model.internment.ended', 'model.isPhysicalExame', 'model.name', 'model.period', 'model.prevStarted', 'model.started', 'model.ended', 'thisDate', 'started', 'prevAction', 'prevAction.notes', function () {
      var self = this;
      var columnTime = this.get('thisDate');
      var actions = [];
      this.set('icon', null);
      this.set('action', undefined);
      this.set('prevAction', undefined);

      if (Ember.isEmpty(this.get("model.name"))) {
        return null;
      }
      /* Done Actions */


      actions = this.get('model.allActions');

      for (var i = 0; i < this.get('model.allActions.length'); i++) {
        var action = actions.objectAt(i);

        if (columnTime.isSame(action.get('started'), "h")) {
          /* store current action */
          self.set('action', action);
          self.set('prevAction', actions.objectAt(i + 1));
          /*
           * Return status class
           */

          if (action.get('status') === 1) {
            if (action.get('initials') && ![1, 7, 18, 23, 24, 25, 26].includes(action.get('initials'))) {
              return "btn-bad";
            } else {
              self.set('icon', Math.abs((0, _moment.default)(action.get('created')).diff(action.get('started'), 'minutes')) < 30 ? "check" : "times");
              return "btn-primary";
            }
          } else if (action.get('status') === 2) {
            return "btn-removed";
          } else if (action.get('status') === 4) {
            self.set('icon', "fa fa-times");
            return "btn-removed";
          } else if (action.get('status') === 5) {
            if (self.isFutureColumn(columnTime) && (!self.get("model.ended") || columnTime.isSameOrBefore(self.get("model.ended"), 'h')) && (!self.get("model.internment.ended") || columnTime.isSameOrBefore(self.get("model.internment.ended"), "h"))) {
              return "btn-info";
            }

            if (self.isCurrentColumn(columnTime) && (!self.get("model.ended") || columnTime.isSameOrBefore(self.get("model.ended"), 'hour')) && (!self.get("model.internment.ended") || columnTime.isSameOrBefore(self.get("model.internment.ended")))) {
              return "btn-warning";
            }

            if (self.isPastColumn(columnTime) && (!self.get("model.ended") || columnTime.isSameOrBefore(self.get("model.ended"), 'hour')) && (!self.get("model.internment.ended") || columnTime.isSameOrBefore(self.get("model.internment.ended")))) {
              return "btn-danger";
            }
          }
        }
      }

      if (this.get("model.started") && columnTime.isBefore(this.get("model.started"), "h")) {
        return "no-task clickable";
      }

      if (this.get("model.ended") && columnTime.isAfter(this.get("model.ended"), "h")) {
        return "no-task";
      }

      if (Ember.isEmpty(this.get('prevAction'))) {
        // if has no prev it will be the most recent one
        this.set('prevAction', actions.objectAt(0));
      }

      return this.notDone(columnTime);
    }),
    shouldDisplayNotes: Ember.computed('model.name', 'action.notes', function () {
      return this.get('action.notes') && this.get('model.name') && !this.get('model.isPhysicalExam');
    }),
    notDone: function notDone(columnTime) {
      if (this.get("model.ended") && (0, _moment.default)(columnTime).isAfter(this.get("model.ended")) || this.get("model.internment.disabled")) {
        return null;
      }

      var task = this.getTrueTask(columnTime, this.get("model"));

      if (task.get('prevStarted')) {
        // prevStarted == true started
        var ref = (0, _moment.default)(task.get("prevStarted")).startOf('hour');

        if (task.get("period") && task.get("trueFirstAction") && columnTime.isAfter(task.get("trueFirstAction.started"), "h") && task.lastActionUntil(columnTime, 1, 5)) {
          ref = (0, _moment.default)(task.lastActionUntil(columnTime, 1, 5).get('started')).startOf('hour');
        }

        if ((Ember.isEmpty(task.get("period")) || task.get("period") == 0) && columnTime.isSame(task.get('prevStarted'), 'h') || // doesn't have period but has started
        task.get("period") && (0, _moment.default)(task.get('prevStarted')).isSameOrBefore(columnTime, 'h') && columnTime.diff(ref, "h") % task.get("period") === 0 // Has period
        ) {
            if (this.isPastColumn(columnTime) && (!this.get("model.ended") || columnTime.isSameOrBefore(this.get("model.ended"), 'hour')) && (!this.get("model.internment.ended") || columnTime.isSameOrBefore(this.get("model.internment.ended")))) {
              return "btn-danger";
            }

            if (this.isFutureColumn(columnTime) && (!this.get("model.ended") || columnTime.isSameOrBefore(this.get("model.ended"), 'hour')) && (!this.get("model.internment.ended") || columnTime.isSameOrBefore(this.get("model.internment.ended")))) {
              return "btn-info";
            }

            if (this.isCurrentColumn(columnTime) && (!this.get("model.ended") || columnTime.isSameOrBefore(this.get("model.ended"), 'hour')) && (!this.get("model.internment.ended") || columnTime.isSameOrBefore(this.get("model.internment.ended")))) {
              return "btn-warning";
            }
          }
      }

      return "add-action";
    },
    updateCartSize: function updateCartSize(action) {
      if (action.get("type")) {
        this.get("store").findRecord("internment-shopping-cart", this.get("model.internment.shoppingCart.id")).then(function () {
          Ember.$("#shopping-cart-nav a").addClass("animated pulse text-sales");
          Ember.run.later(function () {
            return Ember.$("#shopping-cart-nav a").removeClass("animated pulse text-sales");
          }, 1000);
        });
      }
    },
    doAction: function doAction() {
      var self = this;
      this.set("disableBtn", true);
      var action = this.get('store').createRecord('action', {
        status: 1,
        started: (0, _moment.default)(this.get('thisDate')),
        doer: self.get('currentWorker')
      });
      var taskType = this.get('model.constructor.modelName');

      if (taskType.includes("-")) {
        taskType = taskType.split("-")[1];
      }

      action.set(taskType, this.getTrueTask(this.get('thisDate'), this.get('model')));
      action.save().then(function (actionCreated) {
        if (Ember.isEmpty(actionCreated.get("monitoring.content")) && Ember.isEmpty(actionCreated.get("chore.content"))) {
          self.updateCartSize(actionCreated);
        }
      }).catch(function (error) {
        self.get("es").errorReturned(error);
        self.get('store').unloadRecord(action);
      }).finally(function () {
        return self.set("disableBtn", false);
      });
    },
    updateAction: function updateAction() {
      var self = this;
      this.set("disableBtn", true);

      if (this.get('model.isPhysicalExam') && !Ember.isEmpty(this.get('physicalExame'))) {
        var physicalExame = JSON.stringify(this.get('physicalExame'));
        this.set('notes', physicalExame);
      }

      var action = this.get("action");
      action.set('status', 1);
      action.set('doer', self.get('currentWorker'));
      action.save().then(function () {
        return self.updateCartSize(action);
      }).catch(function (error) {
        return self.get("es").errorReturned(error);
      }).finally(function () {
        return self.set("disableBtn", false);
      });
    },
    getTrueTask: function getTrueTask(thisDate, task) {
      if (task.get('prevTodo.started') && (0, _moment.default)(thisDate).isBefore(task.get('started'), "h")) {
        return this.getTrueTask(thisDate, this.get('store').peekRecord(task.get('constructor.modelName'), task.get('prevTodo.id')));
      } else {
        return task;
      }
    }
  });

  _exports.default = _default;
});