define("crm/controllers/organizations/organization/schedule/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    exporting: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
      this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
    },
    columns: Ember.computed(function () {
      var result = [{
        "className": "column-date-time2",
        "propertyName": "start",
        "component": "umt-datetime2",
        "sortDirection": "desc",
        "sortPrecedence": 0,
        "title": ""
      }, {
        "className": "column-name text-calendar text-strong",
        "propertyName": "patient.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("patients.patient.title")
      }, {
        "className": "column-name",
        "propertyName": "patient.owner.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("customers.customer.title")
      }];

      if (Ember.$("body").width() > 767) {
        result.push({
          "className": "column-name",
          "propertyName": "observation",
          "component": "title-models-table-display",
          "title": this.get('intl').t("appointments.new.reason")
        });
      }

      result.push({
        "className": "column-actions-1",
        "component": "appointments-schedule-actions"
      });
      return result;
    }),
    filtersObserver: Ember.observer("startedAfter", "startedBefore", function () {
      if (!Ember.isEmpty(this.get("startedAfter")) && !Ember.isEmpty(this.get("startedBefore")) && (0, _moment.default)(this.get("startedAfter")) > (0, _moment.default)("1970-01-01")) {
        this.set("startedAfter", (0, _moment.default)(this.get("startedAfter")).startOf("day").format("YYYY-MM-DD"));
        this.set("startedBefore", (0, _moment.default)(this.get("startedBefore")).endOf("day").format("YYYY-MM-DD"));
      }
    }),
    minStartedAfter: Ember.computed("startedBefore", function () {
      if (!Ember.isEmpty(this.get("startedBefore"))) {
        return (0, _moment.default)(this.get("startedBefore")).subtract(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    maxStartedBefore: Ember.computed("startedAfter", function () {
      if (!Ember.isEmpty(this.get("startedAfter"))) {
        return (0, _moment.default)(this.get("startedAfter")).add(this.get("catalogs.dateLimit"), "d").startOf("day").format("YYYY-MM-DD");
      }
    }),
    actions: {
      openModalAppointment: function openModalAppointment(appointment) {
        this.send("openModalAppointmentDetails", appointment);
      },
      setToday: function setToday() {
        this.set("startedAfter", (0, _moment.default)().startOf("day").format("YYYY-MM-DD"));
        this.set("startedBefore", (0, _moment.default)().endOf("day").format("YYYY-MM-DD"));
      },
      setWeek: function setWeek() {
        this.set("startedBefore", (0, _moment.default)(this.get("startedAfter")).endOf("week").format("YYYY-MM-DD"));
        this.set("startedAfter", (0, _moment.default)(this.get("startedAfter")).startOf("week").format("YYYY-MM-DD"));
      },
      setMonth: function setMonth() {
        this.set("startedBefore", (0, _moment.default)(this.get("startedAfter")).endOf("month").format("YYYY-MM-DD"));
        this.set("startedAfter", (0, _moment.default)(this.get("startedAfter")).startOf("month").format("YYYY-MM-DD"));
      }
    }
  });

  _exports.default = _default;
});