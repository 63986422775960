define("crm/controllers/organizations/organization/periodics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    queryParams: ["nextDateStart", "nextDateEnd"],
    periodicItemTypes: Ember.computed("itemTypes.@each.isPeriodic", function () {
      if (Ember.isEmpty(this.get('itemTypes'))) {
        return [];
      } else {
        return this.get('itemTypes').filter(function (t) {
          return t.get('isPeriodic');
        });
      }
    }),
    filteredModel: Ember.computed("selectedItemType.id", "model", function () {
      var self = this;

      if (this.get("selectedItemType.id")) {
        return this.get("model").filter(function (p) {
          return p.get("type.type.id") === self.get("selectedItemType.id");
        });
      } else {
        return this.get("model");
      }
    }),
    columns: Ember.computed("model.[]", function () {
      return [{
        "propertyName": "nextDate",
        "component": "umt-date",
        "className": "column-date",
        "title": this.get("intl").t("periodics.periodic.nextPeriodic"),
        "sortPrecedence": 0
      }, {
        "propertyName": "patientCode",
        "className": "column-small text-right",
        "title": "",
        "path": "organizations.organization.patients.patient",
        "routeProperty": "patientID"
      }, {
        "propertyName": "patientName",
        "component": "title-models-table-display",
        "className": "column-name text-clinical text-strong",
        "title": this.get("intl").t("patients.patient.title"),
        "path": "organizations.organization.patients.patient",
        "routeProperty": "patientID"
      }, {
        "propertyName": "customerCode",
        "className": "column-small text-right",
        "title": "",
        "path": "organizations.organization.customers.customer",
        "routeProperty": "customerID"
      }, {
        "propertyName": "customerName",
        "component": "title-models-table-display",
        "className": "column-name",
        "title": this.get("intl").t("customers.customer.title"),
        "path": "organizations.organization.customers.customer",
        "routeProperty": "customerID"
      }, {
        "propertyName": "itemName",
        "component": "title-models-table-display",
        "className": "column-name text-strong",
        "title": this.get("intl").t("periodics.periodic.title"),
        "path": "organizations.organization.items.item",
        "routeProperty": "itemID"
      }, {
        "propertyName": "doneDate",
        "component": "umt-date",
        "className": "column-date",
        "title": this.get("intl").t("periodics.periodic.intake")
      }, {
        "propertyName": "tel",
        "className": "column-contact",
        "title": this.get("intl").t("customers.customer.contacts.mobilePhone")
      }];
    }),
    actions: {
      openCreate: function openCreate() {
        this.send("openModal", {
          entity: "periodic",
          action: "create"
        });
      },
      callCampaignNew: function callCampaignNew() {
        var model = {
          periodic: true,
          started: this.get("nextDateStart"),
          ended: this.get("nextDateEnd"),
          audience: "periodics",
          itemType: this.get("selectedItemType")
        };
        this.send("openModal", {
          entity: "campaign",
          action: "new",
          model: model
        });
      },
      search: function search(dates) {
        this.set("nextDateStart", dates.nextDateStart);
        this.set("nextDateEnd", dates.nextDateEnd);
      },

      /*
       * Table
       */
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      gotoAppointmentNew: function gotoAppointmentNew(queryParams) {
        this.send("transitionToRoute", "organizations.organization.appointments.custom", queryParams);
      },
      doNextPeriodic: function doNextPeriodic(periodic) {
        var self = this;
        Ember.set(periodic, "patient", periodic.patient);
        this.get("store").createRecord("periodic", periodic).save().then(function () {
          self.send("reloadModel");
        });
      }
    }
  });

  _exports.default = _default;
});