define("crm/routes/organizations/organization/exams/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      createdAfter: {
        refreshModel: true
      },
      createdBefore: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var organization = this.modelFor("organizations.organization");

      if (!params.createdAfter || !params.createdBefore) {
        return null;
      }

      var createdAfter = (0, _moment.default)(params.createdAfter);
      var createdBefore = (0, _moment.default)(params.createdBefore);

      if (!createdAfter.isValid() || !createdBefore.isValid() || createdBefore.diff(createdAfter, "days") > this.get("dateLimit")) {
        return null;
      }

      createdAfter = createdAfter.startOf("day").format();
      createdBefore = createdBefore.endOf("day").format();
      return Ember.RSVP.hash({
        exams: this.store.query('exam', {
          organization: organization.get('id'),
          requestDateAfter: createdAfter,
          requestDateBefore: createdBefore
        }),
        patients: this.store.query("patient", {
          organization: organization.get('id'),
          requestDateAfter: createdAfter,
          requestDateBefore: createdBefore
        }),
        customers: this.store.query("customer", {
          organization: organization.get('id'),
          ExamRequestDateAfter: createdAfter,
          ExamRequestDateBefore: createdBefore
        })
      }).then(function (res) {
        return res.exams;
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("createdAfter", null);
        controller.set("createdBefore", null);
      }
    },
    actions: {
      openCreateExam: function openCreateExam() {
        this.send('openModalCreateExam');
      },
      openEditExam: function openEditExam(examToEdit) {
        this.send('openEditExamModal', examToEdit);
      }
    }
  });

  _exports.default = _default;
});