define("crm/controllers/organizations/organization/listings/procurement/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    filteredData: Ember.computed("model.items.[]", "model.stock.[]", function () {
      var result = [];
      var items = {};

      if (Ember.isEmpty("model.items") || Ember.isEmpty("model.stock")) {
        return null;
      }

      this.get("model.items").forEach(function (i) {
        items[i.itemId] = i;
      });
      this.get("model.stock").forEach(function (s) {
        var aux = {
          id: s.item,
          name: s.description,
          warehouse: s.warehouse,
          unit: s.unit,
          stock: s.stock,
          value: 0
        };

        if (items[s.item]) {
          var lasPurchaseUnit;

          if (items[s.item].purchaseUnitName && items[s.item].purchaseUnitSize) {
            lasPurchaseUnit = items[s.item].purchaseUnitName + " (" + items[s.item].purchaseUnitSize + ")";
          } else if (items[s.item].quantity) {
            lasPurchaseUnit = s.unit;
          }

          aux["pc"] = items[s.item].unitPrice == null ? items[s.item].pc : items[s.item].unitPrice;
          aux["purchaseUnitName"] = items[s.item].purchaseUnitName;
          aux["value"] = s.stock > 0 && aux["pc"] > 0 ? aux["pc"] * s.stock : null;
          aux["lasPurchaseUnit"] = lasPurchaseUnit;
        }

        result.push(aux);
      });
      return result.sort(function (a, b) {
        return b.value - a.value;
      });
    }),
    total: Ember.computed("filteredData.[]", function () {
      var total = 0;

      if (!Ember.isEmpty(this.get("filteredData"))) {
        this.get("filteredData").forEach(function (e) {
          return total += e.value;
        });
      }

      return total.toFixed(2);
    }),
    columns: Ember.computed('intl.locale', function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "routeName": "organizations.organization.items.item",
        "routeProperty": "Item",
        "className": "column-id"
      }, {
        "propertyName": "name",
        "title": this.get("intl").t("items.item.name"),
        "routeName": "organizations.organization.items.item",
        "routeProperty": "id"
      }, {
        "propertyName": "warehouse",
        "title": this.get("intl").t("warehouses.warehouse.title"),
        "className": "column-contact"
      }, {
        "propertyName": "lasPurchaseUnit",
        "title": this.get("intl").t("purchaseInvoices.purchaseInvoice.title"),
        "className": "column-contact"
      }, {
        "propertyName": "unit",
        "title": this.get("intl").t("units.unit.title"),
        "className": "column-contact"
      }, {
        "propertyName": "stock",
        "title": this.get("intl").t("items.item.stock"),
        "className": "column-id text-right",
        "component": "umt-number"
      }, {
        "propertyName": "pc",
        "title": this.get('intl').t("purchases.price"),
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "propertyName": "value",
        "title": this.get('intl').t("purchaseInvoices.new.total"),
        "component": "umt-price-display",
        "className": "column-price-large text-procurement",
        "sortDirection": "desc",
        "sortPrecedence": 0
      }];
    })
  });

  _exports.default = _default;
});