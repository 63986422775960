define("crm/routes/organizations/organization/consultations/consultation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    afterModel: function afterModel(model) {
      if (!model.get("appointment.id")) {
        this.store.query("appointment", {
          consultation: model.get("id")
        });
      }

      this.store.query("fileConsultation", {
        consultation: model.get("id")
      });
      this.store.query("fileExam", {
        consultation: model.get("id")
      });
      this.store.query("consultation-template-section-field", {
        consultation: model.get("id")
      }, {
        reload: true
      });
      this.store.query("exam", {
        consultation: model.get("id")
      });
      this.store.query("drug", {
        consultation: model.get("id")
      });
      this.store.query("report", {
        consultation: model.get("id")
      }, {
        reload: true
      });
      this.store.query("report-section", {
        consultation: model.get("id")
      }, {
        reload: true
      });
      this.store.query("report-field", {
        consultation: model.get("id")
      }, {
        reload: true
      });
      this.store.query("template-section-field", {
        consultation: model.get("id")
      }, {
        reload: true
      });

      if (model.get("physicalExam.id")) {
        this.store.findRecord("physicalExam", model.get("physicalExam.id"));
      }

      if (model.get("patient.id")) {
        this.store.findRecord("patient", model.get("patient.id"), {
          reload: true
        });
        this.store.query("appointment", {
          patient: model.get("patient.id")
        }); // to display the history

        this.store.query("consultation", {
          patient: model.get("patient.id")
        }); // to display the history

        this.store.query("item", {
          patient: model.get("patient.id"),
          periodic: true
        });
        this.store.query("periodic", {
          patient: model.get("patient.id")
        });
      }

      var self = this;
      this.store.query("shopping-cart", {
        consultation: model.get("id")
      }).then(function (shoppingCarts) {
        if (!Ember.isEmpty(shoppingCarts)) {
          shoppingCarts.forEach(function (shoppingCart) {
            Ember.RSVP.hash({
              items: self.store.query("item", {
                shoppingCart: shoppingCart.get("id")
              }),
              lines: self.store.query("shopping-cart-line", {
                shoppingCart: shoppingCart.get("id")
              })
            });
          });
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (model.get("organization.company.id")) {
        controller.set("templates", this.store.query("template", {
          company: model.get("organization.company.id")
        }));
      }
    }
  });

  _exports.default = _default;
});