define("crm/routes/organizations/organization/listings/procurement/items", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service("session"),
    catalogs: Ember.inject.service(),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    queryParams: {
      endedAfter: {
        refreshModel: true
      },
      endedBefore: {
        refreshModel: true
      }
    },
    model: function model() {
      var organization = this.modelFor("organizations.organization");
      var organizationID = organization.get("id");
      var begin = this.modelFor("organizations.organization.listings").createdAfter;
      var end = this.modelFor("organizations.organization.listings").createdBefore;
      var self = this;
      return Ember.RSVP.hash({
        stock: Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/today?organization=" + organizationID + "&date=" + end,
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        }),
        items: Ember.$.get({
          url: encodeURI(_environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/stats/purchases/lists/items" + "?company=" + organization.get("company.id") + "&createdAfter=" + begin + "&createdBefore=" + end),
          beforeSend: function beforeSend(request) {
            return request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        return {
          stock: r.stock.lines,
          items: r.items.map(function (l) {
            var i = 0;
            var x = {
              itemId: l[i++],
              itemName: l[i++],
              itemPvp: l[i++],
              itemVat: l[i++],
              pc: l[i++],
              baseUnitSymbol: l[i++],
              pilUnitPrice: l[i++],
              pilQuantity: l[i++],
              pilPromo: l[i++],
              pilDiscount: l[i++],
              pilDiscount2: l[i++],
              pilLinePrice: l[i++],
              pilPurchasePrice: l[i++],
              purchaseUnitName: l[i++],
              purchaseUnitSize: l[i++]
            };

            if (x.pilUnitPrice) {
              var itemPurchasePrice = x.pc;
              var unitPrice = x.pilUnitPrice;
              var quantity = x.pilQuantity;
              var promo = x.pilPromo;
              var discount = x.pilDiscount;
              var discount2 = x.pilDiscount2;
              x.pc = unitPrice;
              x.quantity = quantity;

              if (promo) {
                x.pc = unitPrice * quantity / (quantity + promo);
              }

              if (discount) {
                if (discount2) {
                  x.pc = x.pc * (100 - discount) * (100 - discount2) / 10000;
                } else {
                  x.pc = x.pc * (100 - discount) / 100;
                }
              }

              if (x.purchaseUnitSize) {
                x.pc = x.pc / x.purchaseUnitSize;
              }

              x.pc = x.pc || itemPurchasePrice;
            }

            if (x.pc) {
              x.pc = x.pc.toFixed(2);
            }

            return x;
          })
        };
      });
    }
  });

  _exports.default = _default;
});