define("crm/controllers/organizations/organization/consultations/index", ["exports", "moment", "crm/config/environment", "ember-inflector"], function (_exports, _moment, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    catalogs: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: ["endedAfter", "endedBefore"],
    exporting: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set("endedAfter", (0, _moment.default)().format("YYYY-MM-DD"));
      this.set("endedBefore", this.get("endedAfter"));
    },
    canDoCalendar: Ember.computed("can", function () {
      return this.get("can").can("do clinical");
    }),
    columns: Ember.computed(function () {
      return [{
        "className": "column-date-time",
        "propertyName": "date",
        "component": "umt-datetime",
        "title": this.get('intl').t("appointments.appointment.timeConsultation"),
        "sortDirection": "desc",
        "sortPrecedence": 0
      }, {
        "className": "column-name text-clinical text-strong",
        "propertyName": "patient.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("patients.patient.title"),
        "routeName": "organizations.organization.patients.patient",
        "routeProperty": "patient.id"
      }, {
        "className": "column-name",
        "propertyName": "owner.name",
        "component": "title-models-table-display",
        "title": this.get('intl').t("customers.customer.title"),
        "routeName": "organizations.organization.customers.customer",
        "routeProperty": "owner.id"
      }, {
        "className": "column-name hidden-xs hidden-sm text-strong",
        "propertyName": "appointment.display",
        "title": this.get('intl').t("appointments.appointment.appointmentType"),
        "routeName": "organizations.organization.consultations.consultation",
        "routeProperty": "id"
      }, {
        "className": "column-name hidden-xs hidden-sm",
        "propertyName": "worker1.name",
        "title": this.get('intl').t("workers.worker.title")
      }, {
        "className": "column-status-icon hidden-xs",
        "propertyName": "appointment.endConsultation",
        "component": "umt-boolean",
        "title": this.get('intl').t("visits.status.title")
      }, {
        "className": "column-actions-4",
        "component": "visit-actions"
      }];
    }),
    minEndedAfter: Ember.computed("endedBefore", function () {
      if (!Ember.isEmpty(this.get("endedBefore"))) {
        return (0, _moment.default)(this.get("endedBefore")).subtract(this.get("catalogs.dateLimit"), "d").format("YYYY-MM-DD");
      }
    }),
    maxEndedBefore: Ember.computed("endedAfter", function () {
      if (!Ember.isEmpty(this.get("endedAfter"))) {
        return (0, _moment.default)(this.get("endedAfter")).add(this.get("catalogs.dateLimit"), "d").format("YYYY-MM-DD");
      }
    }),
    actions: {
      search: function search(dates) {
        this.set("endedAfter", dates.nextDateStart);
        this.set("endedBefore", dates.nextDateEnd);
      },
      openDetails: function openDetails(model) {
        this.send('openModal', {
          entity: "consultation",
          action: "details",
          model: model
        });
      },
      openModalCreateVisit: function openModalCreateVisit() {
        this.send('openCreateVisit');
      },
      openEditVisitModal: function openEditVisitModal(visitToEdit) {
        this.send('openEditVisit', visitToEdit);
      },
      createConsultation: function createConsultation() {
        this.send("openModal", {
          entity: "consultation",
          action: "new"
        });
      },
      delete: function _delete(model) {
        if (confirm(this.get('intl').t('intl.remove'))) {
          model.destroyRecord();
          this.get('flashMessages').success(this.get('intl').t('appointments.messages.deletedConsultation'));
        }
      },
      showPdf: function showPdf(model) {
        var self = this;
        var modelName = model.constructor.modelName;
        var id = model.get("id"); //model.unloadRecord();

        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("appointment.typeNames").replace(", ", "-") + "_" + (0, _moment.default)(savedDocument.get("date")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale "));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = model.get("patient.code") + "_" + model.get("patient.name") + "_" + model.get("appointment.typeNames").replace(", ", "-") + "_" + (0, _moment.default)(model.get("date")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      },
      export: function _export(before, after) {
        this.set("exporting", true);
        var self = this;
        var startedAfter = (0, _moment.default)(after).startOf("day").add(1, "h").toISOString();
        var startedBefore = (0, _moment.default)(before).endOf("day").add(1, "h").toISOString();
        Ember.$.ajax({
          method: 'GET',
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get('session.session.content.authenticated.access_token'));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/appointments/xlsx?consultation=true&organization=" + self.get("model.id") + "&startedBefore=" + startedBefore + "&startedAfter=" + startedAfter,
          xhrFields: {
            responseType: 'blob'
          },
          success: function success(blob) {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "consultations-" + new Date().toISOString().slice(0, 10) + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
            self.set("exporting", false);
          },
          error: function error() {
            self.set("exporting", false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});