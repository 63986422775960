define("crm/models/todo-monitoring", ["exports", "ember-data", "crm/models/todo"], function (_exports, _emberData, _todo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _todo.default.extend({
    kind: "monitoring",
    intl: Ember.inject.service(),
    internment: _emberData.default.belongsTo('internment', {
      inverse: "monitorings"
    }),
    nextTodo: _emberData.default.belongsTo('todo-monitoring', {
      inverse: 'prevTodo'
    }),
    prevTodo: _emberData.default.belongsTo('todo-monitoring', {
      inverse: 'nextTodo'
    }),
    isPhysicalExam: Ember.computed('name', function () {
      return ["Exame físico", "Examen físico", "Physical exam", "Examen physique"].includes(this.get('name'));
    })
  });

  _exports.default = _default;
});