define("crm/routes/organizations/organization/listings/sales/inflow", ["exports", "crm/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    store: Ember.inject.service("session"),
    breadCrumb: {
      finalTitle: "Recebimentos"
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      var dateFrom2 = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdAfter).startOf("day").format();
      var dateTo2 = (0, _moment.default)(this.paramsFor("organizations.organization.listings").createdBefore).endOf("day").format();
      return Ember.RSVP.hash({
        customers: this.get("store").query("customer", {
          organization: organization.get("id"),
          createdAfter: dateFrom2,
          createdBefore: dateTo2,
          entityName: "Invoice"
        }),
        invoices: this.get("store").query("invoice", {
          organization: organization.get("id"),
          createdAfter: dateFrom2,
          createdBefore: dateTo2
        }),
        receipts: Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/sales/accountsReceivable/receipts" + "?organization=" + organization.get("id") + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          }
        })
      }).then(function (r) {
        var receipts = r.result;
        receipts.sort(function (a, b) {
          return a.ROW_NUMBER < b.ROW_NUMBER ? -1 : a.ROW_NUMBER > b.ROW_NUMBER ? 1 : 0;
        });
        return {
          receipts: receipts,
          invoices: r.invoices.filter(function (i) {
            return ![8, 9].includes(i.get("status")) && ["FR", "FS"].includes(i.get("documentType")) && !Ember.isEmpty(i.get("billingID"));
          }).map(function (i) {
            return {
              CreatedOn: i.get("created"),
              Name: i.get("customer.name"),
              CompanyTaxID: i.get("customer.nif"),
              NaturalKey: i.get("name"),
              PaymentMethodsKey: i.get("paymentMethod"),
              GrossValueAmount: i.get("payableAmount"),
              PayableAmountAmount: i.get("payableAmount"),
              AllowanceChargeAmountAmount: null,
              SettledAmountAmount: i.get("payableAmount"),
              SourceDoc__2: i.get("name"),
              IssueDate: i.get("created"),
              AmountAmount: i.get("payableAmount")
            };
          })
        };
      });
    }
  });

  _exports.default = _default;
});