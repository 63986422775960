define("crm/routes/organizations/organization/listings/sales/receivables", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service("session"),
    model: function model() {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("sales")) {
        return null;
      }

      var self = this;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/sales/financialCore/agingReceivables" + "?organization=" + organization.get("id") + "&company=" + organization.get("company.id") + "&date=" + dateTo,
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        },
        success: function success(result) {
          result.data = result.result;
          /*
                      result.data.sort((a, b) => {
                         if(a.ROW_NUMBER < b.ROW_NUMBER) {
                            return -1;
                         }
                         if(a.ROW_NUMBER > b.ROW_NUMBER) {
                            return 1;
                         }
                         return 0;
                      });
          */

          return result;
        },
        error: function error() {
          return {};
        }
      });
    },
    afterModel: function afterModel() {
      this.set("breadCrumb", {
        finalTitle: this.get("intl").t("pages.currentAccounts")
      });
    }
  });

  _exports.default = _default;
});