define("crm/services/catalogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    dateLimit: Ember.computed(function () {
      return 366;
    }),
    adjustments: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: "1/2",
        value: 0.5
      }, {
        id: 2,
        name: "1",
        value: 1
      }, {
        id: 3,
        name: "2",
        value: 2
      }, {
        id: 4,
        name: "3",
        value: 3
      }];
    }),
    appointmentType: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get("intl").t("appointments.status.scheduled")
      }, {
        id: 2,
        name: this.get("intl").t("appointments.status.checkinWithoutscheduling")
      }, {
        id: 3,
        name: this.get("intl").t("appointments.status.checkinWithScheduling")
      }, {
        id: 4,
        name: this.get("intl").t("appointments.status.inConsultation")
      }, {
        id: 5,
        name: this.get("intl").t("appointments.status.inCheckout")
      }, {
        id: 6,
        name: this.get("intl").t("appointments.status.checkout")
      }, {
        id: 7,
        name: this.get("intl").t("appointments.status.missed")
      }, {
        id: 8,
        name: this.get("intl").t("appointments.status.givenUp")
      }, {
        id: 9,
        name: this.get("intl").t("appointments.status.paused")
      }, {
        id: 10,
        name: this.get("intl").t("appointments.status.getOut")
      }, {
        id: 11,
        name: this.get("intl").t("appointments.status.rescheduledPhrase")
      }];
    }),
    calendarPeriodTypes: Ember.computed("intl", function () {
      return [{
        id: "d",
        name: this.get("intl").t("periodics.periods.day")
      }, {
        id: "w",
        name: this.get("intl").t("periodics.periods.week")
      }, {
        id: "m",
        name: this.get("intl").t("periodics.periods.month")
      }, {
        id: "y",
        name: this.get("intl").t("periodics.periods.year")
      }];
    }),
    colors: Ember.computed("intl", function () {
      return [{
        id: 1,
        color: "AD1457",
        style: Ember.String.htmlSafe("background-color: #AD1457")
      }, {
        id: 2,
        color: "F4511E",
        style: Ember.String.htmlSafe("background-color: #F4511E")
      }, {
        id: 3,
        color: "E4C441",
        style: Ember.String.htmlSafe("background-color: #E4C441")
      }, {
        id: 4,
        color: "0B8043",
        style: Ember.String.htmlSafe("background-color: #0B8043")
      }, {
        id: 5,
        color: "3F51B5",
        style: Ember.String.htmlSafe("background-color: #3F51B5")
      }, {
        id: 6,
        color: "8E24AA",
        style: Ember.String.htmlSafe("background-color: #8E24AA")
      }, {
        id: 7,
        color: "D81B60",
        style: Ember.String.htmlSafe("background-color: #D81B60")
      }, {
        id: 8,
        color: "EF6C00",
        style: Ember.String.htmlSafe("background-color: #EF6C00")
      }, {
        id: 9,
        color: "C0CA33",
        style: Ember.String.htmlSafe("background-color: #C0CA33")
      }, {
        id: 10,
        color: "009688",
        style: Ember.String.htmlSafe("background-color: #009688")
      }, {
        id: 11,
        color: "7986CB",
        style: Ember.String.htmlSafe("background-color: #7986CB")
      }, {
        id: 12,
        color: "795548",
        style: Ember.String.htmlSafe("background-color: #795548")
      }, {
        id: 13,
        color: "D50000",
        style: Ember.String.htmlSafe("background-color: #D50000")
      }, {
        id: 14,
        color: "F09300",
        style: Ember.String.htmlSafe("background-color: #F09300")
      }, {
        id: 15,
        color: "7CB342",
        style: Ember.String.htmlSafe("background-color: #7CB342")
      }, {
        id: 16,
        color: "039BE5",
        style: Ember.String.htmlSafe("background-color: #039BE5")
      }, {
        id: 17,
        color: "B39DDB",
        style: Ember.String.htmlSafe("background-color: #B39DDB")
      }, {
        id: 18,
        color: "616161",
        style: Ember.String.htmlSafe("background-color: #616161")
      }, {
        id: 19,
        color: "E67C73",
        style: Ember.String.htmlSafe("background-color: #E67C73")
      }, {
        id: 20,
        color: "F6BF26",
        style: Ember.String.htmlSafe("background-color: #F6BF26")
      }, {
        id: 21,
        color: "33B679",
        style: Ember.String.htmlSafe("background-color: #33B679")
      }, {
        id: 22,
        color: "4285F4",
        style: Ember.String.htmlSafe("background-color: #4285F4")
      }, {
        id: 23,
        color: "9E69AF",
        style: Ember.String.htmlSafe("background-color: #9E69AF")
      }, {
        id: 24,
        color: "A79B8E",
        style: Ember.String.htmlSafe("background-color: #A79B8E")
      }];
    }),
    customerFieldTypes: Ember.computed("intl", function () {
      return [{
        id: 0,
        name: this.get("intl").t("customerFieldTypes.customerFieldType.types.string")
      }, {
        id: 1,
        name: this.get("intl").t("customerFieldTypes.customerFieldType.types.integer")
      }, {
        id: 2,
        name: this.get("intl").t("customerFieldTypes.customerFieldType.types.date")
      }, {
        id: 3,
        name: this.get("intl").t("customerFieldTypes.customerFieldType.types.boolean")
      }, {
        id: 4,
        name: this.get("intl").t("customerFieldTypes.customerFieldType.types.double")
      }];
    }),
    todoPeriods: Ember.computed("intl", function () {
      return [{
        id: 0,
        name: this.get("intl").t("periodics.periods.drugs.noRepeat")
      }, {
        id: 1,
        name: this.get("intl").t("periodics.periods.drugs.period1")
      }, {
        id: 2,
        name: this.get("intl").t("periodics.periods.drugs.period2")
      }, {
        id: 3,
        name: this.get("intl").t("periodics.periods.drugs.period3")
      }, {
        id: 4,
        name: this.get("intl").t("periodics.periods.drugs.period4")
      }, {
        id: 5,
        name: this.get("intl").t("periodics.periods.drugs.period5")
      }, {
        id: 6,
        name: this.get("intl").t("periodics.periods.drugs.period6")
      }, {
        id: 8,
        name: this.get("intl").t("periodics.periods.drugs.period8")
      }, {
        id: 12,
        name: this.get("intl").t("periodics.periods.drugs.period12")
      }, {
        id: 24,
        name: this.get("intl").t("periodics.periods.drugs.period24")
      }, {
        id: 48,
        name: this.get("intl").t("periodics.periods.drugs.period48")
      }, {
        id: 72,
        name: this.get("intl").t("periodics.periods.drugs.period72")
      }, {
        id: 96,
        name: this.get("intl").t("periodics.periods.drugs.period96")
      }, {
        id: 120,
        name: this.get("intl").t("periodics.periods.drugs.period120")
      }, {
        id: 168,
        name: this.get("intl").t("periodics.periods.drugs.period168")
      }];
    }),
    environmentTypes: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get("intl").t("patients.form.environmentType.indoor")
      }, {
        id: 2,
        name: this.get("intl").t("patients.form.environmentType.outdoor")
      }, {
        id: 3,
        name: this.get("intl").t("patients.form.environmentType.both")
      }];
    }),
    images: Ember.computed("intl", function () {
      return {
        customer: "assets/img/customer.png"
      };
    }),
    inboundTypes: Ember.computed("intl", function () {
      return [{
        id: 0,
        name: this.get("intl").t("inbounds.unknown")
      }, {
        id: 1,
        name: this.get("intl").t("inbounds.customer")
      }, {
        id: 2,
        name: this.get("intl").t("inbounds.facebook")
      }, {
        id: 3,
        name: this.get("intl").t("inbounds.reference")
      }, {
        id: 4,
        name: this.get("intl").t("inbounds.publicity")
      }, {
        id: 5,
        name: this.get("intl").t("inbounds.website")
      }, {
        id: 6,
        name: this.get("intl").t("inbounds.geolocation")
      }, {
        id: 7,
        name: this.get("intl").t("inbounds.partner")
      }];
    }),
    internmentType: function internmentType(type) {
      return this.get("internmentTypes").find(function (t) {
        return t.get("id") === type;
      });
    },
    internmentTypes: Ember.computed("intl", function () {
      return [{
        id: 0,
        name: this.get("intl").t("internmentTypes.outpatient.name")
      }, {
        id: 1,
        name: this.get("intl").t("internmentTypes.hotel.name")
      }, {
        id: 2,
        name: this.get("intl").t("internmentTypes.infected.name")
      }, {
        id: 3,
        name: this.get("intl").t("internmentTypes.reference.name")
      }, {
        id: 4,
        name: this.get("intl").t("internmentTypes.surgery.name")
      }, {
        id: 5,
        name: this.get("intl").t("internmentTypes.normal.name")
      }, {
        id: 6,
        name: this.get("intl").t("internmentTypes.intensiveCare.name")
      }];
    }),
    memosMotives: Ember.computed("intl", function () {
      return [{
        id: 0,
        name: this.get("intl").t("memos.priceError")
      }, {
        id: 1,
        name: this.get("intl").t("memos.stockRuture")
      }, {
        id: 2,
        name: this.get("intl").t("memos.discountAbsence")
      }, {
        id: 3,
        name: this.get("intl").t("memos.rejectedProduct")
      }, {
        id: 4,
        name: this.get("intl").t("memos.priceincrease")
      }];
    }),
    periodTypes: Ember.computed("intl", function () {
      return [{
        id: 24,
        name: this.get("intl").t("periodics.periods.day")
      }, {
        id: 168,
        name: this.get("intl").t("periodics.periods.week")
      }, {
        id: 720,
        name: this.get("intl").t("periodics.periods.month")
      }, {
        id: 8760,
        name: this.get("intl").t("periodics.periods.year")
      }];
    }),
    temperaments: Ember.computed("intl", function () {
      return [{
        id: 1,
        icon: "smile-o",
        color: "navy",
        title: this.get("intl").t("patients.patient.temperament.good")
      }, {
        id: 5,
        icon: "meh-o",
        color: "warning",
        title: this.get("intl").t("patients.patient.temperament.moderate")
      }, {
        id: 9,
        icon: "frown-o",
        color: "danger",
        title: this.get("intl").t("patients.patient.temperament.agressive")
      }];
    }),
    titles: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get("intl").t("titles.mr")
      }, {
        id: 2,
        name: this.get("intl").t("titles.mrs")
      }, {
        id: 3,
        name: this.get("intl").t("titles.miss")
      }, {
        id: 4,
        name: this.get("intl").t("titles.dr")
      }, {
        id: 5,
        name: this.get("intl").t("titles.dra")
      }, {
        id: 6,
        name: this.get("intl").t("titles.engineer")
      }, {
        id: 7,
        name: this.get("intl").t("titles.engineerFemale")
      }, {
        id: 8,
        name: this.get("intl").t("titles.nurse")
      }, {
        id: 9,
        name: this.get("intl").t("titles.nurseFemale")
      }, {
        id: 10,
        name: this.get("intl").t("titles.professor")
      }, {
        id: 11,
        name: this.get("intl").t("titles.professorFemale")
      }, {
        id: 12,
        name: this.get("intl").t("titles.arquitect")
      }, {
        id: 13,
        name: this.get("intl").t("titles.father")
      }, {
        id: 14,
        name: this.get("intl").t("titles.sister")
      }];
    }),
    unit: function unit(id) {
      return this.get("units").find(function (e) {
        return e.id == id;
      });
    },
    units: Ember.computed('intl', function () {
      return [{
        id: 1,
        name: this.get('intl').t('units.ampoules')
      }, {
        id: 2,
        name: this.get('intl').t('units.drops')
      }, {
        id: 3,
        name: this.get('intl').t('units.grams')
      }, {
        id: 4,
        name: this.get('intl').t('units.milligrams')
      }, {
        id: 5,
        name: this.get('intl').t('units.milliliters')
      }, {
        id: 6,
        name: this.get('intl').t('units.capsules')
      }, {
        id: 7,
        name: this.get('intl').t('units.pills')
      }, {
        id: 8,
        name: this.get('intl').t('units.sachets')
      }, {
        id: 9,
        name: this.get('intl').t('units.puff')
      }, {
        id: 10,
        name: this.get('intl').t('units.percentage')
      }, {
        id: 11,
        name: this.get('intl').t('units.internationalUnits')
      }, {
        id: 12,
        name: this.get('intl').t('units.milliequivalents')
      }, {
        id: 13,
        name: this.get('intl').t('units.milligramsPerMilliliter')
      }, {
        id: 14,
        name: this.get('intl').t('units.milligramsPerKilogram')
      }, {
        id: 15,
        name: this.get('intl').t('units.microgramsPerMilliliter')
      }, {
        id: 16,
        name: this.get('intl').t('units.millilitersPerKilogram')
      }].sort(function (a, b) {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0;
      });
    }),
    variationTypes: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get("intl").t("patients.patient.weights.weight.title")
      }];
    }),
    viaType: function viaType(id) {
      return this.get("viaTypes").find(function (e) {
        return e.id == id;
      });
    },
    viaTypes: Ember.computed("intl", function () {
      return [{
        id: 1,
        name: this.get("intl").t("via.oral")
      }, {
        id: 2,
        name: this.get("intl").t("via.subcutaneousInjectable")
      }, {
        id: 3,
        name: this.get("intl").t("via.intramuscularInjectable")
      }, {
        id: 4,
        name: this.get("intl").t("via.intravenousInjectable")
      }, {
        id: 5,
        name: this.get("intl").t("via.ocularBoth")
      }, {
        id: 6,
        name: this.get("intl").t("via.ocularLeft")
      }, {
        id: 7,
        name: this.get("intl").t("via.ocularRight")
      }, {
        id: 8,
        name: this.get("intl").t("via.inhalation")
      }, {
        id: 9,
        name: this.get("intl").t("via.intraperitonealInjectable")
      }, {
        id: 10,
        name: this.get("intl").t("via.intraosseousInjectable")
      }, {
        id: 11,
        name: this.get("intl").t("via.rectal")
      }, {
        id: 12,
        name: this.get("intl").t("via.IntralesionalInjectable")
      }, {
        id: 13,
        name: this.get("intl").t("via.auricularBoth")
      }, {
        id: 14,
        name: this.get("intl").t("via.auricularLeft")
      }, {
        id: 15,
        name: this.get("intl").t("via.auricularRight")
      }, {
        id: 16,
        name: this.get("intl").t("via.topical")
      }, {
        id: 17,
        name: this.get("intl").t("via.transdermal")
      }, {
        id: 18,
        name: this.get("intl").t("via.inhalation")
      }, {
        id: 19,
        name: this.get("intl").t("via.nasogastric")
      }].sort(function (a, b) {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0;
      });
    }),
    workerRoles: Ember.computed("intl", function () {
      return [{
        id: 2,
        name: this.get("intl").t("workers.worker.roles.veterinary")
      }, {
        id: 3,
        name: this.get("intl").t("workers.worker.roles.nurse")
      }, {
        id: 4,
        name: this.get("intl").t("workers.worker.roles.assistant")
      }, {
        id: 5,
        name: this.get("intl").t("workers.worker.roles.receptionist")
      }, {
        id: 0,
        name: this.get("intl").t("workers.worker.roles.other")
      }];
    })
  });

  _exports.default = _default;
});