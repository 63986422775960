define("crm/routes/organizations/organization/inventory/movements", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalogs: Ember.inject.service(),
    intl: Ember.inject.service("session"),
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service("session"),
    dateLimit: Ember.computed.alias("catalogs.dateLimit"),
    worker: Ember.computed.readOnly('sessionAccount.currentWorker'),
    queryParams: {
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var self = this;
      var cID = self.get("worker.organization.id");
      var start = params.start || (0, _moment.default)().endOf("day").add(-30, "days").format("YYYY-MM-DD");
      var end = params.end || (0, _moment.default)().endOf("day").format("YYYY-MM-DD");

      if ((0, _moment.default)(end).diff(start, "days") <= this.get("dateLimit")) {
        return Ember.$.ajax({
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/movements?organization=" + cID + "&start=" + start + "&end=" + end,
          accept: "application/json",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(result) {
            result.data = result.result;
            return result;
          },
          error: function error() {
            return {};
          }
        });
      } else {
        return [];
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("createdAfter", null);
        controller.set("createdBefore", null);
      }
    }
  });

  _exports.default = _default;
});