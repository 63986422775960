define("crm/translations/fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contacts": "Contacts"
    },
    "absences": {
      "approve": {
        "title": "Approuver les demandes"
      },
      "edit": {
        "title": "Modifier l'absence"
      },
      "new": {
        "title": "Nouvelle absence"
      },
      "title": "Absences",
      "type": {
        "funeral": "Funérailles",
        "holidays": "Vacances",
        "marriage": "Mariage",
        "parental": "Parental",
        "sick": "Congé maladie",
        "title": "Type d'absence"
      }
    },
    "account": {
      "emailUs": "Envoyez-nous un email",
      "freeVersion": "Version gratuite!",
      "getStarted": {
        "addData": "Ajoutez vos données d'hôpital ou de clinique et cliquez sur « Créer ».",
        "clickCreate": "Cliquez sur « Créer ».",
        "clickPlus": "Cliquez sur le bouton « + Enregistrer Hôpital » pour vous inscrire.",
        "guide": "Voir le guide de démarrage",
        "seeDetails": "Une fois l'animal enregistré, vous pourrez ensuite le prendre en charge.",
        "step1": "Étape 1",
        "step1Done": "CAMV enregistré avec succès.",
        "step2": "Étape 2",
        "step2Done": "Animaux déjà enregistrés.",
        "step3": "Étape 3",
        "title1": "Enregistrer le CAMV",
        "title2": "Enregistrer votre premier patient",
        "title3": "Effectuer une hospitalisation"
      },
      "limitedUse": "Certaines fonctionnalités peuvent être limitées. Pour débloquer, envoyez-nous un",
      "personEditSubtitle": "Modifiez vos informations personnelles.",
      "securityEditSubtitle": "Changez vos informations de connexion.",
      "settingsSubtitle": "Personnalisez le système selon vos préférences.",
      "softwareUpdated": "Une mise à jour est disponible - module {module} version : {version}.<br><b>Cliquez ici pour mettre à jour!</b>",
      "title": "Compte"
    },
    "actions": {
      "addLine": "Ajouter une ligne",
      "advanceOneMonth": "Avancer d'un mois",
      "approve": "Approuver",
      "bill": "Facturer",
      "checkIn": "Enregistrement",
      "checkOut": "Sortie",
      "checkOutTitle": "Marquer comme fermé sans quitter cette fiche",
      "checkedOut": "Sorti",
      "checkoutWithoutPaying": "Sortir sans payer",
      "clear": "Effacer les filtres",
      "clearCart": "Nettoyer",
      "close": "Fermer",
      "continue": "Continuer",
      "create": {
        "lastAction": "Dernière action"
      },
      "currentHour": "Date et heure actuelles",
      "delete": "Supprimer",
      "details": "Détails",
      "disable": "Désactiver",
      "doTask": "Effectuer la tâche",
      "download": "Télécharger",
      "edit": "Éditer",
      "editProfile": "Éditer le profil",
      "enable": "Activer",
      "filters": "Filtres",
      "finalConsumer": "Consommateur final",
      "giveNext": "Donner le suivant",
      "giveUp": "Abandonner",
      "goBackOneMonth": "Reculer d'un mois",
      "goToConsultation": "Aller à la consultation",
      "makeSale": "Réaliser une vente",
      "markAsPaid": "Marquer comme payé",
      "memos": "Émettre des notes de crédit",
      "missed": "Absent",
      "moveStock": "Déplacer le stock",
      "newPurchaseInvoiceLine": "Nouvelle ligne d'achat",
      "newScheduling": "Planifier",
      "openConsultation": "Ouvrir la consultation",
      "openShoppingCartDetails": "Détails du panier",
      "openShoppingCartEdit": "Modifier le panier",
      "paid": "Payé",
      "pay": "Payer",
      "print": "Imprimer",
      "receive": "Recevoir",
      "recordResults": "Enregistrer les résultats",
      "reject": "Rejeter",
      "removeAppointmentConsultation": "Êtes-vous sûr de vouloir supprimer ce rendez-vous ?",
      "reschedule": "Reprogrammer",
      "rescheduled": "Reprogrammé",
      "roundPrices": "Arrondir",
      "schedule": "Planifier",
      "scheduled": "Planifié",
      "search": "Client ou Patient",
      "searchItems": "Rechercher des articles",
      "selectCustomer": "Sélectionner un client",
      "selectItem": "Sélectionner un article",
      "selectPatient": "Sélectionner un patient",
      "send": "Envoyer",
      "showInvoice": "Générer la facture",
      "title": "Actions",
      "transferStock": "Transférer le stock",
      "undoTask": "Réouvrir la tâche",
      "view": "Vue",
      "viewItem": "Voir l'article",
      "viewProfile": "Voir le profil",
      "viewTask": "Voir la tâche"
    },
    "adjustmentReasons": {
      "adjustmentReason": {
        "title": "Raison"
      }
    },
    "alerts": {
      "adjustmentWithItemStock": "Ce motif est utilisé uniquement pour les articles qui n'ont jamais eu de stock initial",
      "appointmentCheckout": "Consultation terminée",
      "appointmentGivenUp": "Le client a renoncé",
      "appointmentMissed": "Le client était absent",
      "appointmentRescheduled": "Consultation reprogrammée",
      "changesSaved": "Modifications enregistrées",
      "creatingItem": "Création de nouveaux articles",
      "customerWithoutNif": "Impossible de payer avec les informations de facturation du client si le client sélectionné n'a pas de NIF associé",
      "done": "Êtes-vous sûr de vouloir marquer cette tâche comme terminée ?",
      "inProgress": "En cours...",
      "inactiveCustomer": "Ce client est inactif",
      "inactivePatient": "Ce patient est inactif",
      "invalidItems": "Il y a des articles invalides",
      "leave": "Si vous sortez, toutes les modifications seront perdues",
      "missingOwner": "Il n'y a pas de propriétaire associé",
      "missingQuotation": "Ce client n'a aucun devis",
      "missingWeight": "Il n'y a pas de poids enregistré",
      "mustExistPrice": "Une ligne doit avoir un prix",
      "negativeStock": "La quantité ne peut pas être inférieure à 0",
      "noDiagnostic": "Aucun diagnostic ajouté",
      "noDrugs": "Aucun médicament ajouté",
      "noMcd": "Aucun examen ajouté",
      "noSpaces": "Ce champ n'accepte pas d'espaces ou d'accents",
      "noSymptoms": "Aucun symptôme ajouté",
      "noTreatmentPlan": "Aucun plan de traitement ajouté",
      "noWarehouseItems": "Cet entrepôt n'a aucun article",
      "referralCustomer": "Ce client est référencé",
      "referralPatient": "Ce patient est référencé",
      "roomAllreadyWithAppointment": "Cette pièce possède déjà un marquage associé à cette période",
      "sendEmail": "Êtes-vous sûr de vouloir envoyer cet email ?",
      "simplifiedInvoiceWarning": "Une facture simplifiée ne peut pas avoir un total supérieur à 100",
      "transferDatesMessage": "La date de chargement doit être avant la date de déchargement",
      "weightUpdated": "Le poids n'est pas à jour, veuillez mettre à jour pour continuer",
      "workerAllreadyWithAppointment": "Ce travailleur a déjà un rendez-vous prévu pour cette période"
    },
    "application": {
      "loading": "Chargement"
    },
    "appointments": {
      "appointment": {
        "appointmentDate": "Date de rendez-vous",
        "appointmentType": "Type de rendez-vous",
        "arrivalTime": "Heure d'arrivée",
        "calls": "Appels",
        "dateHasPassed": "Vous ne pouvez pas prendre rendez-vous pour une date passée",
        "end": "Date de fin",
        "endConsultation": "Fin de consultation",
        "exitTime": "Heure de sortie",
        "giveUpDate": "Date d'abandon",
        "giveUpReason": "Raison de l'abandon",
        "googleAppointmentId": "Identifiant Google du rendez-vous",
        "madeBy": "Effectué par",
        "missedDate": "Date d'absence",
        "missedReason": "Raison de l'absence",
        "note": "Note",
        "notice": "Avis",
        "noticeDate": "Date de l'avis",
        "observation": "Observations",
        "periodicInfo": "Informations périodiques",
        "receptionNotes": "Notes de réception",
        "recurrenceInfo": "Informations sur la récurrence",
        "resource": "Ressource",
        "scheduledBy": "Programmé par",
        "scheduledFor": "Programmé pour",
        "start": "Date de début",
        "status": "Statut",
        "subject": "Sujet",
        "timeConsultation": "Heure de la consultation",
        "title": "Rendez-vous",
        "type": "Type",
        "weight": "Poids"
      },
      "checkin": "Enregistrement",
      "edit": {
        "title": "Modifier le rendez-vous"
      },
      "filters": {
        "noFilters": "Aucun filtre sélectionné",
        "title": "Filtres"
      },
      "messages": {
        "checkOut": "Sortie effectuée avec succès.",
        "deleted": "Rendez-vous supprimé",
        "deletedConsultation": "Consultation supprimée",
        "reschedule": "Veuillez choisir une nouvelle date pour le rendez-vous de :"
      },
      "new": {
        "associatePatient": "Associer un patient",
        "changeCustomer": "Changer de client",
        "changePatientAndCustomer": "Changer de patient et de client",
        "changeSupplier": "Changer de fournisseur",
        "completeRecord": "Compléter le dossier",
        "expectedDuration": "Durée prévue",
        "fast": {
          "title": "Prise de rendez-vous rapide"
        },
        "noDataToShow": "Pas de données à afficher",
        "reason": "Raison",
        "repetition": "Répétition",
        "title": "Nouveau rendez-vous",
        "to": "à",
        "worker": "Sélectionner un employé"
      },
      "noWorkerAssigned": "Aucun employé assigné",
      "status": {
        "checkinWithScheduling": "Enregistrement avec rendez-vous",
        "checkinWithoutscheduling": "Enregistrement sans rendez-vous",
        "checkout": "Sortie",
        "finished": "Terminé",
        "getOut": "Sortir",
        "givenUp": "Abandonné",
        "inCheckout": "En cours de sortie",
        "inConsultation": "En consultation",
        "inProgress": "En cours",
        "inVisit": "En visite",
        "missed": "Absent",
        "paused": "En pause",
        "rescheduledPhrase": "Reprogrammé",
        "scheduled": "Programmé"
      },
      "title": "Rendez-vous"
    },
    "approvals": {
      "approve": {
        "title": "Approuver"
      },
      "messages": {
        "approve": "Êtes-vous sûr de vouloir approuver cette absence ?",
        "reject": "Êtes-vous sûr de vouloir rejeter cette absence ?"
      },
      "reject": {
        "title": "Rejeter"
      },
      "status": {
        "approved": "Approuvé",
        "pending": "En attente",
        "rejected": "Rejeté",
        "title": "Statut"
      },
      "title": "Approbations des absences"
    },
    "avatar": {
      "accessories": {
        "kurt": "Kurt",
        "prescription1": "Prescription 1",
        "prescription2": "Prescription 2",
        "round": "Rondes",
        "sunglasses": "Lunettes de soleil",
        "title": "Accessoires",
        "wayfarers": "Wayfarers"
      },
      "clothes": {
        "blazerShirt": "Chemise de blazer",
        "blazerSweater": "Blazer en laine",
        "collarSweater": "Pull à col",
        "graphicShirt": "Chemise graphique",
        "hoodie": "Sweat à capuche",
        "overall": "Salopette",
        "shirtCrewNeck": "T-shirt à col rond",
        "shirtScoopNeck": "T-shirt à col échancré",
        "shirtVNeck": "T-shirt en V",
        "title": "Vêtements"
      },
      "colorFabric": {
        "black": "Noir",
        "blue1": "Bleu 1",
        "blue2": "Bleu 2",
        "blue3": "Bleu 3",
        "gray1": "Gris 1",
        "gray2": "Gris 2",
        "heather": "Bruyère",
        "pastelBlue": "Bleu pastel",
        "pastelGreen": "Vert pastel",
        "pastelOrange": "Orange pastel",
        "pastelRed": "Rouge pastel",
        "pastelYellow": "Jaune pastel",
        "pink": "Rose",
        "red": "Rouge",
        "title": "Couleurs du tissu",
        "white": "Blanc"
      },
      "eyebrow": {
        "angry": "Fâché",
        "angryNatural": "Fâché naturel",
        "default": "Défaut",
        "defaultNatural": "Défaut naturel",
        "flatNatural": "Plat naturel",
        "raisedExcited": "Excité levé",
        "raisedExcitedNatural": "Excité levé naturel",
        "sadConcerned": "Triste",
        "sadConcernedNatural": "Triste naturel",
        "title": "Sourcil",
        "unibrowNatural": "Unisourcil",
        "upDown": "Haut bas",
        "upDownNatural": "Haut bas naturel"
      },
      "eyes": {
        "close": "Fermés",
        "cry": "Pleurer",
        "default": "Défaut",
        "dizzy": "Étourdi",
        "eyeRoll": "Roulement des yeux",
        "happy": "Heureux",
        "hearts": "Cœurs",
        "side": "Latéral",
        "squint": "Squint",
        "surprised": "Surpris",
        "title": "Yeux",
        "wink": "Clin d'œil",
        "winkWacky": "Clin d'œil fou"
      },
      "hatColor": {
        "blue1": "Bleu 1",
        "blue2": "Bleu 2",
        "blue3": "Bleu 3",
        "gray1": "Gris 1",
        "gray2": "Gris 2",
        "heather": "Bruyère",
        "pastelBlue": "Bleu pastel",
        "pastelGreen": "Vert pastel",
        "pastelOrange": "Orange pastel",
        "pastelRed": "Rouge pastel",
        "pastelYellow": "Jaune pastel",
        "pink": "Rose",
        "red": "Rouge",
        "title": "Couleur du chapeau",
        "white": "Blanc"
      },
      "mouth": {
        "concerned": "Préoccupé",
        "default": "Défaut",
        "disbelief": "Incrédulité",
        "eating": "Manger",
        "grimace": "Grimace",
        "sad": "Triste",
        "screamOpen": "Peur",
        "serious": "Sérieux",
        "smile": "Sourire",
        "title": "Bouche",
        "tongue": "Langue",
        "twinkle": "Étincelle",
        "vomit": "Vomir"
      },
      "skin": {
        "black": "Noir",
        "brown": "Marron",
        "darkBrown": "Marron foncé",
        "light": "Lumière",
        "pale": "Pâle",
        "tanned": "Bronzé",
        "title": "Peau",
        "yellow": "Jaune"
      },
      "top": {
        "ShortHairShortFlat": "Cheveux courts plats",
        "eyepatch": "Cache-œil",
        "hat": "Chapeau",
        "hijab": "Hijab",
        "longHairBigHair": "Cheveux longs",
        "longHairBob": "Bob à cheveux longs",
        "longHairBun": "Chignon à cheveux longs",
        "longHairCurly": "Cheveux longs bouclés",
        "longHairCurvy": "Cheveux longs ondulés",
        "longHairDreads": "Dreadlocks",
        "longHairFrida": "Frida à cheveux longs",
        "longHairFro": "Afro à cheveux longs",
        "longHairFroBand": "Bandeau pour afro à cheveux longs",
        "longHairMiaWallace": "Mia Wallace à cheveux longs",
        "longHairNotTooLong": "Cheveux longs pas trop longs",
        "longHairShavedSides": "Cheveux longs avec côtés rasés",
        "longHairStraight1": "Cheveux longs droits 1",
        "longHairStraight2": "Cheveux longs droits 2",
        "longHairStraightStrand": "Cheveux longs droits avec mèche",
        "shortHairDreads1": "Courte dreadlocks 1",
        "shortHairDreads2": "Courte dreadlocks 2",
        "shortHairFrizzle": "Cheveux courts frisés",
        "shortHairShaggyMullet": "Cheveux courts en coupe mulet",
        "shortHairShortCurly": "Cheveux courts bouclés courts",
        "shortHairShortRound": "Cheveux courts arrondis",
        "shortHairShortWaved": "Cheveux courts ondulés",
        "shortHairSides": "Cheveux courts sur les côtés",
        "shortHairTheCaesar": "Cheveux courts à la César",
        "shortHairTheCaesarSidePart": "Cheveux courts à la César avec raie sur le côté",
        "title": "Tête",
        "turban": "Turban",
        "winterHat1": "Chapeau d'hiver 1",
        "winterHat2": "Chapeau d'hiver 2",
        "winterHat3": "Chapeau d'hiver 3",
        "winterHat4": "Chapeau d'hiver 4"
      }
    },
    "boxes": {
      "create": "Créer Box",
      "edit": "Modifier Box",
      "name": "Nom",
      "status": {
        "checkin": "Check-in",
        "checkout": "Check-out",
        "free": "Libre",
        "occupied": "Occupé",
        "title": "Statut"
      },
      "title": "Boxes"
    },
    "brands": {
      "brand": {
        "name": "Nom",
        "title": "Marque"
      },
      "description": "Voir toutes les marques",
      "form": {
        "placeholder": {
          "name": "Nom"
        }
      },
      "messages": {
        "createdSuccess": "Marque créée avec succès"
      },
      "new": {
        "title": "Nouvelle marque"
      },
      "title": "Marques"
    },
    "breeds": {
      "0": "Indéterminé",
      "1": "Pointer",
      "10": "Border Terrier",
      "100": "Griffon d'arrêt à poil dur Korthals",
      "101": "Épagneul Picard",
      "102": "Clumber Spaniel",
      "103": "Retriever à poil bouclé",
      "104": "Golden Retriever",
      "105": "Berger de Brie",
      "106": "Épagneul de Pont-Audemer",
      "107": "Braque Saint-Germain",
      "108": "Dogue de Bordeaux",
      "109": "Deutsch Langhaar",
      "11": "Bull Terrier",
      "110": "Grand Münsterländer",
      "111": "Chien d'arrêt allemand à poil court",
      "112": "Setter irlandais",
      "113": "Retriever à poil plat",
      "114": "Labrador Retriever",
      "115": "Field Spaniel",
      "116": "Épagneul d'eau irlandais",
      "117": "Épagneul Springer anglais",
      "118": "Épagneul Springer gallois",
      "119": "Sussex Spaniel",
      "12": "Fox Terrier à poil lisse",
      "120": "King Charles Spaniel",
      "121": "Smålandsstövare",
      "122": "Drever",
      "123": "Schillerstövare",
      "124": "Hamiltonstövare",
      "125": "Chien d'arrêt français type Gascogne",
      "126": "Chien d'arrêt français type Pyrénées",
      "127": "Chien finnois de Laponie",
      "128": "Cavalier King Charles Spaniel",
      "129": "Chien de montagne des Pyrénées",
      "13": "English Toy Terrier (noir & feu)",
      "130": "Berger des Pyrénées à face rase",
      "131": "Irish Terrier",
      "132": "Boston Terrier",
      "133": "Berger des Pyrénées à poil long",
      "134": "Chuvatch slovaque",
      "135": "Dobermann",
      "136": "Boxer",
      "137": "Leonberg",
      "138": "Rhodesian Ridgeback",
      "139": "Rottweiler",
      "14": "Chien de berger Suédois (Vallhund Suédois)",
      "140": "Bulldog",
      "141": "Serbian Hound",
      "142": "Istrian Short",
      "143": "Istrian Wire-haired Hound",
      "144": "Dalmatian",
      "145": "Posavac Hound",
      "146": "Bosnian Coarse-haired Hound",
      "147": "Collie Rough",
      "148": "Bullmastiff",
      "149": "Greyhound",
      "15": "Berger Belge",
      "150": "English Foxhound",
      "151": "Irish Wolfhound",
      "152": "Beagle",
      "153": "Whippet",
      "154": "Basset Hound",
      "155": "Deerhound",
      "156": "Italian Spinone",
      "157": "German Shepherd Dog",
      "158": "American Cocker Spaniel",
      "159": "Dandie Dinmont Terrier",
      "16": "Bobtail (Chien de berger Anglais anciennement)",
      "160": "Wire Fox Terrier",
      "161": "Cão de Castro Laboreiro",
      "162": "Bouvier des Ardennes",
      "163": "Poodle",
      "164": "Estrela Mountain Dog",
      "165": "French Spaniel",
      "166": "Picardy Sheepdog",
      "167": "Ariege Pointing Dog",
      "168": "Bourbonnais Pointing Dog",
      "169": "Auvergne Pointer",
      "17": "Griffon Nivernais",
      "170": "Giant Schnauzer",
      "171": "Schnauzer",
      "172": "Miniature Schnauzer",
      "173": "German Pinscher",
      "174": "Pinscher",
      "175": "Affenpinscher",
      "176": "Portuguese Pointer",
      "177": "Sloughi",
      "178": "Finnish Lapphund",
      "179": "Hovawart",
      "18": "Briquet Griffon Vendéen",
      "180": "Bouvier des Flandres",
      "181": "Kromfohrlander",
      "182": "Borzoi",
      "183": "Bergamasco Sheepdog",
      "184": "Volpino Italiano",
      "185": "Bolognese",
      "186": "Neapolitan Mastiff",
      "187": "Italian Rough-haired Segugio",
      "188": "Cirneco dell'Etna",
      "189": "Italian Greyhound",
      "19": "Ariégeois",
      "190": "Maremma Sheepdog",
      "191": "Italian Pointer",
      "192": "Norwegian Hound",
      "193": "Spanish Hound",
      "194": "Chow Chow",
      "195": "Japanese Chin",
      "196": "Pekingese",
      "197": "Shih Tzu",
      "198": "Tibetan Terrier",
      "199": "Canadian Eskimo Dog",
      "2": "Setter Anglais",
      "20": "Gascon Saintongeois",
      "200": "Samoyed",
      "201": "Hanoverian Scenthound",
      "202": "Hellenic Hound",
      "203": "Bichon Frise",
      "204": "Pudelpointer",
      "205": "Bavarian Mountain Scent Hound",
      "206": "Chihuahua",
      "207": "French Tricolour Hound",
      "208": "French White and Black Hound",
      "209": "Frisian Water Dog",
      "21": "Grand Bleu de Gascogne",
      "210": "Stabyhoun",
      "211": "Dutch Shepherd Dog",
      "212": "Drentse Patrijshond",
      "213": "Fila Brasileiro",
      "214": "Landseer",
      "215": "Lhasa Apso",
      "216": "Afghan Hound",
      "217": "Serbian Tricolour Hound",
      "218": "Tibetan Mastiff",
      "219": "Tibetan Spaniel",
      "22": "Poitevin",
      "220": "German Shorthaired Pointer",
      "221": "Little Lion Dog",
      "222": "Xoloitzcuintle",
      "223": "Great Dane",
      "224": "Australian Silky Terrier",
      "225": "Norwegian Buhund",
      "226": "Mudi",
      "227": "Hungarian Wire-haired Pointer",
      "228": "Hungarian Greyhound",
      "229": "Transylvanian Hound",
      "23": "Billy",
      "230": "Norwegian Elkhound",
      "231": "Alaskan Malamute",
      "232": "Slovakian Hound",
      "233": "Bohemian Wire-haired Pointing Griffon",
      "234": "Cesky Terrier",
      "235": "Atlas Mountain Dog",
      "236": "Pharaoh Hound",
      "237": "Majorca Mastiff",
      "238": "Havanese",
      "239": "Polish Lowland Sheepdog",
      "24": "Chien d'Artois",
      "240": "Tatra Shepherd Dog",
      "241": "Pug",
      "242": "Alpine Dachsbracke",
      "243": "Akita",
      "244": "Shiba Inu",
      "245": "Japanese Terrier",
      "246": "Tosa",
      "247": "Hokkaido",
      "248": "Japanese Spitz",
      "249": "Chesapeake Bay Retriever",
      "25": "Porcelaine",
      "250": "Mastiff",
      "251": "Norwegian Lundehund",
      "252": "Hygen Hound",
      "253": "Halden Hound",
      "254": "Norwegian Elkhound Black",
      "255": "Saluki",
      "256": "Siberian Husky",
      "257": "Bearded Collie",
      "258": "Norfolk Terrier",
      "259": "Canaan Dog",
      "26": "Petit Bleu de Gascogne",
      "260": "Greenland Dog",
      "261": "Norrbottenspets",
      "262": "Croatian Shepherd Dog",
      "263": "Karst Shepherd",
      "264": "Montenegrin Mountain Hound",
      "265": "Old Danish Pointing Dog",
      "266": "Grand Griffon Vendéen",
      "267": "Coton de Tulear",
      "268": "Lapponian Herder",
      "269": "Spanish Greyhound",
      "27": "Griffon Bleu de Gascogne",
      "270": "American Staffordshire Terrier",
      "271": "Australian Cattle Dog",
      "272": "Chinese Crested Dog",
      "273": "Icelandic Sheepdog",
      "274": "Beagle-Harrier",
      "275": "Eurasier",
      "276": "Dogo Argentino",
      "277": "Australian Kelpie",
      "278": "Otterhound",
      "279": "Harrier",
      "28": "Grand Basset Griffon Vendéen",
      "280": "Collie Smooth",
      "281": "Border Collie",
      "282": "Romagna Water Dog",
      "283": "German Shepherd Dog",
      "284": "Black And Tan Coonhound",
      "285": "American Water Spaniel",
      "286": "Glen of Imaal Terrier",
      "287": "American Foxhound",
      "288": "Russian-European Laika",
      "289": "East Siberian Laika",
      "29": "Basset Artésien Normand",
      "290": "West Siberian Laika",
      "291": "Azawakh",
      "292": "Dutch Smoushond",
      "293": "Shar Pei",
      "294": "Peruvian Hairless Dog",
      "295": "Saarloos Wolfdog",
      "296": "Nova Scotia Duck Tolling Retriever",
      "297": "Dutch Schapendoes",
      "298": "Nederlandse Kooikerhondje",
      "299": "Broholmer",
      "3": "Kerry Blue Terrier",
      "30": "Basset Bleu de Gascogne",
      "300": "French White and Orange Hound",
      "301": "Kai Ken",
      "302": "Kishu Ken",
      "303": "Shikoku",
      "304": "Wirehaired Slovakian Pointer",
      "305": "Majorca Shepherd Dog",
      "306": "Great Anglo-French Tricolour Hound",
      "307": "Great Anglo-French White and Black Hound",
      "308": "Great Anglo-French White and Orange Hound",
      "309": "Medium-Sized Anglo-French Hound",
      "31": "Basset Fauve de Bretagne",
      "310": "South Russian Shepherd Dog",
      "311": "Russian Black Terrier",
      "312": "Caucasian Shepherd Dog",
      "313": "Canarian Warren Hound",
      "314": "Irish Red and White Setter",
      "315": "Kangal Shepherd Dog",
      "316": "Czechoslovakian Wolfdog",
      "317": "Polish Greyhound",
      "318": "Korea Jindo Dog",
      "319": "Central Asian Shepherd Dog",
      "32": "Chien d'Eau Portugais",
      "320": "Spanish Water Dog",
      "321": "Italian Short-Haired Segugio",
      "322": "Thai Ridgeback",
      "323": "Parson Russell Terrier",
      "324": "Azorean Cattle Dog",
      "325": "Brazilian Terrier",
      "326": "Australian Shepherd",
      "327": "Cane Corso",
      "328": "American Akita",
      "329": "Jack Russell Terrier",
      "33": "Welsh Corgi Cardigan",
      "330": "Dogo Canario",
      "331": "White Swiss Shepherd",
      "332": "Taiwan Dog",
      "333": "Romanian Mioritic Shepherd Dog",
      "334": "Romanian Carpathian Shepherd Dog",
      "335": "Russian Toy",
      "336": "Cimarron Uruguayo",
      "337": "Polish Hunting Dog",
      "338": "Bosnian and Herzegovinian-Croatian Shepherd Dog",
      "339": "Miniature Bull Terrier",
      "34": "Welsh Corgi Pembroke",
      "340": "Abyssinian",
      "341": "American Bobtail",
      "342": "American Bobtail Shorthair",
      "343": "American Curl",
      "344": "American Curl Longhair",
      "345": "American Curl Shorthair",
      "346": "American Shorthair",
      "347": "American Wirehair",
      "348": "Australian Mist",
      "349": "Balinese",
      "35": "Irish Soft Coated Wheaten Terrier",
      "350": "Bengal",
      "351": "Bengal Longhair",
      "352": "Birman",
      "353": "Bombay",
      "354": "British",
      "355": "British Longhair",
      "356": "British Shorthair",
      "357": "Burmese",
      "358": "Burmilla",
      "359": "Burmilla Longhair",
      "36": "Berger de Yougoslavie",
      "360": "Chartreux",
      "361": "Chausie",
      "362": "Colorpoint Shorthair",
      "363": "Cornish Rex",
      "364": "Cymric",
      "365": "Devon Rex",
      "366": "Donskoy",
      "367": "Egyptian Mau",
      "368": "European",
      "369": "European Burmese",
      "37": "Chien d'élan suédois",
      "370": "Exotic",
      "371": "Exotic Shorthair",
      "372": "Havana Brown",
      "373": "German Rex",
      "374": "Himalayan",
      "375": "Javanese",
      "376": "Japanese Bobtail",
      "377": "Japanese Bobtail Longhair",
      "378": "Khao Manee",
      "379": "Korat",
      "38": "Basenji",
      "380": "Kurilian Bobtail",
      "381": "Kurilian Bobtail Shorthair",
      "382": "Kurilian Bobtail Longhair",
      "383": "LaPerm",
      "384": "LaPerm Shorthair",
      "385": "Lykoi",
      "386": "Maine Coon",
      "387": "Manx",
      "388": "Minuet",
      "389": "Minuet Longhair",
      "39": "Berger de Beauce",
      "390": "Munchkin",
      "391": "Munchkin Longhair",
      "392": "Nebelung",
      "393": "Norwegian Forest Cat",
      "394": "Ocicat",
      "395": "Oriental",
      "396": "Oriental Shorthair",
      "397": "Oriental Longhair",
      "398": "Persian",
      "399": "Peterbald",
      "4": "Cairn Terrier",
      "40": "Bouvier Bernois",
      "400": "Pixiebob",
      "401": "Pixiebob Longhair",
      "402": "Ragamuffin",
      "403": "Ragdoll",
      "404": "Russian Blue",
      "405": "Savannah",
      "406": "Scottish Fold",
      "407": "Scottish Fold Longhair",
      "408": "Scottish Straight",
      "409": "Scottish Straight Longhair",
      "41": "Bouvier d'Appenzell",
      "410": "Selkirk Rex",
      "411": "Selkirk Rex Longhair",
      "412": "Seychellois",
      "413": "Seychellois Shorthair",
      "414": "Seychellois Longhair",
      "415": "Siamese",
      "416": "Siberian",
      "417": "Singapura",
      "418": "Snowshoe",
      "419": "Sokoke",
      "42": "Bouvier de l'Entlebuch",
      "420": "Somali",
      "421": "Sphynx",
      "422": "Thai",
      "423": "Tonkinese",
      "424": "Toyger",
      "425": "Turkish Angora",
      "426": "Turkish Van",
      "428": "Dachshund",
      "429": "Transmontano Mastiff",
      "43": "Chien d'ours de Carélie",
      "44": "Spitz finlandais",
      "45": "Terre-Neuve",
      "46": "Chien courant finlandais",
      "47": "Chien courant polonais",
      "48": "Komondor",
      "49": "Kuvasz",
      "5": "Cocker Spaniel Anglais",
      "50": "Puli",
      "51": "Pumi",
      "52": "Braque hongrois à poil court",
      "53": "Grand Bouvier suisse",
      "54": "Chien courant suisse",
      "55": "Petit chien courant suisse",
      "56": "Saint-Bernard",
      "57": "Chien courant de Styrie à poil dur",
      "58": "Chien courant noir et feu pour la chasse au raton laveur",
      "59": "Pinscher autrichien à poil court",
      "6": "Setter Gordon",
      "60": "Bichon Maltais",
      "61": "Griffon Fauve de Bretagne",
      "62": "Petit Basset Griffon Vendéen",
      "63": "Chien courant de Småland",
      "64": "Lakeland Terrier",
      "65": "Manchester Terrier",
      "66": "Norwich Terrier",
      "67": "Scottish Terrier",
      "68": "Sealyham Terrier",
      "69": "Skye Terrier",
      "7": "Airedale Terrier",
      "70": "Staffordshire Bull Terrier",
      "71": "Épagneul nain continental",
      "72": "Welsh Terrier",
      "73": "Griffon Bruxellois",
      "74": "Griffon Belge",
      "75": "Petit Brabançon",
      "76": "Schipperke",
      "77": "Chien de Saint-Hubert",
      "78": "West Highland White Terrier",
      "79": "Yorkshire Terrier",
      "8": "Terrier Australien",
      "80": "Berger Catalan",
      "81": "Berger des Shetland",
      "82": "Podenco Ibicenco",
      "83": "Perdigueiro de Burgos",
      "84": "Mastin Espagnol",
      "85": "Mastin des Pyrénées",
      "86": "Cão da Serra de Aires",
      "87": "Podengo Português",
      "88": "Épagneul Breton",
      "89": "Rafeiro do Alentejo",
      "9": "Bedlington Terrier",
      "90": "Spitz Allemand",
      "91": "Deutsch Drahthaar",
      "92": "Braque de Weimar",
      "93": "Dachsbracke de Westphalie",
      "94": "Bouledogue Français",
      "95": "Kleiner Münsterländer",
      "96": "Terrier de chasse allemand",
      "97": "Épagneul allemand",
      "98": "Chien d'eau français",
      "99": "Épagneul Bleu de Picardie"
    },
    "bugReports": {
      "bugReport": {
        "message": "Message",
        "subject": "Sujet",
        "title": "Signaler une erreur"
      },
      "title": "Signaler des erreurs"
    },
    "bundle": {
      "newBundle": "Nouveau Bundle"
    },
    "bundles": {
      "bundle": {
        "title": "Bundle"
      },
      "messages": {
        "createdSuccess": "Bundle créé avec succès"
      },
      "title": "Bundles"
    },
    "calendar": {
      "title": "Calendrier"
    },
    "campaigns": {
      "campaign": {
        "audience": "Audience",
        "audienceDetermined": "L'audience sont tous les patients avec des périodiques programmés entre",
        "audienceEveryone": "L'audience sont tous les clients",
        "description": "Notes",
        "ended": "Fin",
        "errors": {
          "periodicsAlreadyCommunicated": "Les périodiques sélectionnés ont déjà été communiqués"
        },
        "message": "Message",
        "name": "Nom",
        "sendDate": "Date d'envoi",
        "species": "Espèces",
        "started": "Début",
        "title": "Campagne"
      },
      "new": {
        "createdSuccess": "Campagne créée avec succès",
        "title": "Nouvelle campagne"
      },
      "sendConfirmation": "Êtes-vous sûr de vouloir envoyer cette campagne?",
      "title": "Campagnes"
    },
    "charges": {
      "title": "Facturations"
    },
    "chores": {
      "chore": {
        "title": "Tâche"
      },
      "title": "Tâches"
    },
    "communicationErrors": {
      "communicationError": {
        "appointmentDate": "Date",
        "campaign": "Campagne",
        "customer": "Client",
        "patient": "Patient",
        "phoneNumber": "Numéro",
        "started": "Date d'envoi",
        "text": "Texte",
        "title": "Erreur de communication"
      },
      "title": "Erreurs de communication"
    },
    "communications": {
      "communication": {},
      "comunication": {
        "title": "Communication"
      }
    },
    "companies": {
      "details": {
        "disabled": "Cette entité n'a pas de plan associé. Contactez-nous pour activer un forfait d'essai!"
      },
      "new": {
        "title": "Nouvelle Entreprise"
      }
    },
    "compoundProduct": {
      "newCompoundProduct": "Nouvel Article Composé",
      "title": "Article composé"
    },
    "compoundProducts": {
      "action": {
        "addPrice": "Cliquez ici pour attribuer un prix à l'article",
        "dontUsePrice": "Cliquez ici si vous ne souhaitez pas utiliser ce prix pour l'article"
      },
      "messages": {
        "createdSuccess": "Article composé créé avec succès"
      }
    },
    "configurations": {
      "breeds": {
        "dangerous": "Dangereux",
        "description": "Voir toutes les races",
        "name": "Nom",
        "new": "Nouvelle race",
        "proportion": "Proportions",
        "title": "Races"
      },
      "clinic": {
        "title": "Clinique",
        "visitTypes": {
          "description": "Voir tous les types de visite",
          "descriptionVisitTypes": "Type de visite",
          "name": "Nom",
          "new": "Nouveau type de visite",
          "parent": "Unité mère",
          "title": "Types de Visite"
        }
      },
      "configItems": {
        "acceptQuotation": "Accepter le devis",
        "acceptedQuotation": "Devis accepté",
        "enum": {
          "0": {
            "name": "Désinsectisation",
            "value": "0"
          },
          "1": {
            "name": "Surveillance",
            "value": "1"
          },
          "2": {
            "name": "Examens",
            "value": "2"
          },
          "3": {
            "name": "Traitements",
            "value": "3"
          },
          "4": {
            "name": "Vaccination",
            "value": "4"
          }
        },
        "generatePDF": "Générer PDF",
        "generatePayment": "Générer Paiement",
        "generateReceipt": "Générer reçu",
        "generatedPDF": "PDF Généré",
        "generatedReceipt": "Reçu généré",
        "goToPos": "Aller au POS",
        "rejectQuotation": "Rejeter le devis",
        "rejectedQuotation": "Devis rejeté",
        "transformIntoOrder": "Transformer en commande",
        "transformedIntoOrder": "Transformé en commande"
      },
      "general": {
        "title": "Général"
      },
      "item": {
        "brands": {
          "list": "Voir toutes les marques",
          "new": "Nouvelle marque",
          "title": "Marques"
        },
        "itemTypes": {
          "list": "Voir tous les types d'articles",
          "new": "Nouveau type d'article",
          "title": "Types d'Articles"
        },
        "models": {
          "list": "Voir tous les modèles",
          "new": "Nouveau modèle",
          "title": "Modèles"
        },
        "unitDimensions": {
          "list": "Voir toutes les dimensions d'unités",
          "new": "Nouvelle dimension d'unité",
          "title": "Dimensions d'Unités"
        },
        "units": {
          "list": "Voir toutes les unités",
          "new": "Nouvelle unité",
          "title": "Unités"
        }
      },
      "organization": {
        "advanced": "Avancées",
        "edit": "Éditer l'Organisation",
        "featureUnavailable": "Pour avoir accès, vous devez posséder le module {feature}",
        "name": "Nom de l'Organisation",
        "receiptSlip": "Reçu en reçu",
        "sender": "Nom de l'expéditeur",
        "slip": "Reçu en facture",
        "title": "Organisation"
      },
      "organizationCommunication": {
        "beforeSchedule": "Ces SMS seront envoyés automatiquement la veille du rendez-vous.",
        "consultationText": "Texte de rendez-vous de consultation",
        "consultationTextTitle": "Bonjour ! Nous vous rappelons que @@pa a un rendez-vous de consultation prévu le @@dd à @@hh. Merci de votre préférence.",
        "customerBirthdayText": "Texte d'anniversaire de client",
        "customerBirthdayTextTitle": "Joyeux anniversaire !",
        "daysBefore": "Jours avant",
        "externalDewormingText": "Texte de vermifugation externe",
        "externalDewormingTextTitle": "Bonjour ! Nous vous informons que @@pa doit effectuer la vermifugation externe ce mois-ci. Merci de votre préférence.",
        "internalDewormingText": "Texte de vermifugation interne",
        "internalDewormingTextTitle": "Bonjour ! Nous vous informons que @@pa doit effectuer la vermifugation interne ce mois-ci. Merci de votre préférence.",
        "othersText": "Texte pour d'autres",
        "othersTextTitle": "Bonjour ! Nous vous informons que @@pa a un périodique prévu ce mois-ci. Merci de votre préférence.",
        "patientBirthdayText": "Texte d'anniversaire de patient",
        "patientBirthdayTextTitle": "Joyeux anniversaire !",
        "proceduresAppointmentText": "Texte de rendez-vous de procédure",
        "proceduresAppointmentTextTitle": "Bonjour ! Nous vous informons que @@pa a un rendez-vous de procédure prévu le @@dd à @@hh. Merci de votre préférence.",
        "sendHour": "Heure d'envoi",
        "sendMinute": "Minute d'envoi",
        "surgeryAppointmentText": "Texte de rendez-vous de chirurgie",
        "surgeryAppointmentTextTitle": "Bonjour ! Nous vous rappelons que @@pa a une chirurgie prévue le @@dd à @@hh. N'oubliez pas le jeûne. Merci de votre préférence.",
        "title": "Communication de l'organisation",
        "vaccineAppointmentText": "Texte de rendez-vous de vaccination",
        "vaccineAppointmentTextTitle": "Bonjour ! Nous vous rappelons que @@pa a un rendez-vous de vaccination prévu le @@dd à @@hh. Merci de votre préférence.",
        "vaccineText": "Texte de vaccination",
        "vaccineTextTitle": "Bonjour ! Nous vous informons que @@pa doit être vacciné(e) ce mois-ci. Merci de votre préférence.",
        "wellBeingAppointmentText": "Texte de rendez-vous de bien-être",
        "wellBeingAppointmentTextTitle": "Bonjour ! Nous vous informons que @@pa a un rendez-vous de bien-être prévu le @@dd à @@hh. Merci de votre préférence."
      },
      "periodicTypes": {
        "description": "Voir tous les périodiques",
        "descriptionPeriodic": "Description",
        "name": "Nom",
        "new": "Nouveau type de périodique",
        "period": "Période",
        "reinforcementNumber": "Nombre de renforcements",
        "reinforcementPeriod": "Période de renforcement",
        "title": "Types de Périodiques"
      },
      "species": {
        "description": "Voir toutes les espèces",
        "name": "Nom",
        "new": "Nouvelle espèce",
        "title": "Espèces"
      },
      "title": "Configurations"
    },
    "consultations": {
      "consultation": {
        "bathCutReport": "Rapport de bain et de coupe",
        "bathCutRequest": "Demande de bain et de coupe",
        "buttons": {
          "backToConsultation": "Continuer la consultation",
          "confirmation": "Êtes-vous sûr de vouloir terminer la visite ?",
          "finishAndPay": "Terminer et payer",
          "finishWithoutPay": "Terminer sans payer",
          "finishedConsultation": "Terminer",
          "pausedConsultation": "Mettre la visite en pause"
        },
        "cantSave": "Vous devez entrer une date et une heure valides",
        "checkUpSeniorCat": "Rapport de contrôle pour chat senior",
        "checkUpSeniorDog": "Rapport de contrôle pour chien senior",
        "countTime": "Temps",
        "creator": "Créateur de la visite",
        "debtConsent": "Déclaration de reconnaissance de dette et plan de paiement",
        "declarationRGPD": "Consentement aux fins de RGPD",
        "dischargeLetter": "Lettre de sortie",
        "ecoReport": "Rapport échographique",
        "electrocardiogramCat": "Électrocardiogramme pour chat",
        "electrocardiogramDog": "Électrocardiogramme pour chien",
        "euthanasia": "Terme d'euthanasie",
        "generalAnesthesia": "Terme d'anesthésie générale",
        "hospitalizationConsent": "Consentement à l'hospitalisation",
        "internmentDischarge": "Sortie d'hospitalisation",
        "labAnalysisReport": "Rapport d'analyse de laboratoire",
        "responsabilityTermInternment": "Terme de responsabilité d'hospitalisation",
        "responsabilityTermSurgery": "Terme de responsabilité chirurgicale",
        "sanityCertificate": "Certificat de santé",
        "searchOptions": {
          "date": "Date"
        },
        "soap": {
          "anamnesis": "Anamnèse",
          "assessment": "Évaluation",
          "description": "Description du Plan de Traitement",
          "diagnosisDescription": "Description du Diagnostic",
          "diagnostic": "Diagnostic",
          "medication": "Médication",
          "objective": "Objectif",
          "observations": "Notes internes",
          "physicalTest": {
            "abdomen": "Abdomen",
            "appetite": "Appétit",
            "attitude": "Attitude",
            "bloodPressure": "P. Artérielle",
            "capillaryRepletionTime": "TRC",
            "digestiveSounds": "Bruits dig.",
            "digitalPulse": "Pouls digités",
            "edit": {
              "title": "Éditer Examen Physique"
            },
            "ganglia": "Ganglions",
            "heartRate": "Fréq. Card.",
            "hydration": "Hydratation",
            "jugularVeins": "Veines jug.",
            "mucous": "Muqueuses",
            "observation": "Observations de l'Examen Physique",
            "pain": "Douleur",
            "parameters": "Paramètres",
            "respiratoryFrequency": "Fréq. Resp.",
            "temperature": "Température",
            "title": "Examen Physique",
            "value": "Valeur"
          },
          "prescription": {
            "delivered": "En consultation",
            "prescribed": "Prescrire",
            "title": "Prescription"
          },
          "subjective": "Subjectif",
          "symptom": "Signes Cliniques",
          "symptomDescription": "Description du Signe Clinique",
          "title": "S.O.A.P.",
          "treatmentPlan": "Plan de Traitement"
        },
        "surgeryDischarge": "Sortie de chirurgie",
        "surgeryReport": "Rapport de chirurgie",
        "title": "Visite",
        "vet": "Vétérinaire",
        "visitDate": "Date de la consultation",
        "workerObservation": "Observations lors de la visite"
      },
      "messages": {
        "consultationFinished": "Visite terminée avec succès",
        "consultationSaved": "Visite enregistrée avec succès"
      },
      "title": "Visites"
    },
    "creator": {
      "title": "Créé par"
    },
    "crud": {
      "associate": "Associer",
      "cancel": "Annuler",
      "close": "Fermer",
      "create": "Créer",
      "createAndAdd": "Enregistrer et créer un nouveau",
      "createSuccess": "Enregistrement réussi pour",
      "created": "Créé",
      "delete": "Supprimer",
      "details": "Détails",
      "register": "Enregistrer",
      "registered": "Enregistré",
      "revert": "Revenir",
      "save": "Enregistrer",
      "saved": "Enregistré",
      "schedule": "Programmer",
      "update": "Éditer",
      "updated": "Mis à jour"
    },
    "current": {
      "amountOwed": "Montant dû",
      "title": "Compte courant"
    },
    "customerFieldTypes": {
      "customerFieldType": {
        "editable": "Éditable",
        "label": "Libellé",
        "mandatory": "Obligatoire",
        "title": "Propriété personnalisée",
        "type": "Type",
        "types": {
          "boolean": "Oui/Non",
          "date": "Date",
          "double": "Nombre à virgule",
          "integer": "Nombre entier",
          "string": "Texte"
        },
        "unique": "Unique"
      },
      "title": "Propriétés personnalisées"
    },
    "customers": {
      "actions": {
        "disableCustomer": "Désactiver le client"
      },
      "customer": {
        "accumulatedPoints": "Points Accumulés",
        "address": "Adresse",
        "addresses": "Adresses de Livraison",
        "avatar": "Avatar",
        "balcon": "Banque",
        "barcode": "Code-barres",
        "birthdate": "Date de Naissance",
        "building": "Bâtiment",
        "cc": "Carte d'Identité",
        "code": "Code",
        "comment": "Commentaires",
        "contacts": {
          "authorizations": "Autorisations",
          "contact": "Contact",
          "contactType": "Type de Contact",
          "contacts": "Contacts",
          "email": "Email",
          "mobilePhone": "Téléphone Portable",
          "new": "Nouveau Contact",
          "phone": "Téléphone",
          "preferential": "Préférentiel",
          "preferentialcontact": "Contact Préférentiel",
          "typesTranslation": {
            "1": "Newsletter",
            "2": "Périodiques",
            "3": "Promotions",
            "4": "Interdit"
          }
        },
        "country": "Pays",
        "customerName": "Nom du Client",
        "customerSince": "Client Depuis",
        "customerTitle": "Titre",
        "enable": "Autoriser",
        "errors": {
          "alreadyUsed": "Ce client a déjà été utilisé dans des documents et ne peut pas être modifié",
          "noNumber": "Ce client n'a pas de numéro de contact associé"
        },
        "female": "Féminin",
        "generalDiscount": "Remises Générales",
        "headerDocuments": "Documents",
        "iban": "IBAN",
        "irsRetention": "Rétention d'IR",
        "male": "Masculin",
        "name": "Nom",
        "newAddress": "Nouvelle Adresse",
        "nif": "NIF (Numéro d'Identification Fiscale)",
        "noAnimal": "Ce client n'a pas d'animaux associés",
        "origin": "Origine",
        "paymentTerm": "Délai de Paiement",
        "personalCharacteristics": "Caractéristiques Personnelles",
        "preferedPaymentMethod": "Méthode de Paiement Préférée",
        "price": "Prix",
        "profession": "Profession",
        "reason": "Raison",
        "referencedBy": "Recommandé par",
        "sex": "Sexe",
        "stickyNote": "Notes Adhésives",
        "street": "Rue",
        "tariff": "Tarif",
        "title": "Client",
        "town": "Ville",
        "unknown": "Inconnu",
        "usedPoints": "Points Utilisés",
        "validCC": "Validité de la Carte d'Identité",
        "visitDetails": "Détails de la Visite",
        "warningList": "Liste d'Avertissements",
        "zip": "Code Postal"
      },
      "disabled": "Inactif",
      "edit": {
        "title": "Éditer Client"
      },
      "enabled": "Actif",
      "form": {
        "allreadyExistsVat": "Ce NIF est déjà enregistré",
        "category": {
          "address": "Adresse",
          "complementarDetails": "Informations Complémentaires",
          "contacts": "Contacts",
          "financialDetails": "Détails Financiers",
          "personalInformation": "Informations Personnelles",
          "rpContacts": "Contacts Représentant"
        },
        "invalidContact": "Ce contact n'est pas valide",
        "invalidVat": "Un NIF doit comporter 9 caractères",
        "placeholder": {
          "addresses": "Adresses de Livraison",
          "bancon": "Banque",
          "building": "Bâtiment",
          "cc": "Carte d'Identité",
          "comment": "Commentaires",
          "contact": "Contact",
          "email": "Email",
          "generalDiscount": "Remises Générales",
          "iban": "IBAN",
          "mobilePhone": "Téléphone Mobile",
          "name": "Exemple : Pedro Silva",
          "nif": "NIF",
          "personalCharacteristics": "Caractéristiques Personnelles",
          "phone": "Téléphone",
          "profession": "Profession",
          "stickyNote": "Notes",
          "street": "Rue",
          "town": "Ville",
          "zip": "Code Postal"
        },
        "select": {
          "contact": "Sélectionnez le Type de Contact",
          "country": "Sélectionnez le Pays",
          "origin": "Sélectionnez l'Origine",
          "paymentTerm": "Délai de Paiement",
          "price": "Sélectionnez le Prix",
          "reason": "Sélectionnez la Raison",
          "referencedBy": "Choisir",
          "removeReferencedBy": "Supprimer la recommandation"
        },
        "vatAnotherCountry": "Ce NIF n'est pas valide"
      },
      "inactiveCustomers": "Clients inactifs",
      "merge": {
        "crud": "Fusionner",
        "messages": {
          "confirm": "Êtes-vous sûr de vouloir fusionner ces clients ?",
          "error": "Une erreur est survenue lors de la fusion des clients",
          "errorSame": "Les clients sont les mêmes",
          "info": "Cette action ne peut pas être annulée !",
          "success": "Clients fusionnés avec succès"
        },
        "title": "Fusionner Clients"
      },
      "messages": {
        "createdSuccess": "Client créé avec succès",
        "disable": "Êtes-vous sûr de vouloir désactiver ce client?",
        "hasDebt": "Ce client a des dettes, souhaitez-vous continuer?"
      },
      "new": {
        "saveAndAssociatePatient": "Créer et associer un patient",
        "title": "Nouveau Client"
      },
      "title": "Clients"
    },
    "dayWeek": {
      "0": "Dimanche",
      "1": "Lundi",
      "2": "Mardi",
      "3": "Mercredi",
      "4": "Jeudi",
      "5": "Vendredi",
      "6": "Samedi"
    },
    "declarations": {
      "prescription": "Prescription"
    },
    "documents": {
      "accountingAddress": "Adresse de Facturation",
      "accountingName": "Nom de Facturation",
      "accountingTaxId": "NIF (Numéro d'Identification Fiscale)",
      "annul": "Annuler",
      "annulDocument": "Annuler le Document",
      "annulReason": "Raison de l'Annulation",
      "date": "Date de Paiement",
      "document": {
        "title": "Document"
      },
      "edit": {
        "title": "Modifier le Document"
      },
      "financialAccount": "Compte Financier",
      "internalNotes": "Notes Internes",
      "invoiceNotes": "Notes de Facture",
      "payableAmount": "Montant à Payer",
      "paymentMethod": "Méthode de Paiement",
      "quotationNotes": "Notes de Devis",
      "reference": "Référence",
      "title": "Documents",
      "warning": "Êtes-vous sûr de vouloir annuler l'enregistrement du document?"
    },
    "drugs": {
      "drug": {
        "concentration": "Concentration",
        "delete": "Supprimer le Médicament",
        "description": "Description",
        "dosageTotal": "Dose Totale",
        "edit": "Modifier le Médicament",
        "name": "Nom",
        "pills": "Comprimés",
        "quantity": "Quantité",
        "title": "Médicament",
        "via": "Voie",
        "view": "Voir les Détails du Médicament",
        "volumeTotal": "Volume Total"
      },
      "edit": {
        "addMoreTypes": "Vous ne trouvez pas le type dont vous avez besoin ? Envoyez un e-mail à",
        "nameNotNull": "Un médicament doit avoir un type",
        "selectDrugType": "Sélectionner le Type de Médicament",
        "title": "Modifier le Médicament"
      },
      "messages": {
        "createdSuccess": "Médicament créé avec succès"
      },
      "new": {
        "addMoreTypes": "Vous ne trouvez pas le type dont vous avez besoin ? Envoyez un e-mail à",
        "nameNotNull": "Un médicament doit avoir un type",
        "selectDrugType": "Sélectionner le Type de Médicament",
        "title": "Nouveau Médicament"
      },
      "title": "Médicaments"
    },
    "emailValidations": {
      "title": "Validation de l'E-mail",
      "validated": "Votre e-mail a été validé avec succès, vous pouvez maintenant vous connecter"
    },
    "enterprises": {
      "edit": {
        "title": "Modifier l'Expéditeur"
      },
      "messages": {
        "title": "Expéditeur créé avec succès"
      },
      "new": {
        "title": "Nouvel Expéditeur"
      },
      "title": "Expéditeur"
    },
    "enums": {
      "chronos": {
        "date": "date",
        "dateUpper": "Date",
        "day": "jour",
        "dayUpper": "Jour",
        "days": "jours",
        "hour": "heure",
        "hourUpper": "Heure",
        "hours": "heures",
        "hoursUpper": "Heures",
        "month": "mois",
        "monthUpper": "Mois",
        "months": "mois",
        "today": "aujourd'hui",
        "todayUpper": "Aujourd'hui",
        "waitingTime": "temps d'attente",
        "waitingTimeUpper": "Temps d'attente",
        "week": "semaine",
        "weekUpper": "Semaine",
        "year": "an",
        "yearUpper": "An",
        "years": "ans"
      },
      "countries": {
        "AD": "Andorra",
        "AE": "Emiratos Árabes Unidos",
        "AF": "Afeganistão",
        "AG": "Antígua e Barbuda",
        "AI": "Anguila",
        "AL": "Albânia",
        "AM": "Arménia",
        "AN": "Antilhas Holandesas",
        "AO": "Angola",
        "AQ": "Antárctica",
        "AR": "Argentina",
        "AS": "Samoa Americana",
        "AT": "Áustria",
        "AU": "Austrália",
        "AW": "Aruba",
        "AZ": "Azerbaijão",
        "BA": "Bósnia e Herzegovina",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Bélgica",
        "BF": "Burkina Faso",
        "BG": "Bulgária",
        "BH": "Barém",
        "BI": "Burundi",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BN": "Brunei Darussalam",
        "BO": "Bolívia",
        "BR": "Brasil",
        "BS": "Bahamas",
        "BT": "Butão",
        "BV": "Ilhas Bouvet",
        "BW": "Botswana",
        "BY": "Bielorrússia",
        "BZ": "Belize",
        "CA": "Canadá",
        "CC": "Ilhas Cocos (Keeling)",
        "CD": "Congo (República Democrático do)",
        "CF": "Centro-Africana (República)",
        "CG": "Congo",
        "CH": "Suíça",
        "CI": "Costa do Marfim",
        "CK": "Ilhas Cook",
        "CL": "Chile",
        "CM": "Camarões",
        "CN": "China",
        "CO": "Colômbia",
        "CR": "Costa Rica",
        "CU": "Cuba",
        "CV": "Cabo Verde",
        "CX": "Ilhas Christmas",
        "CY": "Chipre",
        "CZ": "República Checa",
        "DE": "Alemanha",
        "DJ": "Jibuti",
        "DK": "Dinamarca",
        "DM": "Domínica",
        "DO": "República Dominicana",
        "DZ": "Argélia",
        "EC": "Equador",
        "EE": "Estónia",
        "EG": "Egipto",
        "EH": "Sara Ocidental",
        "ER": "Eritreia",
        "ES": "Espanha",
        "ET": "Etiópia",
        "FI": "Finlândia",
        "FJ": "Ilhas Fiji",
        "FK": "Ilhas Falkland (Malvinas)",
        "FM": "Micronésia (Estados Federados da)",
        "FO": "Ilhas Faroé",
        "FR": "França",
        "GA": "Gabão",
        "GB": "Reino Unido",
        "GD": "Granada",
        "GE": "Geórgia",
        "GF": "Guiana Francesa",
        "GH": "Gana",
        "GI": "Gibraltar",
        "GL": "Gronelândia",
        "GM": "Gâmbia",
        "GN": "Guiné",
        "GP": "Guadalupe",
        "GQ": "Guiné Equatorial",
        "GR": "Grécia",
        "GS": "Geórgia do Sul e Ilhas Sandwich",
        "GT": "Guatemala",
        "GU": "Guam",
        "GW": "Guiné-Bissau",
        "GY": "Guiana",
        "HK": "Hong Kong",
        "HM": "Ilhas Heard e Ilhas McDonald",
        "HN": "Honduras",
        "HR": "Croácia",
        "HT": "Haiti",
        "HU": "Hungria",
        "ID": "Indonésia",
        "IE": "Irlanda",
        "IL": "Israel",
        "IN": "Índia",
        "IO": "Território Britânico do Oceano Índico",
        "IQ": "Iraque",
        "IR": "Irão (República Islâmica)",
        "IS": "Islândia",
        "IT": "Itália",
        "JM": "Jamaica",
        "JO": "Jordânia",
        "JP": "Japão",
        "KE": "Kenya",
        "KG": "Quirguizistão",
        "KH": "Camboja",
        "KI": "Kiribati",
        "KM": "Comores",
        "KN": "São Cristóvão e Nevis",
        "KP": "Coreia (República Popular Democrática da)",
        "KR": "Coreia (República da)",
        "KW": "Kuwait",
        "KY": "Ilhas Caimão",
        "KZ": "Cazaquistão",
        "LA": "Laos (República Popular Democrática do)",
        "LB": "Líbano",
        "LC": "Santa Lúcia",
        "LI": "Liechtenstein",
        "LK": "Sri Lanka",
        "LR": "Libéria",
        "LS": "Lesoto",
        "LT": "Lituânia",
        "LU": "Luxemburgo",
        "LV": "Letónia",
        "LY": "Líbia (Jamahiriya Árabe da)",
        "MA": "Marrocos",
        "MC": "Mónaco",
        "MD": "Moldova (República de)",
        "MG": "Madagáscar",
        "MH": "Ilhas Marshall",
        "MK": "Macedónia (Antiga República Jugoslava da)",
        "ML": "Mali",
        "MM": "Myanmar",
        "MN": "Mongólia",
        "MO": "Macau",
        "MP": "Ilhas Marianas do Norte",
        "MQ": "Martinica",
        "MR": "Mauritânia",
        "MS": "Monserrate",
        "MT": "Malta",
        "MU": "Maurícias",
        "MV": "Maldivas",
        "MW": "Malawi",
        "MX": "México",
        "MY": "Malásia",
        "MZ": "Moçambique",
        "NA": "Namíbia",
        "NC": "Nova Caledónia",
        "NE": "Niger",
        "NF": "Ilhas Norfolk",
        "NG": "Nigéria",
        "NI": "Nicarágua",
        "NL": "Países Baixos",
        "NO": "Noruega",
        "NP": "Nepal",
        "NR": "Nauru",
        "NU": "Niue",
        "NZ": "Nova Zelândia",
        "OM": "Omã",
        "PA": "Panamá",
        "PE": "Peru",
        "PF": "Polinésia Francesa",
        "PG": "Papuásia-Nova Guiné",
        "PH": "Filipinas",
        "PK": "Paquistão",
        "PL": "Polónia",
        "PM": "São Pedro e Miquelon",
        "PN": "Pitcairn",
        "PR": "Porto Rico",
        "PS": "Território Palestiniano Ocupado",
        "PT": "Portugal",
        "PW": "Palau",
        "PY": "Paraguai",
        "QA": "Catar",
        "RE": "Reunião",
        "RO": "Roménia",
        "RU": "Rússia (Federação da)",
        "RW": "Ruanda",
        "SA": "Arábia Saudita",
        "SB": "Ilhas Salomão",
        "SC": "Seychelles",
        "SD": "Sudão",
        "SE": "Suécia",
        "SG": "Singapura",
        "SH": "Santa Helena",
        "SI": "Eslovénia",
        "SJ": "Svålbard e a Ilha de Jan Mayen",
        "SK": "Eslovaca (República)",
        "SL": "Serra Leoa",
        "SM": "São Marino",
        "SN": "Senegal",
        "SO": "Somália",
        "SR": "Suriname",
        "ST": "São Tomé e Príncipe",
        "SV": "El Salvador",
        "SY": "Síria (República Árabe da)",
        "SZ": "Suazilândia",
        "TC": "Turcos e Caicos (Ilhas)",
        "TD": "Chade",
        "TF": "Territórios Franceses do Sul",
        "TG": "Togo",
        "TH": "Tailândia",
        "TJ": "Tajiquistão",
        "TK": "Tokelau",
        "TM": "Turquemenistão",
        "TN": "Tunísia",
        "TO": "Tonga",
        "TP": "Timor Leste",
        "TR": "Turquia",
        "TT": "Trindade e Tobago",
        "TV": "Tuvalu",
        "TW": "Taiwan (Província da China)",
        "TZ": "Tanzânia, República Unida da",
        "UA": "Ucrânia",
        "UG": "Uganda",
        "UM": "Ilhas Menores Distantes dos Estados Unidos",
        "US": "Estados Unidos",
        "UY": "Uruguai",
        "UZ": "Usbequistão",
        "VA": "Santa Sé (Cidade Estado do Vaticano)",
        "VC": "São Vicente e Granadinas",
        "VE": "Venezuela",
        "VG": "Ilhas Virgens (britânicas)",
        "VI": "Ilhas Virgens (Estados Unidos)",
        "VN": "Vietname",
        "VU": "Vanuatu",
        "WF": "Wallis e Futuna (Ilha)",
        "WS": "Samoa",
        "YE": "Iémen",
        "YT": "Mayotte",
        "YU": "Jugoslávia",
        "ZA": "África do Sul",
        "ZM": "Zâmbia",
        "ZW": "Zimbabwe"
      },
      "currencies": {
        "$": "Dollar",
        "EUR": "Euro",
        "GBP": "Libra",
        "R$": "Real"
      },
      "mobileCountries": {
        "ES": "Espanha +354",
        "PT": "Portugal +351"
      },
      "sex": {
        "female": {
          "initial": "F",
          "name": "Féminin"
        },
        "male": {
          "initial": "M",
          "name": "Masculin"
        }
      }
    },
    "equipmentParameterTypes": {
      "code": "Code",
      "description": "Description",
      "new": {
        "title": "Nouveaux Types de Paramètres d'Équipement"
      },
      "title": "Types de Paramètres d'Équipement"
    },
    "equipmentParameters": {
      "maxVal": "Valeur maximale",
      "minVal": "Valeur minimale",
      "new": {
        "title": "Nouveau Paramètre"
      },
      "title": "Paramètres",
      "type": "Types de paramètres"
    },
    "equipmentTypes": {
      "code": "Code",
      "description": "Description",
      "new": {
        "title": "Nouveau Type d'Équipement"
      },
      "title": "Types d'Équipement"
    },
    "equipments": {
      "new": {
        "title": "Nouvel Équipement"
      },
      "title": "Équipement",
      "type": "Type d'équipement"
    },
    "error": {
      "adapter": {
        "forbidden": "Vous n'avez pas les autorisations nécessaires pour effectuer cette action"
      },
      "noInternet": "Impossible de trouver une connexion Internet",
      "noPermission": "Vous n'avez pas la permission d'accéder à cette page!",
      "systemDown": "Le système est hors service",
      "unexistingBreed": "Vous devez sélectionner une race dans la liste"
    },
    "events": {
      "edit": {
        "title": "Éditer cas"
      },
      "messages": {
        "title": "Cas créé avec succès"
      },
      "new": {
        "title": "Nouveaux cas"
      },
      "title": "Événements"
    },
    "exams": {
      "all": "Tous",
      "delected": "Supprimer",
      "doneDate": "Date de réalisé",
      "edit": {
        "title": "Modifier l'Examen"
      },
      "exam": {
        "description": "Description",
        "externalLab": "Laboratoire Externe",
        "name": "Nom",
        "notes": "Résultat",
        "started": "Date",
        "title": "Examen",
        "view": "Voir l'Examen"
      },
      "examTypes": {
        "exam_abdominocentesis": "Abdominocentèse",
        "exam_bio": "Biologie",
        "exam_biopsy": "Biopsie",
        "exam_bloodCount": "Numération Sanguine",
        "exam_cat": "TDM",
        "exam_cystocentesis": "Cystocentèse",
        "exam_echocardiogram": "Échocardiogramme",
        "exam_fnac": "FNSA",
        "exam_ionogram": "Ionogramme",
        "exam_other": "Autre",
        "exam_pleuralFluidAnalysis": "Analyse du Liquide Pleural",
        "exam_smear": "Frottis",
        "exam_ultrasound": "Échographie",
        "exam_urethralCatheterization": "Cathétérisme Urethral",
        "exam_xRay": "Radiographie",
        "title": "Type"
      },
      "held": "Réalisé le",
      "messages": {
        "createdSuccess": "Examen créé avec succès"
      },
      "new": {
        "addMoreTypes": "Vous ne trouvez pas le type d'examen que vous souhaitez ? Envoyez un email à",
        "nameNotNull": "L'examen doit avoir un type",
        "selectExamType": "Sélectionner le Type d'Examen",
        "title": "Nouvel Examen"
      },
      "observations": "Observations",
      "parameters": {
        "exam_bio_alb": "Albumine",
        "exam_bio_alp": "Phosphatase Alcaline",
        "exam_bio_amyl": "Amylase",
        "exam_bio_bun": "Urée",
        "exam_bio_ca": "Calcium",
        "exam_bio_cl": "Chlore",
        "exam_bio_cpk": "Créatine Kinase",
        "exam_bio_cre": "Créatinine",
        "exam_bio_ggt": "Gamma-GT",
        "exam_bio_glu": "Glucose",
        "exam_bio_k": "Potassium",
        "exam_bio_lap": "LAP",
        "exam_bio_ldh": "Lactate Déshydrogénase",
        "exam_bio_lip": "Lipase",
        "exam_bio_mg": "Magnésium",
        "exam_bio_na": "Sodium",
        "exam_bio_nh3": "Ammoniac",
        "exam_bio_tbil": "Bilirubine Totale",
        "exam_bio_tcho": "Cholestérol Total",
        "exam_bio_tg": "Triglycérides",
        "exam_bio_tp": "Protéines Totales",
        "exam_bio_ua": "Acide Urique",
        "title": "Paramètres"
      },
      "putResults": "Rentrer les Résultats",
      "requestDate": "Date de Demande",
      "results": "Écrire les Résultats",
      "status": {
        "done": "Réalisé",
        "pending": "En Attente",
        "title": "Statut"
      },
      "title": "Examens"
    },
    "families": {
      "family": {
        "children": "Sous-Unités",
        "familyType": "Type de Famille",
        "name": "Nom",
        "parent": "Unité Mère",
        "title": "Famille"
      },
      "form": {
        "placeholder": {
          "children": "Sous-Unités",
          "familyType": "Type de Famille",
          "name": "Nom",
          "parent": "Unité Mère"
        }
      },
      "title": "Familles"
    },
    "files": {
      "title": "Fichiers"
    },
    "filters": {
      "all": "Tous",
      "bundles": "Bundles",
      "byFamily": "Par famille",
      "byUser": "Par utilisateur",
      "cashTotal": "Total en espèces",
      "checkTotal": "Chèques",
      "compoundProducts": "Produits Composés",
      "creditTotal": "Total crédit",
      "creditors": "Créanciers",
      "debtors": "Débiteurs",
      "deliveries": "Livraisons",
      "directDebitTotal": "Total de prélèvements automatiques",
      "invoices": "Factures",
      "invoicesTotal": "Total des factures",
      "memos": "Mémos",
      "memosTotal": "Total des mémos",
      "none": "Aucun",
      "orders": "Commandes",
      "payments": "Paiements",
      "pending": "En attente",
      "quotations": "Devis",
      "receipts": "Reçus",
      "requests": "Demandes",
      "total": "Total",
      "totalFA": "Total factures",
      "totalFR": "Total reçus de factures",
      "totalFS": "Total des factures simplifiées",
      "totalPayment": "Total paiement",
      "totalReceipt": "Total reçu",
      "transferTotal": "Total des transferts"
    },
    "financialAccounts": {
      "actions": {
        "close": "Fermer",
        "open": "Ouvrir"
      },
      "automaticManagment": "Il n'y a pas de comptes financiers en gestion manuelle",
      "bankAccount": "Compte bancaire",
      "choose": "Choisissez un compte financier",
      "close": "Fermer",
      "description": "Voir tous les comptes financiers",
      "edit": {
        "title": "Modifier le Compte Financier"
      },
      "financialAccount": {
        "accountNumber": "Numéro de compte",
        "billed": "Facturé",
        "currency": "Devise",
        "description": "Description",
        "financialAccountFund": "Fonds du Compte Financier",
        "history": "Historique",
        "iban": "IBAN",
        "institution": "Institution bancaire",
        "isManual": "Gestion Manuelle",
        "name": "Nom",
        "received": "Reçu",
        "swift": "SWIFT",
        "title": "Compte Financier",
        "total": "Total des mouvements",
        "totals": "Totals",
        "type": "Type"
      },
      "messages": {
        "allreadyClosed": "Ce compte financier est déjà fermé",
        "allreadyOpen": "Ce compte financier est déjà ouvert",
        "createdSuccess": "Compte financier créé avec succès",
        "editedSuccess": "Compte financier modifié avec succès",
        "noFinancialAccounts": "Aucun compte financier disponible",
        "noFinancialAccountsTypeBankAccount": "Aucun compte bancaire créé",
        "noFinancialAccountsTypeRegister": "Aucun compte de caisse créé"
      },
      "new": {
        "title": "Nouveau Compte Financier"
      },
      "purpose": "Vous pouvez ouvrir et fermer des comptes financiers ici",
      "register": "Caisse",
      "seeHistory": "Pour consulter l'historique, accédez au module de statistiques",
      "title": "Comptes financiers"
    },
    "footer": {
      "rights": "Tous droits réservés",
      "toggle": "Basculer la vue"
    },
    "form": {
      "administrative": "Administration",
      "chooseOne": "-- choisir un --",
      "extra": "Supplémentaire",
      "general": "Général",
      "health": "Santé",
      "look": "Apparence",
      "required": "Ce champ est obligatoire"
    },
    "getChrome": {
      "message": "Cette application fonctionne uniquement avec Google Chrome."
    },
    "header": {
      "logout": "Déconnexion"
    },
    "help": {
      "faq": {
        "last-rev-date": "29 juin 2016",
        "link": "FAQ",
        "title": "Questions fréquemment posées"
      },
      "manual": {
        "last-rev-date": "23 juin 2016",
        "link": "Manuel",
        "title": "Manuel"
      },
      "title": "Aide"
    },
    "howTo": {
      "steps": {
        "createCampaign": "Créez une campagne",
        "goToPeriodics": "Accédez au menu des périodiques",
        "makeAvailable": "Contactez-nous pour activer ce système",
        "startSendingMessages": "Les SMS seront envoyés automatiquement à la date choisie"
      },
      "text": "Nous avons maintenant une fonctionnalité d'envoi de SMS",
      "title": "Voir Plus"
    },
    "icons": {
      "absence": "hand-o-down",
      "absences": "hand-o-down",
      "action": "check-square-o",
      "actions": "check-square-o",
      "alert": "exclamation-triangle",
      "annul": "ban",
      "appointment": "calendar-o",
      "appointmentDone": "calendar-check-o",
      "appointmentNew": "calendar-plus-o",
      "appointments": "calendar-o",
      "at": "usd",
      "avatar": "image",
      "avatars": "image",
      "boxes": "home",
      "calendar": "calendar-o",
      "calendarMenu": "hourglass-start",
      "calendarPlus": "calendar-plus",
      "campaign": "bullhorn",
      "campaigns": "bullhorn",
      "caretDown": "caret-down",
      "caretUp": "caret-up",
      "charge": "credit-card",
      "charges": "credit-card",
      "check": "check",
      "checkout": "sign-out",
      "clinical": "stethoscope",
      "clinicalMenu": "stethoscope",
      "comment": "comment-o",
      "compoundProduct": "tags",
      "configs": "cog",
      "consultation": "medkit",
      "consultations": "medkit",
      "crm": "address-book-o",
      "currency": "€",
      "currentAccount": "folder-o",
      "customer": "user",
      "customers": "user",
      "delete": "trash",
      "deleteO": "trash-o",
      "detail": "eye",
      "disable": "toggle-off",
      "dischargeLetter": "envelope-square",
      "document": "file-text-o",
      "documentDetails": "file-pdf-o",
      "documents": "folder-o",
      "download": "download",
      "edit": "pencil",
      "email": "envelope-o",
      "enable": "toggle-on",
      "error": "warning",
      "events": "th-large",
      "exam": "eyedropper",
      "examion": "eyedropper",
      "exams": "eyedropper",
      "eye": "eye",
      "female": "venus",
      "file": "file-o",
      "files": "files-o",
      "filter": "filter",
      "financialAccounts": "inbox",
      "financialAccountsClosed": "stop-circle-o",
      "financialAccountsOpen": "play-circle-o",
      "flag": "flag",
      "genderless": "genderless",
      "generatePayment": "file-text",
      "generateReceipt": "file-text-o",
      "giveNext": "retweet",
      "goTo": "arrow-right",
      "group": "sitemap",
      "hermaphrodite": "intersex",
      "hotel": "hotel",
      "houseCalls": "home",
      "hr": "id-badge",
      "internment": "h-square",
      "internments": "h-square",
      "inventory": "archive",
      "invites": "user-plus",
      "invoice": "file-o",
      "item": "tag",
      "itemType": "star-o",
      "laboratories": "flask",
      "laboratory": "flask",
      "liaisons": "hand-o-right",
      "listings": "list-ol",
      "load": "spinner fa-spin",
      "male": "mars",
      "manufacturers": "institution",
      "mobilePhone": "mobile",
      "moveStock": "arrows-v",
      "neutral": "neuter",
      "notes": "sticky-note",
      "order": "shopping-cart",
      "organization": "hospital-o",
      "patient": "paw",
      "patients": "paw",
      "pencilSquare": "pencil",
      "periodic": "history",
      "periodics": "history",
      "phone": "phone",
      "phonecall": "phone",
      "plusCircle": "plus-circle",
      "pointOfSales": "money",
      "prescription": "file-text-o",
      "processing": "hourglass-start",
      "procurement": "handshake-o",
      "purchaseInvoice": "file",
      "purchaseInvoices": "file",
      "purchaseOrder": "cart-arrow-down",
      "purchaseOrders": "cart-arrow-down",
      "purchaseRequest": "cart-plus",
      "purchaseRequests": "cart-plus",
      "purchases": "file",
      "referral": "exchange",
      "referrals": "exchange",
      "reject": "times",
      "remove": "times",
      "reward": "certificate",
      "rewards": "certificate",
      "room": "map-marker",
      "rooms": "map-marker",
      "saft": "file-zip-o",
      "sales": "shopping-basket",
      "salesMenu": "shopping-basket",
      "save": "floppy-o",
      "search": "search",
      "secondTimeEmission": "file-pdf-o",
      "send": "paper-plane",
      "sendCampaign": "mail-forward",
      "sheltereds": "paw",
      "shift": "hand-o-up",
      "shifts": "hand-o-up",
      "shoppingCart": "shopping-cart",
      "simpleView": "tv",
      "sms": "comments",
      "stats": "line-chart",
      "stays": "ticket",
      "study": "x-ray",
      "supplier": "industry",
      "supplierItem": "truck",
      "supplierItems": "truck",
      "suppliers": "industry",
      "task": "tasks",
      "tasks": "tasks",
      "taxes": "bank",
      "template": "file-word-o",
      "templates": "file-word-o",
      "thumbsUp": "thumbs-up",
      "timesCircle": "times-circle",
      "transferStock": "exchange",
      "undetermined": "question",
      "veterinary": "paw",
      "view": "columns",
      "visit": "suitcase",
      "waitingRoom": "sign-in",
      "warehouse": "cubes",
      "warehouses": "cubes",
      "weight": "balance-scale",
      "worker": "user-md",
      "workers": "users"
    },
    "inbounds": {
      "customer": "Client",
      "facebook": "Facebook",
      "geolocation": "Géolocalisation",
      "partner": "Partenaire",
      "publicity": "Publicité",
      "reference": "Référence",
      "unknown": "Inconnu",
      "website": "Site web"
    },
    "index": {
      "addInternments": {
        "description": "Créez ou recherchez un client, remplissez les informations de l'animal et interné.",
        "title": "Internements"
      },
      "associateCollaborators": {
        "description": "Associez des collaborateurs avec différents rôles.",
        "title": "Collaborateurs"
      },
      "comments": "Pour toute question ou commentaire, veuillez nous contacter",
      "createAccount": {
        "description": "Remplissez vos informations et créez votre compte.",
        "title": "Créer un compte"
      },
      "createHospital": {
        "description": "Ajoutez les informations de l'hôpital et créez-le.",
        "title": "Enregistrer un hôpital"
      },
      "welcome.subtitle": "par {corp}"
    },
    "initials": {
      "catheterLeftForelimb": {
        "description": "Membre antérieur gauche",
        "name": "MEG"
      },
      "catheterLeftHindlimb": {
        "description": "Membre postérieur gauche",
        "name": "MPG"
      },
      "catheterRightForelimb": {
        "description": "Membre antérieur droit",
        "name": "MED"
      },
      "catheterRightHindlimb": {
        "description": "Membre postérieur droit",
        "name": "MPD"
      },
      "didntEat": {
        "description": "N'a pas mangé",
        "name": "NA"
      },
      "faecesDigestedBlood": {
        "description": "Fèces sang digéré",
        "name": "FSD"
      },
      "faecesHard": {
        "description": "Fèces dures",
        "name": "FD"
      },
      "faecesHardWithBlood": {
        "description": "Fèces dures avec du sang",
        "name": "FDS"
      },
      "faecesLiquid": {
        "description": "Fèces liquides",
        "name": "FL"
      },
      "faecesLiquidWithBlood": {
        "description": "Fèces liquides avec du sang",
        "name": "FLA"
      },
      "faecesLivingBlood": {
        "description": "Fèces sang vivant",
        "name": "FSV"
      },
      "faecesNormal": {
        "description": "Fèces normales",
        "name": "FN"
      },
      "faecesSoft": {
        "description": "Fèces molles",
        "name": "FM"
      },
      "faecesSoftMoulded": {
        "description": "Fèces molles moulées",
        "name": "FMM"
      },
      "fasting": {
        "description": "Jeûne",
        "name": "JC"
      },
      "feedingForced": {
        "description": "Alimentation forcée",
        "name": "AF"
      },
      "feedingNormal": {
        "description": "Alimentation normale",
        "name": "AN"
      },
      "foodForced": {
        "description": "Alimentation forcée",
        "name": "AF"
      },
      "foodNormal": {
        "description": "Alimentation normale",
        "name": "AN"
      },
      "foodTube": {
        "description": "Alimentation par tube",
        "name": "AT"
      },
      "hematuria": {
        "description": "Hématurie",
        "name": "SH"
      },
      "noFaeces": {
        "description": "Pas de fèces",
        "name": "SF"
      },
      "noUrine": {
        "description": "Pas d'urine",
        "name": "SU"
      },
      "urineConcentrated": {
        "description": "Urine concentrée",
        "name": "UC"
      },
      "urineConcentratedByExpression": {
        "description": "Urine concentrée par expression",
        "name": "UCE"
      },
      "urineJaundiced": {
        "description": "Urine ictérique",
        "name": "UI"
      },
      "urineNormal": {
        "description": "Urine normale",
        "name": "NU"
      },
      "urineNormalByExpression": {
        "description": "Urine normale par expression",
        "name": "UNE"
      },
      "vomitBile": {
        "description": "Vomissement biliaire",
        "name": "VB"
      },
      "vomitFoamy": {
        "description": "Vomissement mousseux",
        "name": "VE"
      },
      "vomitFood": {
        "description": "Vomissement alimentaire",
        "name": "AV"
      },
      "vomitRegurgitation": {
        "description": "Vomissement avec régurgitation",
        "name": "RV"
      },
      "vomitWithBlood": {
        "description": "Vomissement avec du sang",
        "name": "SV"
      }
    },
    "internmentTypes": {
      "hospitalization": {
        "title": "Hospitalisation"
      },
      "hotel": {
        "name": "Hôtel",
        "namePlural": "Hôtels"
      },
      "infected": {
        "name": "Infectieux",
        "namePlural": "Infectieux"
      },
      "intensiveCare": {
        "name": "Soins intensifs",
        "namePlural": "Soins intensifs"
      },
      "normal": {
        "name": "Normal",
        "namePlural": "Normaux"
      },
      "outpatient": {
        "name": "Ambulatoire",
        "namePlural": "Ambulatoires"
      },
      "reference": {
        "name": "Référence",
        "namePlural": "Références"
      },
      "surgery": {
        "name": "Chirurgie",
        "namePlural": "Chirurgies"
      }
    },
    "internments": {
      "actions": {
        "action": {
          "closeCart": "Fermer le panier",
          "create": {
            "doneBy": "Effectué par",
            "doubleClick": "Vous pouvez compléter les actions en double-cliquant simplement dessus.",
            "errorCreating": "Impossible d'enregistrer vos modifications pour le moment. Veuillez réessayer plus tard.",
            "lastAction": "Dernière action",
            "noNotes": "Pas de notes",
            "saveNotes": "Enregistrer les notes",
            "updateScheduling": "Mettre à jour la planification",
            "uploadImage": "Télécharger une image",
            "weightChangedDrug": "Les valeurs de ce médicament peuvent être obsolètes car le poids de l'animal a été modifié.",
            "weightChangedSerum": "Les valeurs de ce sérum peuvent être obsolètes car le poids de l'animal a été modifié."
          },
          "details": {
            "gallery": "Galerie",
            "started": "Date de l'action"
          },
          "initials": "Initiales",
          "notes": "Notes",
          "started": "Début",
          "status": {
            "done": "Effectuée",
            "removed": "Supprimée",
            "reverted": "Revertie"
          },
          "title": "Action"
        },
        "shoppingCart": "Actions du panier",
        "title": "Actions"
      },
      "clinicAnimal": {
        "admission": "Admission",
        "entity": "Patient",
        "entityPlural": "Patients",
        "location": "Emplacement",
        "pmsID": "Identifiant PMS",
        "status": "Statut",
        "temperament": "Tempérament"
      },
      "clinicPerson": {
        "pmsID": "Numéro de client",
        "role": "Rôle"
      },
      "clinicalEpisode": {
        "created": "Créé",
        "date": "Date",
        "description": "Description",
        "entity": "Épisode clinique",
        "entityPlural": "Historique clinique",
        "name": "Nom",
        "type": "Type",
        "updated": "Mis à jour"
      },
      "details": {
        "allEvents": "Tous les événements",
        "allTasks": "Toutes les tâches",
        "cantReopen": "Cet hospitalisation ne peut pas être réouvert. Veuillez nous contacter pour plus d'informations.",
        "closed": "Fermé",
        "columnsCount": "Nb de colonnes",
        "completedActions": "Actions réalisées",
        "currentDay": "Jour actuel",
        "dailyConsumptions": "Les consommations affichées ici sont uniquement pour ce jour d'hospitalisation",
        "dayTasks": "Tâches du jour",
        "dischargeBy": "Sortie programmée par",
        "doesntDefecate": "Ne défèque pas",
        "doesntEat": "Ne mange pas",
        "doesntUrinate": "Ne urine pas",
        "highlight": "Mettre en évidence",
        "hospitalizedStatus": {
          "average": "Maintenu",
          "bad": "Dégradé",
          "good": "Amélioré"
        },
        "lock": "Verrouiller",
        "locked": "Verrouillé",
        "lockedBy": "Verrouillé par",
        "owesMoney": "Le client doit de l'argent",
        "reopen": "Réouvrir",
        "reopenCheck": "Êtes-vous sûr de vouloir réouvrir ?",
        "report": "Rapport",
        "startHour": "Heure de début",
        "step": "Intervalle horaire",
        "taskState": {
          "closed": "Fermé",
          "open": "Ouvert",
          "title": "État"
        },
        "total": "Total",
        "unlock": "Déverrouiller",
        "warningReason": "Vous voyez cet avertissement car vous n'avez pas effectué une action de la tâche",
        "yesterdaysConsumptions": "Pour voir les consommations des jours précédents, revenez en arrière dans le dossier"
      },
      "edit": {
        "title": "Modifier l'hospitalisation"
      },
      "internment": {
        "clinicalEvolution": "Évolution clinique",
        "consumptions": "Consommations",
        "costEstimate": "Nouvelle dépense",
        "create": "Enregistrer",
        "details": {
          "allEvents": "Tous les événements",
          "allTasks": "Toutes les tâches",
          "cantReopen": "Cet hospitalisation ne peut pas être rouvert. Veuillez nous contacter pour plus d'informations.",
          "closed": "Fermé",
          "columnsCount": "Nb de colonnes",
          "completedActions": "Actions effectuées",
          "currentDay": "Jour actuel",
          "dailyConsumptions": "Les consommations affichées ici sont uniquement pour ce jour d'hospitalisation",
          "dayTasks": "Tâches du jour",
          "dischargeBy": "Libération planifiée par",
          "doesntDefecate": "Ne défèque pas",
          "doesntEat": "Ne mange pas",
          "doesntUrinate": "Ne urine pas",
          "highlight": "Mettre en évidence",
          "hospitalizedStatus": {
            "average": "Maintenu",
            "bad": "Détérioré",
            "good": "Amélioré"
          },
          "lock": "Verrouiller",
          "locked": "Verrouillé",
          "lockedBy": "Verrouillé par",
          "owesMoney": "Le client doit de l'argent",
          "reopen": "Rouvrir",
          "reopenCheck": "Êtes-vous sûr de vouloir rouvrir ?",
          "report": "Rapport",
          "reports": "Rapports",
          "startHour": "Heure de début",
          "step": "Intervalle horaire",
          "taskState": {
            "closed": "Fermée",
            "open": "Ouverte",
            "title": "État"
          },
          "total": "Total",
          "unlock": "Déverrouiller",
          "warningReason": "Vous voyez cet avertissement parce que vous n'effectuez pas une action de la tâche",
          "yesterdaysConsumptions": "Pour voir les consommations des jours précédents, revenez en arrière dans le dossier"
        },
        "diagnosis": "Diagnostic",
        "discharge": "Planifier la sortie",
        "dischargeDate": "Date de sortie",
        "dischargeText": "Note de sortie",
        "discharged": "Sortie prévue",
        "duration": "Durée",
        "end": "Sortie",
        "endReason": "Note de sortie",
        "ended": "Fin",
        "event": {
          "catheter": "Cathéter",
          "contactOwner": "Contacter le propriétaire",
          "faeces": "Fèces",
          "feeding": "Alimenter",
          "fluidRate": "Débit de fluide",
          "food": "Alimentation",
          "temperature": "Température",
          "urine": "Urine",
          "vomit": "Vomissements"
        },
        "finances": "Finances",
        "financialReport": "Rapport financier",
        "hotel": {
          "name": "Hôtel",
          "namePlural": "Hôtels"
        },
        "infected": {
          "name": "Infecté",
          "namePlural": "Infectés"
        },
        "internmentType": "Type",
        "location": "Emplacement",
        "newMsg": "Hospitalisation",
        "normal": {
          "name": "Normal",
          "namePlural": "Normaux"
        },
        "notes": "Notes",
        "otherTasks": "Autres",
        "outpatient": {
          "name": "Ambulatoire",
          "namePlural": "Ambulatoires"
        },
        "reason": "Raison",
        "reference": {
          "name": "Référence",
          "namePlural": "Références"
        },
        "report": {
          "body": "Body / Collier",
          "clean": "Animal propre / sec",
          "dressing": "Pansement",
          "moreDetails": "Consultez plus de détails sur cet hospitalisation à l'adresse suivante :",
          "release": "Libération",
          "removeCatheter": "Retirer le cathéter",
          "verify": "Vérifier avant de libérer l'animal"
        },
        "responsibleVet": "Responsable",
        "revertDischarge": "Annuler la sortie",
        "started": "Début",
        "status": "Statut",
        "statusDsc": {
          "close": "Terminé",
          "open": "En cours"
        },
        "surgery": {
          "name": "Chirurgie",
          "namePlural": "Chirurgies"
        },
        "symptoms": "Symptômes",
        "title": "Hospitalisation",
        "warningMessage": "Avertissement",
        "workers": "Travailleurs"
      },
      "new": {
        "title": "Nouvel Hospitalisation"
      },
      "title": "Hospitalisations",
      "welcome": {
        "hopi": "Si vous utilisez déjà notre logiciel de hospitalisation, connectez-vous ici",
        "trial": "Expérimental",
        "trialMsg": "Essayez gratuitement le nouveau module de hospitalisation"
      }
    },
    "intl": {
      "attention": "Attention!",
      "close": "Êtes-vous sûr de vouloir fermer?",
      "discharge": "Êtes-vous sûr de vouloir programmer la sortie?",
      "emptyString": "--",
      "error": "Erreur",
      "finish": "Êtes-vous sûr de vouloir terminer?",
      "information": "info",
      "my": "Mes",
      "noContent": "Aucun contenu",
      "or": "ou",
      "pause": "Êtes-vous sûr de vouloir mettre en pause la consultation?",
      "remove": "Êtes-vous sûr de vouloir supprimer?",
      "revert": "Êtes-vous sûr de vouloir annuler?",
      "revertDischarge": "Êtes-vous sûr de vouloir annuler la sortie?",
      "showLess": "Afficher moins...",
      "showMore": "Afficher plus...",
      "unsavedChanges": "En annulant, toutes les modifications seront perdues. Voulez-vous continuer?"
    },
    "invite": {
      "invitedMsg": "Invitation envoyée à :",
      "title": "Inviter"
    },
    "invoices": {
      "changeInvoice": "Modifier la facture sélectionnée",
      "invoice": {
        "annuled": "Document annulé",
        "date": "Date",
        "emissionDate": "Date d'émission",
        "entity": "Entité",
        "error": "Échec du traitement",
        "number": "N° Facture",
        "processing": "En traitement",
        "reference": "Référence",
        "status": "Statut",
        "title": "Facture",
        "total": "Total"
      },
      "new": {
        "title": "Nouvelle Facture"
      },
      "title": "Factures"
    },
    "itemAdjustments": {
      "itemAdjustment": {
        "title": "Ajustement d'inventaire"
      },
      "messages": {
        "createdSuccess": "Ajustement d'inventaire créé avec succès"
      },
      "new": {
        "title": "Nouvel ajustement d'inventaire"
      },
      "title": "Ajustements"
    },
    "itemSelectList": {
      "compoundTotal": "Total composé",
      "confirm": "Confirmer",
      "name": "Nom",
      "orderNumber": "Numéro de commande",
      "pv": "Prix (hors TVA)",
      "pvSale": "Prix de vente (hors TVA)",
      "pvp": "PVP 1",
      "pvp2": "PVP 2",
      "pvp3": "PVP 3",
      "quantity": "Quantité",
      "value": "Valeur"
    },
    "itemTypes": {
      "description": "Voir tous les types d'articles",
      "edit": {
        "title": "Modifier le type d'article"
      },
      "itemType": {
        "description": "Description",
        "name": "Nom",
        "parent": "Unité mère",
        "stockable": "Peut mouvementer le stock",
        "title": "Type"
      },
      "new": {
        "title": "Nouveau type d'article"
      },
      "title": "Types d'articles",
      "translation": {
        "ADS": "ADS",
        "Reprodução": "Reproduction",
        "cmdt": "Examens (MCDT)",
        "cmdt-external_imagiology": "Imagerie externe",
        "cmdt-external_lab": "Laboratoire externe",
        "cmdt-internal_imagiology": "Imagerie interne",
        "cmdt-internal_lab": "Laboratoire interne",
        "complementary_funeral_services": "Services funéraires complémentaires",
        "complementary_services": "Services complémentaires",
        "complementary_services_recovery": "Services de récupération complémentaires",
        "consultations": "Consultations",
        "consultations-external": "Externe",
        "consultations-first_consultation": "Première consultation",
        "consultations-followup": "Suivi",
        "consultations-general": "Général",
        "consultations-specialty": "Spécialité",
        "consultations-urgency": "Urgence",
        "consumables": "Consommables",
        "consumables-cleaning": "Nettoyage",
        "consumables-disposable": "Jetable",
        "consumables-mcdt": "MCDT",
        "consumables-stewardship": "Gestion",
        "consumables-surgical": "Chirurgical",
        "drugs": "Médicaments",
        "drugs-anesthetics": "Anesthésiques",
        "drugs-anti_infective": "Anti-infectieux",
        "drugs-anti_inflammatory": "Anti-inflammatoires",
        "drugs-antibiotics": "Antibiotiques",
        "drugs-antidotes": "Antidotes",
        "drugs-antineoplastics": "Antinéoplasiques",
        "drugs-cardiovascular _system": "Système cardiovasculaire",
        "drugs-chemotherapy": "Chimiothérapie",
        "drugs-digestive_system": "Système digestif",
        "drugs-endocrinological": "Endocrinologie",
        "drugs-external_Insulin": "Insuline externe",
        "drugs-external_otological": "Otologiques externes",
        "drugs-hormones": "Hormones",
        "drugs-immunostimulants": "Immunostimulants",
        "drugs-immunosuppressors": "Immunosuppresseurs",
        "drugs-integumentary_system": "Système tégumentaire",
        "drugs-musculoskeletal_system": "Système musculosquelettique",
        "drugs-nervous_system": "Système nerveux",
        "drugs-other": "Autres",
        "drugs-respiratory_system": "Système respiratoire",
        "drugs-urinary_system": "Système urinaire",
        "drugs-visual_system": "Système visuel",
        "food": "Alimentation",
        "food-diet": "Régime alimentaire",
        "food-physiological": "Physiologique",
        "food-snaks": "Collations",
        "health_insurance": "Assurances santé",
        "health_plans": "Plans de santé",
        "hospitalization": "Hospitalisation",
        "hospitalization-fluidotherapy": "Fluidothérapie",
        "hospitalization-hospitalization": "Hospitalisation",
        "hospitalization-procedures": "Procédures hospitalières",
        "hotel": "Hôtel",
        "pecuaria": "Élevage",
        "petshop": "Animalerie",
        "petshop-clothing": "Vêtements",
        "petshop-handling": "Manipulation",
        "petshop-home": "Maison",
        "petshop-hygiene": "Hygiène",
        "petshop-toys": "Jouets",
        "procedures": "Procédures",
        "procedures_medical": "Procédure médicale",
        "procedures_nurse": "Infirmière",
        "profilaxia": "Prophylaxie",
        "profilaxia-external_deworming": "Vermifuges externes",
        "profilaxia-identification": "Identification",
        "profilaxia-internal_deworming": "Vermifuges internes",
        "reference_consultation": "Consultation de référence",
        "referral": "Référence",
        "referral-transportation": "Transport",
        "rehabilitation": "Réhabilitation",
        "soft_tissue_breeder": "Tissus mous Système reproducteur",
        "soft_tissue_cardiovascular": "Tissus mous Système cardiovasculaire",
        "soft_tissue_gastrointestinal": "Tissus mous Système gastro-intestinal",
        "soft_tissue_integumentary": "Tissus mous Système tégumentaire",
        "soft_tissue_locomotor": "Tissus mous Système locomoteur",
        "soft_tissue_nervous": "Tissus mous Système nerveux",
        "soft_tissue_respiratory": "Tissus mous Système respiratoire",
        "soft_tissue_urinary": "Tissus mous Système urinaire",
        "soft_tissue_visual": "Tissus mous Système visuel",
        "studies": "Études",
        "suplementos": "Suppléments",
        "surgery": "Chirurgie",
        "surgery-drugs": "Anesthésie et Analgésie",
        "surgery-orthopedics": "Orthopédie",
        "surgery-soft_tissue": "Tissus mous",
        "vaccines": "Vaccins",
        "wellness": "Bien-être",
        "wellness-recorte": "Toilettage",
        "wellness-shower": "Douche"
      }
    },
    "items": {
      "chooseUnit": "Le champ unité de base est obligatoire",
      "companions": "Articles associés",
      "disabled": "Désactivé",
      "edit": {
        "title": "Modifier l'article",
        "types": "Modifier les types"
      },
      "enabled": "Activé",
      "entry": {
        "title": "Entrée de commandes"
      },
      "form": {
        "category": {
          "generic": "Générique",
          "lastPurchase": "Dernier achat",
          "order": "Commande",
          "period": "Période",
          "periodic": "Périodique",
          "sellable": "Vendable",
          "stockable": "Stockable",
          "unstockable": "Non stockable"
        },
        "placeholder": {
          "name": "Nom"
        }
      },
      "inactiveItems": "Articles inactifs",
      "item": {
        "barcode": "Code-barres",
        "cantSelectSame": "Cet article a déjà été ajouté",
        "companyItemSubType": "Sous-type d'article",
        "complementaryDescription": "Notes internes",
        "cost": "Coût",
        "defaultWarehouse": "Entrepôt par défaut",
        "description": "Nom 2ème ligne",
        "duration": "Durée",
        "errors": {
          "nameAlreadyUsed": "Le nom de cet article ne peut pas être modifié car il a déjà été utilisé dans des documents",
          "typeAlreadyUsed": "Ne peut pas être modifié car il a déjà été utilisé dans des documents"
        },
        "examUnit": "Unité d'examen",
        "inactive": "Cet article est inactif!",
        "itemClass": {
          "0": "Inconnu",
          "1": "Services",
          "2": "Matières premières",
          "3": "Travaux en cours",
          "4": "Sous-produits",
          "5": "Produits finis",
          "6": "Marchandises",
          "7": "Immobilisé",
          "title": "Classe"
        },
        "linePrice": "Prix de ligne",
        "linePriceOld": "Ancien prix de ligne",
        "linePriceWithVat": "Prix avec TVA",
        "maxStock": "Stock Max",
        "minStock": "Stock Min",
        "name": "Nom",
        "parameter": "Paramètre",
        "pmsID": "ID externe",
        "prescription": "Prescription",
        "price": "Prix (HT)",
        "priceAutomatic": "Prix automatique",
        "profit": "Profit",
        "purchasePrice": "Prix d'achat (HT)",
        "pvp": "Prix de vente",
        "pvp2": "Prix de vente 2",
        "pvp3": "Prix de vente 3",
        "pvpDiscount": "Remise",
        "pvpDiscount2": "Remise 2",
        "pvpDiscount3": "Remise 3",
        "pvpMargin": "Marge",
        "pvpMargin2": "Marge 2",
        "pvpMargin3": "Marge 3",
        "pvpMarkup": "Marge bénéficiaire",
        "pvpMarkup2": "Marge bénéficiaire 2",
        "pvpMarkup3": "Marge bénéficiaire 3",
        "quantity": "Quantité",
        "reservedQuantity": "Quantité réservée",
        "saleUnit": "Unité de vente",
        "saleUnits": "Unités de vente",
        "stock": "Stock",
        "stockUnit": "Unité d'achat",
        "tarifVariable": "Tarif variable",
        "title": "Article",
        "type": "Type",
        "unitPrice": "Prix unitaire",
        "unitPriceWithVat": "Prix unitaire avec TVA",
        "warehouse": "Entrepôt"
      },
      "messages": {
        "createCondition": "Pour 1, laissez-le vide",
        "createdSuccess": "Article créé avec succès"
      },
      "new": {
        "duplicate": "Dupliquer",
        "title": "Nouvel Article"
      },
      "originalRetailPrice": "Prix de vente initial",
      "retailPrice1": "Prix de vente 1",
      "retailPrice2": "Prix de vente 2",
      "retailPrice3": "Prix de vente 3",
      "title": "Articles",
      "totalProducts": "Total de produits",
      "totalServices": "Total de services"
    },
    "laboratories": {
      "actions": {
        "saveAndDoneAnalysis": "Enregistrer et Terminer"
      },
      "laboratory": {
        "name": "Nom",
        "title": "Laboratoire"
      },
      "laboratoryType": {
        "external": "Externe",
        "internal": "Interne",
        "title": "Type de Laboratoire"
      },
      "new": {
        "title": "Nouveau laboratoire"
      },
      "search": {
        "title": "Rechercher"
      },
      "title": "Laboratoires"
    },
    "language-select": {
      "language": {
        "en": "Anglais",
        "es": "Espagnol",
        "es-mx": "Espagnol Mexicain",
        "fr": "Français",
        "pt": "Portugais",
        "pt-br": "Portugais Brésilien"
      }
    },
    "liaisons": {
      "messages": {
        "title": "Correspondant créé avec succès"
      },
      "new": {
        "title": "Nouveau vétérinaire correspondant"
      },
      "title": "Correspondants",
      "vet": "Vétérinaire correspondant"
    },
    "listings": {
      "active": "Actifs",
      "all": "Tous",
      "expired": "Expirés",
      "inflow": "Entrées",
      "others": "Autres",
      "pending": "En attente",
      "salesByUser": "Ventes par utilisateur",
      "taxes": "Taxes",
      "title": "Listes"
    },
    "login": {
      "forgot": "Mot de passe oublié?",
      "invalidPassword": "Mot de passe incorrect",
      "missingAccount": "Vous n'avez pas de compte?",
      "submit": "Se connecter",
      "subtitle": "Se connecter",
      "title": "Se connecter",
      "unknownUser": "Adresse e-mail inconnue"
    },
    "manufacturers": {
      "new": {
        "title": "Nouveau fabricant"
      },
      "title": "Fabricants"
    },
    "memos": {
      "advance": "Avance",
      "date": "Date",
      "discountAbsence": "Absence de remise sur la facture",
      "memo": {
        "title": "Note de crédit"
      },
      "motive": "Motif",
      "paymentConditions": "Conditions de paiement",
      "priceError": "Erreur de prix",
      "priceincrease": "Augmentation de prix",
      "reference": "Référence",
      "rejectedProduct": "Produit rejeté",
      "stockRuture": "Rupture de stock",
      "title": "Notes de crédit"
    },
    "menu": {
      "new": "Nouveau"
    },
    "models": {
      "company": {
        "child": "Sous-unité",
        "children": "Sous-unités",
        "country": "Pays",
        "email": "E-mail",
        "entity": "Entreprise",
        "entityPlural": "Entreprises",
        "facebook": "Facebook",
        "logo": "Logo",
        "name": "Nom Fiscal",
        "notes": "Remarques",
        "parent": "Unité mère",
        "phone": "Téléphone",
        "street": "Adresse fiscale",
        "town": "Ville",
        "vatNumber": "VAT",
        "zip": "Code Postal"
      },
      "description": "Voir tous les modèles",
      "employee": {
        "entity": "Employé",
        "entityPlural": "Employés",
        "profile": "Profil"
      },
      "form": {
        "placeholder": {
          "name": "Nom"
        }
      },
      "messages": {
        "createdSuccess": "Modèle créé avec succès"
      },
      "model": {
        "name": "Nom",
        "title": "Modèle"
      },
      "new": {
        "title": "Nouveau Modèle"
      },
      "person": {
        "VAT": "VAT",
        "country": "Pays",
        "email": "E-mail",
        "entity": "Personne",
        "entityPlural": "Personnes",
        "mobilePhone": "Téléphone portable",
        "name": "Nom",
        "phone": "Téléphone",
        "street": "Adresse",
        "town": "Ville",
        "zip": "Code Postal"
      },
      "role": {
        "administrator": "Administrateur",
        "employee": "Employé",
        "undermanager": "Logistique",
        "unknown": "Profil inconnu"
      },
      "title": "Modèles",
      "user": {
        "VAT": "VAT",
        "email": "E-mail",
        "entity": "Utilisateur",
        "entityPlural": "Utilisateurs",
        "name": "Nom",
        "password": "Mot de passe"
      }
    },
    "modelsTable": {
      "noDataToShow": "Aucune donnée à afficher",
      "of": "de",
      "search": "Filtrer les résultats :",
      "show": "Afficher"
    },
    "modules": {
      "management": {
        "description": "Système de gestion",
        "inactiveClient": "Le client sélectionné est inactif",
        "tariffNotIncluded": "Votre tarif actuel ne comprend pas l'abonnement à ce module",
        "title": "Gestion",
        "unavailableModule": "Fonctionnalité non disponible"
      },
      "sales": {
        "title": "Ventes"
      }
    },
    "monitorings": {
      "monitoring": {
        "abdomen": "Abdomen",
        "attitude": "Attitude",
        "bloodPressure": "Pression artérielle",
        "capillaryRepletionTime": "TRC",
        "description": "Description",
        "ended": "Fin",
        "ganglia": "Ganglions",
        "glucose": "Glucose",
        "heartRate": "Fréquence cardiaque",
        "hematocrit": "Hématocrite",
        "hydration": "Hydratation",
        "maxValue": "Valeur maximale",
        "mentalState": "État mental",
        "minValue": "Valeur minimale",
        "mucous": "Muqueuses",
        "name": "Nom",
        "notes": "Notes",
        "other": "Autre",
        "pain": "Douleur",
        "period": "Fréquence",
        "physicalExame": "Examen physique",
        "pulse": "Pouls",
        "respiratoryFrequency": "Fréquence respiratoire",
        "started": "Début",
        "temperature": "Température",
        "title": "Surveillance",
        "totalProteins": "Protéines totales"
      },
      "title": "Surveillances"
    },
    "navigation": {
      "addAsPatient": "Ajouter comme patient",
      "back": "Retour",
      "confirm": "Confirmer"
    },
    "noRecords": "Aucune donnée à afficher",
    "notes": {
      "contact": "Contact",
      "date": "Date",
      "name": "Nom",
      "note": {
        "editNote": "Modifier la note",
        "newNote": "Nouvelle note"
      },
      "noteType": "Type de note",
      "outcomeType": "Résultat",
      "patient": "Patient",
      "title": "Notes",
      "value": "Note"
    },
    "openItems": {
      "amount": "Montant",
      "debtAmount": "Montant dû",
      "discount": "Remise",
      "dueDate": "Date d'échéance",
      "name": "Document",
      "notes": "Notes",
      "payingAmount": "Montant à payer",
      "paymentCantBeZero": "Un paiement ne peut pas être émis avec des valeurs négatives",
      "receiptCantBeZero": "Un reçu doit avoir une valeur positive pour être émis",
      "receiptInfoMessage": "Remarque : pour clôturer une facture avec une note de crédit, ce n'est pas un reçu (il n'autorise pas un total de zéro), dans ce cas, effectuer un paiement, il permet déjà zéro"
    },
    "options": {
      "no": "Non",
      "yes": "Oui"
    },
    "orderLines": {
      "new": {
        "title": "Nouvelle ligne"
      },
      "orderLine": {
        "change": "Changement",
        "discount": "Remise",
        "id": "ID",
        "liquid": "Liquide",
        "name": "Nom",
        "productsAndServices": "Produits et services",
        "quantity": "Quantité",
        "title": "Ligne de commande",
        "total": "Total",
        "totalWithVAT": "Total avec TVA",
        "totalWithoutVAT": "Total sans TVA",
        "vat": "TVA"
      },
      "title": "Lignes"
    },
    "orders": {
      "edit": {
        "backToStart": "Retour au début"
      },
      "order": {
        "title": "Panier"
      },
      "title": "Paniers"
    },
    "organizations": {
      "new": {
        "title": "Nouveau cabinet vétérinaire"
      },
      "organization": {
        "avatar": "Avatar",
        "avatars": "Avatars",
        "businessHours": "Heures d'ouverture",
        "children": "Sous-unités",
        "company": "Entreprise",
        "country": "Pays",
        "created": "Créé",
        "details": {
          "disabled": "Cette entité n'a pas de plan associé. Veuillez contacter le support."
        },
        "email": "Email",
        "enable": "Activer",
        "facebook": "Facebook",
        "index": {
          "afterNoon": "Bon après-midi",
          "hi": "Bonjour",
          "morning": "Bonjour",
          "night": "Bonne nuit"
        },
        "name": "Nom",
        "notes": "Notes",
        "parent": "Unité mère",
        "patientsCount": "Nombre de patients",
        "phone": "Téléphone",
        "plan": "Plan",
        "street": "Adresse",
        "title": "Cabinet vétérinaire",
        "town": "Ville",
        "zip": "Code postal"
      },
      "title": "Organisations"
    },
    "pages": {
      "absences": "Absences",
      "account": "Compte",
      "actions": "Actions",
      "active": "Actif",
      "appointments": "Rendez-vous",
      "approval": "Approbation des absences",
      "archive": "Archive",
      "avatar": "Avatar",
      "avatars": "Avatares",
      "backoffice": "Paramètres",
      "boxes": "Boîtes",
      "calendar": "Calendrier",
      "campaign": "Campagne",
      "campaigns": "Campagnes",
      "checkinWait": "Salle d'attente",
      "checkoutWait": "Check-out",
      "clinical": "Clinique",
      "clinicalHistory": "Historique clinique",
      "clinicalReport": "Rapport clinique",
      "clinicalSummary": "Résumé clinique",
      "clinicalWaitingRooms": "Salle d'attente clinique",
      "companies": "Entreprises",
      "company": "Entreprise",
      "configurations": "Configurations",
      "consultation": "Consultation",
      "consultations": "Consultations",
      "corporate": "Entreprise",
      "crm": "CRM",
      "currentAccount": "Compte courant",
      "currentAccounts": "Comptes courants",
      "custom": "Personnalisé",
      "customers": "Clients",
      "day": "Jour",
      "day2": "Jour v2",
      "debt": "Dette",
      "debts": "Dettes",
      "details": "Détails",
      "diagnosis": "Diagnostic",
      "documents": "Documents",
      "edit": "Éditer",
      "employees": "Employés",
      "equipment": "Équipement",
      "equipmentParameter": "Paramètre de l'équipement",
      "equipmentParameterType": "Type de paramètre d'équipement",
      "equipmentParameterTypes": "Types de paramètres d'équipement",
      "equipmentParameters": "Paramètres de l'équipement",
      "equipmentType": "Type d'équipement",
      "equipmentTypes": "Types d'équipement",
      "equipments": "Équipements",
      "events": "Événements",
      "exams": "Examens",
      "excess": "Excès",
      "financialAccountAuto": "Historique",
      "financialAccounts": "Comptes financiers",
      "financialReport": "Rapport financier",
      "general": "Général",
      "group": "Groupe",
      "hotel": "Hôtel",
      "howTo": "Comment faire",
      "hr": "RH",
      "internal": "Interne",
      "internment": "Hospitalisation",
      "internments": "Hospitalisation",
      "inventory": "Inventaire",
      "invite": "Inviter",
      "invites": "Invitations",
      "invoice": "Facture",
      "invoices": "Factures",
      "invoicesMemos": "Documents",
      "item": "Article",
      "itemAdjustments": "Ajustements d'inventaire",
      "items": "Articles",
      "jasmin": "Jasmin",
      "laboratories": "Laboratoires",
      "liaisons": "Liens",
      "listings": "Listages",
      "manufacturer": "Fabricant",
      "manufacturers": "Fabricants",
      "movements": "Mouvements",
      "new": "Nouveau",
      "openItems": "Documents ouverts",
      "openPurchaseInvoice": "Achats ouverts",
      "openPurchaseInvoices": "Achats ouverts",
      "organization": "Organisation",
      "organizations": "Organisations",
      "overview": "Aperçu",
      "overviewShopping": "Aperçu des achats",
      "patients": "Patients",
      "periodics": "Périodiques",
      "pointOfSales": "Point de vente",
      "procurement": "Approvisionnement",
      "purchaseInvoice": "Achat",
      "purchaseInvoices": "Achats",
      "purchaseInvoicesDocuments": "Documents",
      "purchaseMemo": "Note de crédit",
      "purchaseMemos": "Notes de crédit",
      "purchaseOrder": "Commande",
      "purchaseOrders": "Commandes",
      "purchaseRequest": "Demande d'achat",
      "purchaseRequests": "Demandes d'achat",
      "purchases": "Achats",
      "questionnaireInvite": "Questionnaire",
      "questionnaireInvites": "Questionnaire",
      "quotation": "Devis",
      "quotations": "Devis",
      "reception": "Réception",
      "recoverPassword": "Récupération du mot de passe",
      "referral": "Référence",
      "referrals": "Références",
      "reward": "Points",
      "rewards": "Points",
      "saft": "SAF-T",
      "sales": "Ventes",
      "schedule": "Agenda",
      "scheduleWait": "Attente de planning",
      "security": "Sécurité",
      "settings": "Paramètres",
      "sheltereds": "Abrités",
      "shifts": "Postes",
      "shopping": "Achats",
      "shoppingCart": "Panier",
      "shoppingCarts": "Paniers en attente",
      "shortage": "Pénurie",
      "simpleView": "Vue simple",
      "sms": "SMS",
      "stats": "Statistiques",
      "stays": "Séjours",
      "supplier": "Fournisseur",
      "suppliers": "Fournisseurs",
      "tasks": "Tâches",
      "templates": "Modèles",
      "today": "Stock actuel",
      "todoChore": "Tâche",
      "todoChores": "Tâches",
      "todoDrug": "Médicament",
      "todoDrugs": "Médicaments",
      "todoExam": "Examen",
      "todoExams": "Examens",
      "todoMonitoring": "Surveillance",
      "todoMonitorings": "Surveillances",
      "todoSerum": "Sérum",
      "todoSerums": "Sérums",
      "transferStock": "Transférer le stock",
      "updateItems": "Modifier les articles",
      "visitWait": "En visite",
      "waitingRoom": "Salle d'attente",
      "waitingRooms": "Salles d'attente",
      "warehouse": "Entrepôt",
      "warehouses": "Entrepôts",
      "week": "Semaine",
      "workers": "Employés"
    },
    "patients": {
      "disabled": "Désactivé",
      "edit": {
        "title": "Modifier le patient"
      },
      "enabled": "Activé",
      "form": {
        "category": {
          "fisicalDetails": "Détails physiques",
          "other": "Autres",
          "personalInformation": "Informations personnelles"
        },
        "environmentType": {
          "both": "Les deux",
          "indoor": "Intérieur",
          "outdoor": "Extérieur"
        },
        "placeholder": {
          "behavior": "Comportement",
          "census": "Recensement",
          "chip": "Numéro de puce",
          "comments": "Commentaires",
          "commentsExtra": "Commentaires supplémentaires",
          "eyes": "Exemple: Bleu",
          "fur": "Exemple: Court",
          "height": "Hauteur",
          "insuranceType": "Type d'assurance",
          "name": "Exemple: Boby",
          "notes": "Autres informations",
          "passaport": "Numéro de passeport",
          "pathology": "Sélectionner une pathologie",
          "pedigree": "Pedigree",
          "temperament": "Exemple: Calme"
        },
        "select": {
          "breed": "Sélectionner une race",
          "diet": "Sélectionner un type de régime alimentaire",
          "environmentType": "Sélectionner le type d'environnement",
          "owner": "Sélectionner un client",
          "price": "Sélectionner le prix",
          "species": "Sélectionner une espèce",
          "tail": "Sélectionner un type de queue",
          "veterinary": "Sélectionner le vétérinaire préféré"
        }
      },
      "inactivePatients": "Patients inactifs",
      "messages": {
        "createdSuccess": "Patient créé avec succès",
        "mustHaveWeight": "Le patient doit avoir un poids pour utiliser le calculateur"
      },
      "new": {
        "title": "Nouveau patient"
      },
      "number": "N° de patients",
      "patient": {
        "age": "Âge",
        "alert": "Alerte",
        "avatar": "Avatar",
        "behavior": "Comportement",
        "birthdate": "Date de naissance",
        "breed": "Race",
        "census": "Recensement",
        "chip": "Puce",
        "comment": "Commentaire",
        "comments": "Commentaires",
        "commentsExtra": "Commentaires supplémentaires",
        "deathReason": "Raison du décès",
        "deathdate": "Date de décès",
        "deceased": "Décédé",
        "deleted": "Supprimer",
        "diet": "Régime alimentaire",
        "environmentType": "Type d'environnement",
        "eyes": "Yeux",
        "female": "Femelle",
        "fur": "Pelage",
        "furColor": "Couleur",
        "genderless": "Sans genre",
        "height": "Hauteur",
        "hermaphrodite": "Hermaphrodite",
        "id": "ID",
        "idcard": "Carte d'identité",
        "inactive": "Inactif",
        "insurance": "Assurance",
        "insuranceType": "Type d'assurance",
        "location": "Emplacement",
        "male": "Mâle",
        "mixedbreed": "Race mixte",
        "name": "Nom",
        "neutral": "Neutre",
        "newWeight": "Nouveau poids",
        "noOwner": "Impossible de planifier pour un patient sans propriétaire associé",
        "noOwnerConsultation": "Impossible de créer une consultation pour un patient sans propriétaire associé",
        "notes": "Notes",
        "otherSpecies": "Autres espèces",
        "ownerPrefered": "Préféré par le client",
        "passport": "Passeport",
        "pathologies": "Pathologies",
        "pathology": "Pathologie",
        "patientSince": "Patient depuis",
        "pedigree": {
          "no": "Non",
          "title": "Pedigree",
          "yes": "Oui"
        },
        "pmsID": "Ancien ID",
        "price": "Prix",
        "sex": "Sexe",
        "species": "Espèce",
        "status": "Statut",
        "sterilized": "Stérilisé",
        "tailType": "Type de queue",
        "tattoo": "Tatouages",
        "tattooNumber": "Numéro de tatouage",
        "temperament": {
          "agressive": "Agressif",
          "good": "Bon",
          "moderate": "Modéré",
          "title": "Tempérament"
        },
        "title": "Patient",
        "unknown": "Inconnu",
        "veterinaryPrefered": "Vétérinaire préféré",
        "weights": {
          "changedDrug": "Les valeurs de ce médicament peuvent être obsolètes car le poids de l'animal a été modifié.",
          "changedSerum": "Les valeurs de ce sérum peuvent être obsolètes car le poids de l'animal a été modifié.",
          "title": "Poids",
          "weight": {
            "new": "Nouveau poids",
            "title": "Poids",
            "weighted": "Date"
          }
        }
      },
      "title": "Patients"
    },
    "payments": {
      "noOpenItems": "Ce client n'a pas de notes de crédit ouvertes"
    },
    "periodics": {
      "actions": {
        "done": "Marquer comme fait"
      },
      "messages": {
        "createdSuccess": "Périodique créé avec succès"
      },
      "new": {
        "cadency": "Cadence",
        "description": "Description",
        "intake": "Prise",
        "labels": {
          "cadency": "Cadence",
          "description": "Décrivez brièvement le périodique",
          "observations": "Observations supplémentaires",
          "product": "Sélectionner le produit",
          "selectPeriodicType": "Type de périodique",
          "species": "Sélectionner les espèces souhaitées"
        },
        "nextDate": "Prochaine prise",
        "numberOfReinforcements": "Nombre de renforcements",
        "observations": "Observations",
        "pastDate": "Vous entrez une date dans le passé",
        "previousDate": "Dernière prise",
        "product": "Produit",
        "reinforcmentCadency": "Cadence de renforcement",
        "species": "Espèces",
        "title": "Nouveau périodique",
        "type": "Type de périodique"
      },
      "periodic": {
        "intake": "Prise",
        "intakeDate": "Date",
        "nextPeriodic": "Prochaine",
        "title": "Périodique",
        "type": "Type"
      },
      "periods": {
        "day": "Jour",
        "drugs": {
          "noRepeat": "Toujours visible",
          "period1": "q1h",
          "period12": "BID",
          "period120": "q5d",
          "period168": "q7d",
          "period2": "q2h",
          "period24": "SID",
          "period3": "q3h",
          "period4": "q4h",
          "period48": "q48h",
          "period5": "q5h",
          "period6": "QID",
          "period72": "q72h",
          "period8": "TID",
          "period96": "q4d",
          "singleTake": "Prise unique"
        },
        "month": "Mois",
        "today": "Aujourd'hui",
        "week": "Semaine",
        "year": "An"
      },
      "status": {
        "communicated": "Communiqué",
        "done": "Administré",
        "nextDose": "Prochaine dose",
        "notCommunicated": "Non communiqué",
        "scheduled": "Planifié",
        "taken": "Historique",
        "title": "Statut",
        "toTake": "À prendre"
      },
      "title": "Périodiques"
    },
    "plans": {
      "entity": "Plans",
      "new": {
        "title": "Nouveau Plan"
      },
      "plan": {
        "entity": "Plan",
        "name": "Nom"
      }
    },
    "pointOfSales": {
      "animal": "Animal",
      "barcodeScan": "Scan de code-barres",
      "bill": "Facturer",
      "billingDatas": {
        "billingData": {
          "title": "Données de facturation"
        },
        "choose": "Choisir",
        "finalCustomer": "Client final"
      },
      "camera": "Caméra",
      "change": "Changer",
      "discount": "Remise",
      "documentTypes": {
        "documentType": {
          "title": "Type de document"
        },
        "invoice": "Facture",
        "invoiceReceipt": "Facture avec reçu",
        "simplifiedInvoice": "Facture simplifiée",
        "title": "Types de document"
      },
      "errors": {
        "debt": "Vous allez devoir!",
        "pdfNotEmiting": "PDF n'est pas encore disponible, veuillez télécharger manuellement",
        "pleaseTryDocuments": "Le document a été émis mais il y a eu une erreur lors de la récupération du PDF, veuillez essayer sur la page des documents"
      },
      "issue": "Émettre",
      "payAndCheckout": "Payer et passer à la caisse",
      "paymentMethods": {
        "amountPaid": "Montant versé",
        "card": "Carte",
        "cash": "Espèces",
        "check": "Chèque",
        "checkDate": "Date du chèque",
        "checkNumber": "Numéro de chèque",
        "directDebit": "Débit direct",
        "paymentMethod": {
          "title": "Méthode de paiement"
        },
        "title": "Méthodes de paiement",
        "transfer": "Virement"
      },
      "quantity": "Quantité",
      "saveNewPrice": "Enregistrer un nouveau PVP?",
      "search": "Rechercher un client",
      "select": "Sélectionner un client",
      "title": "Point de ventes",
      "warehouse": "Entrepôt"
    },
    "prices": {
      "price": {
        "title": "Prix"
      },
      "title": "Prix"
    },
    "procurement": {
      "title": "Approvisionnement"
    },
    "purchaseInvoiceLines": {
      "messages": {
        "createdSuccess": "Ligne d'achat créée avec succès"
      },
      "new": {
        "title": "Nouvelle Ligne d'Achat"
      },
      "title": "Ligne d'Achat"
    },
    "purchaseInvoices": {
      "duplicate": {
        "title": "Dupliquer l'Achat"
      },
      "edit": {
        "saving": "Enregistrer les modifications",
        "savingLines": "Sauvegarder les lignes"
      },
      "invalid": {
        "amount": "Quantité invalide",
        "badAdjustment": "Données manquantes dans l'ajustement d'inventaire",
        "badHeader": "Données manquantes dans l'en-tête",
        "badLines": "Lignes invalides",
        "cost": "Coût invalide",
        "noLines": "Lignes non sélectionnées",
        "noSupplier": "Fournisseur non sélectionné",
        "vat": "TVA invalide"
      },
      "lastPurchasePrice": "Dernier prix d'achat",
      "new": {
        "date": "Date d'Entrée",
        "dueDate": "Date d'Échéance",
        "exists": "Un document avec ce nom existe déjà",
        "financialAccount": "Compte Financier",
        "internalNotes": "Notes Internes",
        "name": "Nom",
        "notes": "Remarques",
        "paidAmount": "Montant Payé",
        "payment": "Paiement",
        "paymentMethod": "Mode de Paiement",
        "supplier": "Fournisseur",
        "total": "Total"
      },
      "open": {
        "title": "Achats en Cours"
      },
      "purchaseInvoice": {
        "internalData": "Données Internes",
        "lines": "Lignes",
        "paid": "Payé",
        "payment": "Paiement",
        "status": {
          "issued": "Émis",
          "saved": "Sauvegardé",
          "title": "Statut"
        },
        "title": "Achat"
      },
      "title": "Achat"
    },
    "purchaseOrders": {
      "date": "Date",
      "details": {
        "title": "Détails de la Commande"
      },
      "done": {
        "title": "Êtes-vous sûr de vouloir marquer cette Commande comme étant faite?"
      },
      "duplicate": {
        "title": "Dupliquer la Commande"
      },
      "edit": {
        "title": "Modifier la Commande"
      },
      "info": {
        "billed": "Facturé",
        "issued": "Émis",
        "noneBilled": "Aucune commande facturée",
        "noneIssued": "Aucune commande émise",
        "noneSaved": "Aucune commande enregistrée",
        "saved": "Sauvegardé"
      },
      "item": "Article",
      "line": {
        "lastPrice": "Dernier prix € /"
      },
      "markedDone": {
        "message": "Êtes-vous sûr de vouloir marquer cette Commande comme étant faite? Cette action ne peut pas être annulée.",
        "title": "Marquer comme fait"
      },
      "messages": {
        "successfullyIssued": "Commande émise avec succès",
        "successfullySaved": "Commande enregistrée avec succès"
      },
      "name": "Nom",
      "new": {
        "title": "Nouvelle Commande"
      },
      "note": "Note",
      "payableAmount": "Montant Payable",
      "quantity": "Quantité",
      "receive": {
        "title": "Êtes-vous sûr de vouloir marquer cette Commande comme reçue?"
      },
      "send": {
        "title": "Êtes-vous sûr de vouloir envoyer cette Commande?"
      },
      "status": {
        "markedDone": "Fait",
        "received": "Reçue",
        "sent": "Envoyée",
        "title": "Statut"
      },
      "supplier": "Fournisseur",
      "title": "Commande",
      "updatedSuccess": "Commande mise à jour avec succès"
    },
    "purchaseRequests": {
      "date": "Date",
      "details": {
        "title": "Détails de la demande"
      },
      "done": {
        "title": "Êtes-vous sûr de vouloir marquer cette demande comme effectuée ?"
      },
      "duplicate": {
        "title": "Dupliquer la demande"
      },
      "edit": {
        "title": "Modifier la demande"
      },
      "info": {
        "billed": "Facturé",
        "inOrder": "En commande",
        "issued": "Émis",
        "noneBilled": "Aucune demande facturée",
        "noneInOrder": "Aucune demande en cours de commande",
        "noneIssued": "Aucune demande émise",
        "noneOrdered": "Aucune demande commandée",
        "noneSaved": "Aucune demande enregistrée",
        "ordered": "Commandé",
        "saved": "Enregistré"
      },
      "item": "Article",
      "line": {
        "lastPrice": "Dernier € /"
      },
      "markedDone": {
        "message": "Êtes-vous sûr de vouloir marquer cette demande comme effectuée ? Cette action ne peut pas être annulée.",
        "title": "Marquer comme effectué"
      },
      "messages": {
        "createdSuccess": "Demande créée avec succès"
      },
      "name": "Nom",
      "new": {
        "title": "Nouvelle demande"
      },
      "note": "Notes",
      "payableAmount": "Montant estimé",
      "quantity": "Quantité",
      "receive": {
        "title": "Êtes-vous sûr de vouloir marquer cette demande comme reçue ?"
      },
      "send": {
        "title": "Êtes-vous sûr de vouloir envoyer cette demande ?"
      },
      "status": {
        "received": "Reçu",
        "sent": "Envoyé",
        "title": "État"
      },
      "supplier": "Fournisseur",
      "title": "Demande",
      "updatedSuccess": "Demande mise à jour avec succès"
    },
    "purchases": {
      "discount": "Remise",
      "lot": "Lot",
      "messages": {
        "successfully": "Achat effectué avec succès",
        "successfullySaved": "Achat enregistré avec succès"
      },
      "price": "Prix",
      "quantity": "Quantité",
      "quantityPromotion": "Promotion",
      "shelfLife": "Durée de vie",
      "title": "Achats"
    },
    "quotations": {
      "changeQuotation": "Changer de devis",
      "name": "Nom",
      "new": "Nouveau devis",
      "quotation": {
        "title": "Devis"
      },
      "quotationsConsumed": "Pourcentage",
      "removed": "Devis désélectionné",
      "selected": "Devis sélectionné",
      "title": "Devis"
    },
    "receipts": {
      "edit": {
        "title": "Modifier le reçu"
      },
      "noOpenItems": "Ce client n'a pas de factures impayées",
      "receipt": {
        "title": "Reçu"
      },
      "title": "Reçus"
    },
    "referrals": {
      "title": "Références"
    },
    "requestLines": {
      "new": {
        "title": "Nouvelle ligne"
      },
      "orderLine": {
        "change": "Changement",
        "discount": "Remise",
        "id": "ID",
        "liquid": "Liquide",
        "name": "Nom",
        "productsAndServices": "Produits et services",
        "quantity": "Quantité",
        "title": "Ligne de commande",
        "total": "Total",
        "totalWithVAT": "Total avec TVA",
        "totalWithoutVAT": "Total sans TVA",
        "vat": "TVA"
      },
      "title": "Lignes"
    },
    "rewards": {
      "reward": {
        "available": "Points disponibles",
        "title": "Points",
        "use": "Usar puntos"
      },
      "title": "Points"
    },
    "rooms": {
      "new": {
        "title": "Nouvelle salle"
      },
      "room": {
        "title": "Salle"
      },
      "title": "Salles"
    },
    "saft": {
      "creator": "Créateur",
      "documentDate": "Date",
      "endDate": "Date de fin",
      "name": "Nom",
      "new": {
        "title": "Nouveau SAF-T"
      },
      "startDate": "Date de début",
      "title": "SAF-T"
    },
    "sales": {
      "balance": "Solde",
      "billItem": {
        "new": "Nouvel élément"
      },
      "billed": "Facturé",
      "credit": "Crédit",
      "currentAccount": {
        "12to18": "12 - 18 mois",
        "18to24": "18 - 24 mois",
        "3to6": "3 - 6 mois",
        "6to12": "6 - 12 mois",
        "less3": "< 3 mois",
        "more24": "> 24 mois",
        "notDue": "Non dû"
      },
      "directDebit": "Prélèvement",
      "document": "Numéro de document",
      "documents": {
        "title": "Documents"
      },
      "invoices": {
        "title": "Factures"
      },
      "newItem": "Ajouter un article au panier",
      "stats": {
        "assistants": "Assistantes",
        "maps": "Cartes",
        "nurses": "Infirmières",
        "products": "Produits",
        "services": "Services",
        "top5": "Top 5",
        "transactions": "Transactions",
        "vets": "Vétérinaires"
      },
      "title": "Facturation",
      "totalDebt": "Dette totale",
      "totalPaid": "Total payé",
      "value": "Valeur"
    },
    "salesUnits": {
      "title": "Unités de vente"
    },
    "section": {
      "title": "Section"
    },
    "serums": {
      "serum": {
        "adjustment": "Ajustement",
        "adjustmentResult": "Résultat d'ajustement",
        "amount": "Quantité",
        "description": "Description",
        "ended": "Fin",
        "maintenanceFluidRate": "Débit de fluide d'entretien",
        "name": "Nom",
        "period": "Fréquence",
        "started": "Début",
        "supplementation": "Supplémentation",
        "title": "Perfusion"
      },
      "title": "Perfusions"
    },
    "settings": {
      "rules": "Règles"
    },
    "sheltereds": {
      "disabled": "Désactivé",
      "edit": {
        "title": "Modifier le Protégé"
      },
      "enabled": "Activé",
      "form": {
        "category": {
          "fisicalDetails": "Détails Physiques",
          "other": "Autre",
          "personalInformation": "Informations Personnelles"
        },
        "environmentType": {
          "both": "Les Deux",
          "indoor": "Intérieur",
          "outdoor": "Extérieur"
        },
        "placeholder": {
          "allergies": "Allergies",
          "behavior": "Comportement",
          "census": "Recensement",
          "chip": "Numéro de Puce",
          "comments": "Commentaires",
          "commentsExtra": "Commentaires Supplémentaires",
          "eyes": "Exemple: Bleu",
          "fur": "Exemple: Court",
          "furDescription": "Description du Pelage",
          "height": "Hauteur",
          "insuranceType": "Type d'Assurance",
          "name": "Exemple: Boby",
          "notes": "Autres Informations",
          "passaport": "Numéro de Passeport",
          "pathology": "Sélectionner la Pathologie",
          "pedigree": "Pedigree",
          "siraID": "ID Sira",
          "temperament": "Exemple: Calme"
        },
        "select": {
          "breed": "Sélectionner la Race",
          "diet": "Sélectionner le Type d'Alimentation",
          "environmentType": "Sélectionner le Type d'Environnement",
          "owner": "Sélectionner le Client",
          "price": "Sélectionner le Prix",
          "species": "Sélectionner l'Espèce",
          "tail": "Sélectionner le Type de Queue",
          "veterinary": "Sélectionner le Vétérinaire Préféré"
        }
      },
      "new": {
        "title": "Nouveau Protégé"
      },
      "sheltered": {
        "admissionDate": "Date d'Admission",
        "admissionType": "Type d'Admission",
        "age": "Âge",
        "allergies": "Allergies",
        "avatar": "Avatar",
        "behavior": "Comportement",
        "birthdate": "Date de Naissance",
        "breed": "Race",
        "census": "Recensement",
        "chip": "Puce",
        "comment": "Commentaire",
        "comments": "Commentaires",
        "commentsExtra": "Commentaires Supplémentaires",
        "deathdate": "Date de Décès",
        "deceased": "Décédé",
        "deleted": "Supprimer",
        "diet": "Régime Alimentaire",
        "environmentType": "Type d'Environnement",
        "eyes": "Yeux",
        "fur": "Pelage",
        "furColor": "Couleur",
        "furDescription": "Description du Pelage",
        "furLength": "Longueur du Pelage",
        "furType": "Type de Pelage",
        "height": "Hauteur",
        "id": "ID",
        "inactive": "Inactif",
        "insurance": "Assurance",
        "insuranceType": "Type d'Assurance",
        "location": "Emplacement",
        "name": "Nom",
        "newWeight": "Nouveau Poids",
        "notes": "Notes",
        "ownerPrefered": "Préféré du Client",
        "passport": "Passeport",
        "pathologies": "Pathologies",
        "pathology": "Pathologie",
        "pedigree": "Pedigree",
        "price": "Prix",
        "proportion": "Proportion",
        "releaseDate": "Date de Libération",
        "releaseType": "Type de Libération",
        "sex": "Sexe",
        "siraID": "ID Sira",
        "species": "Espèce",
        "status": "Statut",
        "sterilized": "Stérilisé",
        "tailType": "Type de Queue",
        "tattoo": "Tatouages",
        "tattooNumber": "Numéro de Tatouage",
        "temperament": {
          "agressive": "Agressif",
          "good": "Bon",
          "moderate": "Modéré",
          "title": "Tempérament"
        },
        "title": "Protégé",
        "veterinaryPrefered": "Vétérinaire Préféré",
        "weights": {
          "title": "Poids",
          "weight": {
            "title": "Poids",
            "weighted": "Date"
          }
        }
      },
      "title": "Protégés"
    },
    "shifts": {
      "create": "Créer un Quart de travail",
      "edit": "Modifier le Quart de travail",
      "endDate": "Date de fin",
      "name": "Nom",
      "notes": "Notes",
      "startDate": "Date de début",
      "title": "Quarts de travail",
      "worker": "Travailleur"
    },
    "shoppingCartLines": {
      "new": {
        "title": "Nouvelle ligne"
      },
      "shoppingCartLine": {
        "discount": "Remise",
        "id": "ID",
        "liquid": "Liquide",
        "name": "Nom",
        "productsAndServices": "Produits et services",
        "quantity": "Quantité",
        "title": "Ligne de panier",
        "total": "Total",
        "totalWithVAT": "Total avec TVA",
        "totalWithoutVAT": "Total sans TVA",
        "vat": "TVA"
      },
      "title": "Lignes de panier"
    },
    "shoppingCarts": {
      "checkPaid": "Marquer comme Payé",
      "details": "Voir le panier",
      "edit": {
        "backToStart": "Retour au début"
      },
      "markAsPaid": "Êtes-vous sûr de vouloir marquer comme payé?",
      "name": "Nom",
      "noLines": "Ce panier n'a pas de lignes",
      "onGoingInternment": "Hospitalisation en cours",
      "openTitle": "Paniers ouverts",
      "paid": "Payé",
      "pending": "En attente",
      "percentagePaid": "Pourcentage de paniers payés",
      "shoppingCart": {
        "title": "Panier"
      },
      "showPaidCarts": "Inclure les paniers déjà payés",
      "status": "Statut",
      "title": "Paniers"
    },
    "sms": {
      "new": {
        "title": "Nouveau SMS"
      },
      "sent": {
        "title": "SMS"
      },
      "sms": {
        "appointmentDate": "Date",
        "campaign": "Campagne",
        "customer": "Client",
        "patient": "Patient",
        "phoneNumber": "Numéro de téléphone",
        "started": "Date d'envoi",
        "text": "Texte",
        "title": "SMS"
      },
      "title": "SMS"
    },
    "species": {
      "101": "Nightingale japonais",
      "103": "Inséparable à face rose",
      "107": "Aigle ibérique",
      "108": "Aigle royal",
      "109": "Conure",
      "113": "Pigeons",
      "117": "Petaure commun",
      "118": "Gecko",
      "119": "Serpents",
      "12": "Merle noir",
      "120": "Caméléon",
      "121": "Perruche à collier",
      "125": "Poule",
      "126": "Perroquet jaco",
      "128": "Collier à bague",
      "130": "Tourterelle turque",
      "14": "Cacatoès",
      "16": "Ara",
      "17": "Perruche à tête jaune",
      "18": "Perroquet de Patagonie",
      "2": "Chordata",
      "20": "Conure",
      "21": "Perruche ondulée",
      "22": "Perroquet gris du Gabon",
      "28": "Porcs",
      "33": "Chiens",
      "34": "Felidae",
      "35": "Felis",
      "36": "Chats",
      "40": "Furets",
      "42": "Hérissons",
      "46": "Lapins",
      "50": "Cochon d'Inde",
      "53": "Chinchilla à queue courte",
      "54": "Chinchilla à queue longue",
      "56": "Hamsters",
      "59": "Rats",
      "62": "Dègues du Chili",
      "64": "Chien de prairie",
      "68": "Agame barbu",
      "7": "Chloris chloris",
      "70": "Iguane",
      "72": "Varan",
      "73": "Varan des savanes",
      "74": "Tortues",
      "79": "Chevaux",
      "8": "Canaris",
      "81": "Ânes",
      "82": "Phodopus",
      "83": "Hamster russe",
      "86": "Éléphants",
      "9": "Tarin des aulnes",
      "90": "Vaches",
      "92": "Capra",
      "93": "Chèvres",
      "95": "Moutons",
      "98": "Perruche ondulée"
    },
    "stats": {
      "averageTimes": "Temps moyen",
      "billedThisMonth": "Ce mois-ci",
      "billedThisWeek": "Cette semaine",
      "billedThisYear": "Cette année",
      "billedToday": "Aujourd'hui",
      "decreased": "Diminué",
      "down": "En baisse",
      "female": "F",
      "increased": "Augmenté",
      "invoicesNumber": "Nombre de factures",
      "listOfCreatedCustomers": "Liste des clients créés",
      "male": "M",
      "mostRecentDate": "Date la plus récente",
      "new": "Nouveaux",
      "organization": "Statistiques",
      "overview": "Aperçu",
      "salesByWorker": "Ventes par employé",
      "same": "Identique",
      "todayTotalChange": "Changement total aujourd'hui",
      "total": "Total",
      "totalBilled": "Total facturé",
      "totalInInvoices": "Total dans les factures",
      "totals": "Totaux",
      "up": "En hausse",
      "variations": "Variations",
      "vsLastYear": "par rapport à l'année dernière"
    },
    "stays": {
      "box": "Boîte",
      "create": "Créer un séjour",
      "details": "Détails du séjour",
      "edit": "Modifier le séjour",
      "endDate": "Date de fin",
      "name": "Nom",
      "patient": "Patient",
      "startDate": "Date de début",
      "staysArrivingToday": "Séjours arrivant aujourd'hui",
      "staysLeavingToday": "Séjours quittant aujourd'hui",
      "title": "Séjours"
    },
    "stockUnits": {
      "title": "Unités de stock"
    },
    "subfamily": {
      "title": "Sous-famille"
    },
    "supplierItems": {
      "new": {
        "title": "Nouvel article fournisseur"
      },
      "supplierItem": {
        "supplierId": "Identifiant du fournisseur",
        "title": "Article fournisseur"
      },
      "title": "Articles fournisseur"
    },
    "suppliers": {
      "building": "Bâtiment",
      "country": "Pays",
      "edit": {
        "title": "Modifier le fournisseur"
      },
      "email": "Email",
      "messages": {
        "createdSuccess": "Fournisseur créé avec succès"
      },
      "mobilePhone": "Téléphone mobile",
      "name": "Nom",
      "new": {
        "title": "Nouveau fournisseur"
      },
      "street": "Rue",
      "supplier": {
        "supplierId": "ID du fournisseur",
        "title": "Fournisseur"
      },
      "title": "Fournisseurs",
      "town": "Ville",
      "vat": "Numéro de TVA",
      "zip": "Code postal"
    },
    "system": {
      "alerts": {
        "offline": {
          "html": "<p>Nous n'avons pas pu accéder à notre serveur.</p><p>Essayer une nouvelle connexion <i class='fa fa-spinner fa-pulse fa-lg fa-fw'></i></p>",
          "title": "Pas d'accès Internet !"
        },
        "online": {
          "text": "Vous pouvez maintenant continuer votre travail.",
          "title": "Connexion rétablie !"
        }
      }
    },
    "systemDown1": "Le système sera arrêté pour maintenance à",
    "systemDown2": "pendant environ deux heures.",
    "tasks": {
      "archive": {
        "title": "Archiver"
      },
      "create": {
        "addDrug1": "Pour ajouter un nouveau principe actif, envoyez un email à",
        "addSupplementation1": "Pour ajouter une nouvelle supplémentation,",
        "addSupplementation2": "cliquez ici et envoyez-nous un email",
        "amount": "Quantité",
        "byKG": "Par kg",
        "changes": "Toutes les modifications apportées à la tâche seront appliquées à partir de la dernière action effectuée",
        "createdBy": "Créé par",
        "dosagePerKilogram": "Dose par kg",
        "doseCalculator": "Calculateur de doses",
        "email": "info@petuniversal.com",
        "freqInfo": "Une tâche \"Toujours visible\" n'est pas programmée et peut être enregistrée manuellement. Sans programmation, la tâche n'apparaît pas dans l'aperçu.",
        "name": "Créer une tâche",
        "newActivePrinciple": "Nouveau principe actif",
        "rateInfo": "Selon la formule : (poids * 30 + 70) / 24",
        "startedInfo": "Si vous cliquez sur l'icône de l'horloge, l'heure actuelle est automatiquement insérée.",
        "toAdminister": "À administrer",
        "total": "Total",
        "type": {
          "injectable": "Injectable",
          "pill": "Comprimé",
          "title": "Type"
        },
        "volumePerKilogram": "Volume par kg"
      },
      "done": "Effectuer",
      "edit": {
        "title": "Modifier la tâche"
      },
      "form": {
        "select": {
          "priority": "Sélectionner la priorité",
          "worker": "Sélectionner un employé"
        }
      },
      "generatedBySystem": "Cette tâche a été générée par le système",
      "messages": {
        "createdSuccess": "Tâche créée avec succès"
      },
      "myTasks": "Mes tâches",
      "new": {
        "title": "Nouvelle tâche"
      },
      "priorities": {
        "high": "Élevée",
        "low": "Faible",
        "urgency": "Urgence"
      },
      "select": {
        "all": "Toutes"
      },
      "status": {
        "done": "Effectuée",
        "inDelay": "En retard",
        "pending": "En attente"
      },
      "task": {
        "comment": "Commentaire",
        "description": "Notes",
        "name": "Description",
        "patientcustomer": "Patient/Client",
        "period": "Fréquence",
        "priority": "Priorité",
        "status": "Statut",
        "taskDate": "Date",
        "taskTime": "Heure",
        "title": "Tâche"
      },
      "taskType": "Type de tâche",
      "timeline": "Chronologie",
      "title": "Tâches",
      "withoutSelectedPriority": "Aucune priorité sélectionnée",
      "withoutTasks": "Aucune tâche",
      "withoutWorker": "Aucun employé"
    },
    "taxes": {
      "title": "Taxes",
      "vat": {
        "title": "TVA"
      }
    },
    "templates": {
      "name": "Nom",
      "new": {
        "title": "Nouveau rapport"
      },
      "template": {
        "title": "Rapport"
      },
      "templateSections": {
        "title": "Sections de rapport"
      },
      "templateSectionsFields": {
        "title": "Champs de section de rapport"
      },
      "title": "Rapports"
    },
    "terms": {
      "conditionedDischarge": "Termes de décharge conditionnée",
      "cookies": {
        "link": "Cookies",
        "title": "Cookies"
      },
      "debt": "Termes de la dette",
      "general": {
        "link": "Conditions",
        "title": "Termes et conditions généraux"
      },
      "language-disclaimer": "",
      "last-rev-date": "01 janvier 2016",
      "last-rev-date-desc": "Date de la dernière révision",
      "privacy": {
        "link": "Confidentialité",
        "title": "Politique de confidentialité"
      },
      "procedure": "Termes de procédure",
      "responsability": "Termes de non-responsabilité",
      "title": "Termes",
      "translation-not-available": ""
    },
    "titles": {
      "arquitect": "Architecte",
      "dr": "Docteur",
      "dra": "Doctoresse",
      "engineer": "Ingénieur",
      "engineerFemale": "Ingénieure",
      "father": "Père",
      "miss": "Mademoiselle",
      "mr": "Monsieur",
      "mrs": "Madame",
      "nurse": "Infirmier",
      "nurseFemale": "Infirmière",
      "professor": "Professeur",
      "professorFemale": "Professeure",
      "sister": "Sœur"
    },
    "transfersStock": {
      "aTDocCodeID": "Code AT",
      "loading": {
        "loadingBuildingNumber": "Numéro du bâtiment",
        "loadingCityName": "Ville",
        "loadingCountry": "Pays",
        "loadingDateTime": "Date et heure",
        "loadingPostalZone": "Code postal",
        "loadingStreetName": "Nom de rue",
        "sourceWarehouse": "Source",
        "title": "Chargement"
      },
      "new": {
        "title": "Nouveau transfert de stock"
      },
      "title": "Transferts de stock",
      "unloading": {
        "targetWarehouse": "Destination",
        "title": "Déchargement",
        "unloadingBuildingNumber": "Numéro du bâtiment",
        "unloadingCityName": "Ville",
        "unloadingCountry": "Pays",
        "unloadingDateTime": "Date et heure",
        "unloadingPostalZone": "Code postal",
        "unloadingStreetName": "Nom de rue"
      }
    },
    "u-icons": {
      "bug": "bug",
      "company": "building-o",
      "employee": "users",
      "image": "image",
      "plan": "ticket",
      "plans": "ticket",
      "user": "user",
      "worker": "user-md"
    },
    "unexpectedError": "Une erreur inattendue s'est produite.\nVeuillez réessayer dans quelques instants.\nSi l'erreur persiste, veuillez nous en informer.",
    "unitDimensions": {
      "description": "Toutes les dimensions des unités",
      "form": {
        "placeholder": {
          "baseUnit": "Unité de base",
          "description": "Description",
          "name": "Nom",
          "title": "Dimensions des unités"
        }
      },
      "new": {
        "title": "Nouvelle dimension d'unité"
      },
      "title": "Dimensions des unités",
      "unitDimension": {
        "baseUnit": "Unité de base",
        "description": "Description",
        "name": "Nom",
        "title": "Dimension"
      }
    },
    "units": {
      "ampoules": "ampoules",
      "bandages": "bandages",
      "capsules": "capsules",
      "centimeters": "cm",
      "description": "toutes les unités",
      "drops": "gouttes",
      "form": {
        "placeholder": {
          "baseUnit": "Unité de base",
          "conversionFactor": "Facteur de conversion",
          "externalID": "ID externe",
          "name": "Nom",
          "precisionDigits": "Chiffres de précision",
          "salesItems": "Unités de vente",
          "stockItems": "Unités d'achat",
          "symbol": "Symbole",
          "title": "Unité de stock"
        }
      },
      "grams": "g",
      "internationalUnits": "UI",
      "kilograms": "kg",
      "liters": "l",
      "micrograms": "µg",
      "microgramsPerMilliliter": "µg/ml",
      "milliequivalents": "mEq",
      "milligrams": "mg",
      "milligramsPerKilogram": "mg/kg",
      "milligramsPerMilliliter": "mg/ml",
      "milliliters": "ml",
      "millilitersPerHour": "ml/h",
      "millilitersPerKilogram": "ml/kg",
      "min": "min",
      "new": {
        "title": "Nouvelle unité"
      },
      "percentage": "%",
      "pills": "comprimés",
      "puff": "bouffée",
      "sachets": "sachets",
      "title": "Unités",
      "unit": {
        "baseUnit": "Unité de base",
        "conversionFactor": "Facteur de conversion",
        "externalID": "ID externe",
        "name": "Nom",
        "precisionDigits": "Chiffres de précision",
        "salesItems": "Unités de vente",
        "stockItems": "Unités d'achat",
        "symbol": "Symbole",
        "title": "Unité",
        "totalStock": "Stock total"
      }
    },
    "user": {
      "edit": {
        "title": "Données personnelles"
      },
      "new": {
        "agree-terms": "En cliquant sur le bouton, vous acceptez nos termes et conditions et confirmez avoir lu notre politique de confidentialité.",
        "confirmPassword": "Confirmer le mot de passe",
        "email-example": "email@example.com",
        "name-example": "Nom d'utilisateur",
        "password-example": "mot de passe",
        "passwordsDontMatch": "Les mots de passe ne correspondent pas",
        "title": "S'inscrire",
        "validationEmailSent": "Merci de vous être inscrit, veuillez vérifier votre boîte e-mail et valider votre compte"
      },
      "recoverPassword.success": "Un e-mail vous a été envoyé avec votre nouveau mot de passe"
    },
    "validations": {
      "fieldRequired": "Champ obligatoire.",
      "maxCharacters": "Maximum de {number} caractères",
      "minCharacters": "Minimum de {number} caractères",
      "minQuantity": "Doit avoir au moins {number}",
      "numberCharacters": "Doit avoir {number} caractères",
      "specialCharacters": "Impossible d'insérer des caractères spéciaux (ex : >, <) ou de terminer par un espace"
    },
    "via": {
      "IntralesionalInjectable": "Injectable - IL",
      "auricularBoth": "Les deux oreilles",
      "auricularLeft": "Oreille gauche",
      "auricularRight": "Oreille droite",
      "inhalation": "Inhalation",
      "intramuscularInjectable": "Injectable - IM",
      "intraosseousInjectable": "Injectable - IO",
      "intraperitonealInjectable": "Injectable - IP",
      "intravenousInjectable": "Injectable - IV",
      "nasogastric": "Nasogastrique",
      "ocularBoth": "Les deux yeux",
      "ocularLeft": "Œil gauche",
      "ocularRight": "Œil droit",
      "oral": "Oral",
      "rectal": "Rectal",
      "subcutaneousInjectable": "Injectable - SC",
      "topical": "Topique",
      "transdermal": "Transdermique"
    },
    "visits": {
      "futureVisits": "Visites futures",
      "historicalVisits": "Historique des visites",
      "newVisit": "Nouvelle visite",
      "status": {
        "closed": "Fermé",
        "open": "Ouvert",
        "title": "Statut"
      },
      "title": "Visites",
      "visit": {
        "title": "Visite"
      },
      "visitTypes": {
        "visitSubType": "Sélectionner le sous-type de visite",
        "visitType": "Sélectionner le type de visite"
      }
    },
    "waitingRooms": {
      "arrival": "Arrivée",
      "late": "En retard",
      "status": {
        "noPatientsCheckOut": "Aucun patient en caisse",
        "noPatientsConsultation": "Aucun patient en consultation",
        "noPatientsWaiting": "Aucun patient en attente",
        "pausedConsultations": "Consultations en pause",
        "withScheduling": "Avec planification",
        "withoutAppointments": "Sans rendez-vous"
      },
      "title": "Salle d'attente",
      "warning": "Avertissement",
      "warningDate": "Date d'avertissement"
    },
    "warehouses": {
      "edit": {
        "title": "Modifier l'entrepôt"
      },
      "form": {
        "category": {
          "base": "Base"
        },
        "placeholder": {
          "name": "Nom"
        }
      },
      "messages": {
        "createdSuccess": "Entrepôt créé avec succès"
      },
      "new": {
        "title": "Nouvel entrepôt"
      },
      "stock": "Stock",
      "title": "Entrepôts",
      "viewWarehouse": "Voir l'entrepôt",
      "warehouse": {
        "description": "Remarques",
        "name": "Nom",
        "title": "Entrepôt"
      }
    },
    "workers": {
      "edit": {
        "placeholder": {
          "address": "Adresse",
          "email": "exemple@email.com",
          "mobilePhone": "Téléphone mobile",
          "name": "Exemple : Jean Dupont",
          "phone": "Téléphone",
          "professionalLetterNumber": "N° professionnel"
        },
        "select": {
          "employee": "Employé",
          "role": "Sélectionner la fonction"
        },
        "title": "Modifier le travailleur"
      },
      "form": {
        "select": {
          "worker": "Sélectionner le travailleur"
        }
      },
      "new": {
        "placeholder": {
          "address": "Adresse",
          "email": "exemple@email.com",
          "mobilePhone": "Téléphone mobile",
          "name": "Exemple : Jean Dupont",
          "phone": "Téléphone",
          "professionalLetterNumber": "N° professionnel"
        },
        "select": {
          "employee": "Employé",
          "role": "Sélectionner la fonction"
        },
        "title": "Joindre existant"
      },
      "title": "Travailleurs",
      "worker": {
        "address": "Adresse",
        "birthdate": "Date de naissance",
        "email": "Email",
        "enable": "Activé",
        "externalID": "ID externe",
        "mobilePhone": "Téléphone mobile",
        "name": "Nom",
        "phone": "Téléphone",
        "professionalLetterNumber": "N° professionnel",
        "role": "Fonction",
        "roles": {
          "administrator": "Administrateur",
          "assistant": "Assistant",
          "nurse": "Infirmier",
          "other": "Autre",
          "receptionist": "Réceptionniste",
          "veterinary": "Vétérinaire"
        },
        "title": "Travailleur"
      }
    }
  };
  _exports.default = _default;
});