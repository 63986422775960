define("crm/templates/organizations/organization/exams/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tbt4sYRl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"u-second-nav\",null,[[\"title\",\"titleIcon\"],[[25,\"t\",[\"exams.title\"],null],[25,\"t\",[\"icons.exam\"],null]]],{\"statements\":[[4,\"if\",[[20,[\"canDoExam\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openCreate\",\"laboratory\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.laboratory\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"laboratories.new.title\"],null],false],[8],[8],[0,\"\\n    \"],[6,\"li\"],[7],[6,\"a\"],[3,\"action\",[[19,0,[]],\"openCreate\",\"exam\"]],[7],[1,[25,\"icon-add\",null,[[\"icon\"],[[25,\"t\",[\"icons.exam\"],null]]]],false],[0,\" \"],[1,[25,\"t\",[\"exams.new.title\"],null],false],[8],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[7],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"filters-panel\"],[7],[0,\"\\n    \"],[6,\"h4\"],[7],[1,[25,\"t\",[\"actions.filters\"],null],false],[8],[0,\"\\n    \"],[1,[25,\"date-filters\",null,[[\"previous\",\"today\",\"next\",\"search\"],[\"previous\",\"today\",\"next\",\"search\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\\n\"],[4,\"ibox-panel\",null,null,{\"statements\":[[0,\"    \"],[1,[25,\"exams-list\",null,[[\"model\",\"showCustomer\",\"showDescription\",\"callModal\",\"callLabAnalysisExamReport\"],[[20,[\"model\"]],true,true,[25,\"action\",[[19,0,[]],\"callModal\"],null],\"callLabAnalysisExamReport\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "crm/templates/organizations/organization/exams/index.hbs"
    }
  });

  _exports.default = _default;
});