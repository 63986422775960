define("crm/routes/organizations/organization/listings/sales/receipts", ["exports", "crm/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service("session"),
    session: Ember.inject.service("session"),
    breadCrumb: {
      finalTitle: "Recibos"
    },
    model: function model() {
      var self = this;
      var organization = this.modelFor("organizations.organization");
      var dateFrom = this.paramsFor("organizations.organization.listings").createdAfter;
      var dateTo = this.paramsFor("organizations.organization.listings").createdBefore;
      return Ember.$.ajax({
        url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/listings/sales/accountsReceivable/receipts" + "?organization=" + organization.get("id") + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
        accept: "application/json",
        beforeSend: function beforeSend(request) {
          request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
        },
        success: function success(result) {
          result.data = result.result;
          result.data.sort(function (a, b) {
            if (a.ROW_NUMBER < b.ROW_NUMBER) {
              return -1;
            }

            if (a.ROW_NUMBER > b.ROW_NUMBER) {
              return 1;
            }

            return 0;
          });
          return result;
        },
        error: function error() {
          return {};
        }
      });
    }
  });

  _exports.default = _default;
});