define("crm/abilities/worker", ["exports", "crm/abilities/manager"], function (_exports, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _manager.default.extend({
    canEditRole: Ember.computed("worker.organization.id", function () {
      return this.get("g") || ["2", "3", "4", "403"].includes(this.get("worker.organization.id"));
    })
  });

  _exports.default = _default;
});