define("crm/controllers/organizations/organization/warehouses/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    canDoWarehouses: Ember.computed("can", function () {
      return this.get("can").can("do procurement");
    }),
    columns: Ember.computed(function () {
      return [{
        "propertyName": "id",
        "title": "ID",
        "className": "column-id"
      }, {
        "propertyName": "internalDescription",
        "title": this.get("intl").t("warehouses.warehouse.title"),
        "routeName": "organizations.organization.warehouses.warehouse",
        "routeProperty": "id",
        "sortDirection": "asc",
        "sortPrecedence": 0
      }, {
        "propertyName": "description",
        "title": this.get("intl").t("warehouses.warehouse.description")
      }, {
        "component": "warehouse-actions",
        "className": "column-actions-3"
      }];
    }),
    actions: {
      openModalCreateWarehouse: function openModalCreateWarehouse() {
        this.send("openModal", {
          entity: "warehouse",
          action: "create"
        });
      },
      openEditWarehouseModal: function openEditWarehouseModal(warehouseToEdit) {
        this.send("openEditWarehouse", warehouseToEdit);
      },
      openDetails: function openDetails(warehouse) {
        this.send("openModal", {
          entity: "warehouse",
          action: "details",
          model: warehouse
        });
      }
    }
  });

  _exports.default = _default;
});