define("crm/routes/organizations/organization/warehouses", ["exports", "moment", "crm/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var organization = this.modelFor("organizations.organization");

      if (!organization.get("featureNames").includes("procurement")) {
        return null;
      }

      var result = this._super.apply(this, arguments);

      if (this.get("can").cannot('read warehouse')) {
        transition.abort();
        this.send("noPermissions");
      }

      return result;
    },
    actions: {
      export: function _export() {
        var dateMoment = (0, _moment.default)();
        var self = this;
        return Ember.$.ajax({
          method: "GET",
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/inventory/today/xlsx?organization=" + self.get("controller.model.id") + "&date=" + dateMoment.format("YYYY-MM-DD"),
          xhrFields: {
            responseType: "blob"
          },
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
          },
          success: function success(blob) {
            self.set("exporting", false);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            var url = url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "Stock - " + dateMoment.format("YYYY-MM-DD") + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
          },
          error: function error() {
            return {};
          }
        });
      }
    }
  });

  _exports.default = _default;
});