define("crm/components/contact-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    contactValidator: Ember.inject.service(),
    contactType: null,
    newContact: {},
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      this.get("store").findAll("authorization-type").then(function (authorizationTypes) {
        authorizationTypes.forEach(function (authorizationType) {
          authorizationType.set("name", self.get("intl").t("customers.customer.contacts.typesTranslation." + authorizationType.get("id")));
        });
        self.set("authorizationTypes", authorizationTypes);
      });
    },
    validationCustomer: Ember.computed("model.firstAddress", "organization.country", function () {
      return this.get("model.firstAddress.country") ? this.get("model.firstAddress.country") : this.get("organization.country");
    }),
    contactsType: Ember.computed('intl', function () {
      return [{
        id: 1,
        name: this.get('intl').t('customers.customer.contacts.mobilePhone')
      }, {
        id: 2,
        name: this.get('intl').t('customers.customer.contacts.phone')
      }, {
        id: 3,
        name: this.get('intl').t('customers.customer.contacts.email')
      }];
    }),
    disableBtn: Ember.computed("validContactNumber", "disableBtnContact", function () {
      return !this.get("validContactNumber") || this.get("disableBtnContact");
    }),
    validContactNumber: Ember.computed("validationCustomer", "newContact.contact", "contactType", function () {
      if (Ember.isEmpty(this.get("newContact.contact")) && Ember.isEmpty(this.get("validationCustomer")) && Ember.isEmpty(this.get("contactType"))) {
        return true;
      }

      if (this.get("contactType") === "3") {
        return this.get("contactValidator").email(this.get("newContact.contact"));
      }

      return this.get("contactValidator").phone(this.get("organization.country"), this.get("newContact.contact"));
    }),
    close: function close() {
      Ember.$("#modal-contact-new").modal("hide");
    },
    actions: {
      createContact: function createContact() {
        this.set('disableBtnContact', true);
        var self = this;
        this.set("newContact.customer", this.get("model"));
        this.set("newContact.contactType", this.get("contactType"));
        var contact = this.get('store').createRecord("contact", this.get("newContact"));
        contact.save().then(function (savedContact) {
          self.get("authorizationTypes").forEach(function (authorizationType) {
            if (authorizationType.active) {
              var contactAuthorizationType = self.get('store').createRecord('contact-authorization-type', {
                contact: savedContact,
                authorizationType: authorizationType
              });
              contactAuthorizationType.save();
            }
          });
          self.close();
        }).catch(function () {
          self.get('store').unloadRecord(contact);
          self.set('disableBtnContact', false);
        });
      },
      reset: function reset() {
        this.set('newContact', {});
        this.set('disableBtnContact', false);
        this.get("authorizationTypes") ? this.get("authorizationTypes").forEach(function (authorization) {
          authorization.set("active", false);
        }) : [];
      },
      canceled: function canceled() {
        this.close();
      },
      toggleAuthorizationType: function toggleAuthorizationType(authorizationType) {
        if (authorizationType.active) {
          authorizationType.set('active', false);
        } else {
          authorizationType.set('active', true);
        }
      },
      selectContactType: function selectContactType(value) {
        this.set('contactType', value);
      }
    }
  });

  _exports.default = _default;
});