define("crm/controllers/organizations/organization/exams/index", ["exports", "moment", "crm/config/environment", "ember-inflector"], function (_exports, _moment, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["dateStart", "dateEnd"],
    can: Ember.inject.service(),
    pdf: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    canDoExam: Ember.computed("can", function () {
      return this.get("can").can("do calendar");
    }),
    actions: {
      search: function search(dates) {
        if (dates) {
          this.set("createdAfter", dates.nextDateStart);
          this.set("createdBefore", dates.nextDateEnd);
        }
      },
      setCheckBox: function setCheckBox(status) {
        if (status.checked) {
          status.set('checked', false);
        } else {
          status.set('checked', true);
        }
      },
      callModal: function callModal(arg) {
        this.send("openModal", arg);
      },
      openCreate: function openCreate(entity) {
        this.send("openModal", {
          entity: entity,
          action: "create"
        });
      },
      openDetails: function openDetails(exam) {
        this.send("openModal", {
          entity: "exam",
          action: "details",
          model: exam
        });
      },
      openEdit: function openEdit(exam) {
        this.send("openModal", {
          entity: "exam",
          action: "edit",
          model: exam
        });
      },
      callLabAnalysisExamReport: function callLabAnalysisExamReport(exam) {
        var self = this;
        var modelName = exam.constructor.modelName;
        var id = exam.get("id");
        Ember.$.ajax({
          method: "GET",
          beforeSend: function beforeSend(request) {
            request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
            request.setRequestHeader("Content-Type", "application/pdf");
            request.setRequestHeader("Accept-Language", self.get("intl.locale"));
          },
          url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
          xhrFields: {
            responseType: "blob"
          },
          success: function success(blob) {
            self.get("store").findRecord(modelName, id, {
              reload: true
            }).then(function (savedDocument) {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = savedDocument.get("patient.code") + "_" + savedDocument.get("patient.name") + "_" + savedDocument.get("name") + "_" + (0, _moment.default)(savedDocument.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
              link.click();
            });
          },
          error: function error() {
            Ember.run.later(function () {
              Ember.$.ajax({
                method: "GET",
                beforeSend: function beforeSend(request) {
                  request.setRequestHeader("Authorization", "Bearer " + self.get("session.session.content.authenticated.access_token"));
                  request.setRequestHeader("Content-Type", "application/pdf");
                  request.setRequestHeader("Accept-Language", self.get("intl.locale "));
                },
                url: _environment.default.universe.api.host + "/" + _environment.default.universe.api.namespace + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id,
                xhrFields: {
                  responseType: "blob"
                },
                success: function success(blob) {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download = exam.get("patient.code") + "_" + exam.get("patient.name") + "_" + exam.get("name") + "_" + (0, _moment.default)(exam.get("doneDate")).format("DD-MM-YYYY") + ".pdf";
                  link.click();
                },
                error: function error() {
                  self.get("flashMessages").info(self.get("intl").t("pointOfSales.errors.pdfNotEmiting"), {
                    timeout: 1000
                  });
                }
              });
            }, 1000);
          }
        });
      }
    }
  });

  _exports.default = _default;
});