define("crm/controllers/organizations/organization/warehouses/warehouse/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ["date"],
    exporting: false,
    date: (0, _moment.default)().endOf("day").format("YYYY-MM-DD"),
    columns: Ember.computed("model.[]", function () {
      var listCollumns = [{
        "propertyName": "item",
        "title": "ID",
        "routeName": "organizations.organization.items.item",
        "routeProperty": "Item",
        "className": "column-id"
      }, {
        "propertyName": "description",
        "title": this.get("intl").t("items.item.name"),
        "routeName": "organizations.organization.items.item",
        "routeProperty": "Item"
      }, {
        "propertyName": "stock",
        "title": this.get("intl").t("items.item.stock"),
        "className": "column-id text-right",
        "component": "umt-number"
      }, {
        "propertyName": "unit",
        "title": this.get("intl").t("units.unit.title"),
        "className": "column-contact"
      }];
      return listCollumns;
    }),
    actions: {
      openModalCreateItem: function openModalCreateItem() {
        this.send("openModal", {
          entity: "item",
          action: "create"
        });
      },
      openModalEntryItem: function openModalEntryItem() {
        this.send("openEntryItem");
      },
      gotoItemDetails: function gotoItemDetails(item) {
        this.transitionToRoute("organizations.organization.items.item", item.get("id"));
      },
      openEditWarehouse: function openEditWarehouse(warehouse) {
        this.send('openEditWarehouseModal', warehouse);
      },
      openMoveStockModal: function openMoveStockModal(item) {
        this.send("openModal", {
          entity: "item",
          action: "moveStock",
          model: item
        });
      },
      openCreate: function openCreate(entity) {
        this.send("openModal", {
          entity: entity,
          action: "create"
        });
      },
      openEntry: function openEntry(entity) {
        this.send("openModal", {
          entity: entity,
          action: "entry"
        });
      }
    }
  });

  _exports.default = _default;
});