define("crm/controllers/organizations/organization/listings/sales/receivables", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    createdAfter: (0, _moment.default)().startOf("month").format("YYYY-MM-DD"),
    createdBefore: (0, _moment.default)().format("YYYY-MM-DD"),
    columns: Ember.computed("model.[]", function () {
      return [{
        "title": this.get('intl').t("customers.customer.title"),
        "propertyName": "name",
        "className": "column-name"
      }, {
        "title": this.get('intl').t("sales.currentAccount.notDue"),
        "propertyName": "nonDueAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.less3"),
        "propertyName": "range1Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.3to6"),
        "propertyName": "range2Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.6to12"),
        "propertyName": "range3Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.12to18"),
        "propertyName": "range4Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.18to24"),
        "propertyName": "range5Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": this.get('intl').t("sales.currentAccount.more24"),
        "propertyName": "range6Amount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }, {
        "title": "Total",
        "propertyName": "totalAmount",
        "component": "umt-price-display",
        "className": "column-price-large"
      }];
    })
  });

  _exports.default = _default;
});